import defaults from "../defaults";
import utils from "../utils";
import { Rect, Point } from "../utils";

class Drawer {
    viewer;
    viewport;
    container;
    canvas;
    context;
    sketchCanvas = null;
    sketchContext;
    element;
    debugGridColor = defaults.debugGridColor;
    constructor(options) {
        this.viewer = options.viewer;
        this.viewport = options.viewport;
        if(options.debugGridColor) this.debugGridColor = options.debugGridColor;
        this.container = utils.getElement( options.element );
        this.canvas = utils.makeNeutralElement("canvas");
        this.context = this.canvas.getContext( "2d" );
        this.element = this.container;
        // We force our container to ltr because our drawing math doesn't work in rtl.
        // This issue only affects our canvas renderer, but we do it always for consistency.
        // Note that this means overlays you want to be rtl need to be explicitly set to rtl.
        this.container.dir = 'ltr';
        const viewportSize = this.calculateCanvasSize();
        this.canvas.width = viewportSize.x;
        this.canvas.height = viewportSize.y;
        this.canvas.style.width     = "100%";
        this.canvas.style.height    = "100%";
        this.canvas.style.position  = "absolute";
        //utils.setElementOpacity( this.canvas, this.opacity, true );
        // explicit left-align
        this.container.style.textAlign = "left";
        this.container.appendChild( this.canvas );
    }
    
    /**
     * @return {Boolean} True if rotation is supported.
     */
    canRotate() {
        return true;
    }

    /**
     * Destroy the drawer (unload current loaded tiles)
     */
    destroy() {
        //force unloading of current canvas (1x1 will be gc later, trick not necessarily needed)
        this.canvas.width  = 1;
        this.canvas.height = 1;
        this.sketchCanvas = null;
        this.sketchContext = null;
    }

    /**
     * Clears the Drawer so it's ready to draw another frame.
     */
    clear() {
        this.canvas.innerHTML = "";
        let viewportSize = this.calculateCanvasSize();
        if( this.canvas.width !== viewportSize.x ||
            this.canvas.height !== viewportSize.y ) {
            this.canvas.width = viewportSize.x;
            this.canvas.height = viewportSize.y;
            if ( this.sketchCanvas !== null ) {
                let sketchCanvasSize = this.calculateSketchCanvasSize();
                this.sketchCanvas.width = sketchCanvasSize.x;
                this.sketchCanvas.height = sketchCanvasSize.y;
            }
        }
        this._clear();
    }

    _clear ( useSketch ) {
        const context = this.getContext( useSketch );
        const canvas = context.canvas;
        context.clearRect( 0, 0, canvas.width, canvas.height );
    }

    /**
     * Scale from XLviewer viewer rectangle to drawer rectangle
     * (ignoring rotation)
     * @param {Rect} rectangle - The rectangle in viewport coordinate system.
     * @return {Rect} Rectangle in drawer coordinate system.
     */
    viewportToDrawerRectangle(rectangle) {
        let topLeft = this.viewport.pixelFromPointNoRotate(rectangle.getTopLeft(), true);
        let size = this.viewport.deltaPixelsFromPointsNoRotate(rectangle.getSize(), true);

        return new Rect(
            topLeft.x * utils.pixelDensityRatio,
            topLeft.y * utils.pixelDensityRatio,
            size.x    * utils.pixelDensityRatio,
            size.y    * utils.pixelDensityRatio
        );
    }

    /**
     * Draws the given tile.
     * @param {Tile} tile - The tile to draw.
     * @param {Function} drawingHandler - Method for firing the drawing event if using canvas.
     * drawingHandler({context, tile, rendered})
     * @param {Boolean} useSketch - Whether to use the sketch canvas or not.
     * where <code>rendered</code> is the context with the pre-drawn image.
     * @param {Number} [scale=1] - Apply a scale to tile position and size. Defaults to 1.
     * @param {Point} [translate] A translation vector to offset tile position
     */
    drawTile(tile, drawingHandler, useSketch, scale, translate) {
        let context = this.getContext(useSketch);
        scale = scale || 1;
        tile.drawCanvas(context, drawingHandler, scale, translate);
    }

    getContext( useSketch ) {
        let context = this.context;
        if ( useSketch ) {
            if (this.sketchCanvas === null) {
                this.sketchCanvas = document.createElement( "canvas" );
                let sketchCanvasSize = this.calculateSketchCanvasSize();
                this.sketchCanvas.width = sketchCanvasSize.x;
                this.sketchCanvas.height = sketchCanvasSize.y;
                this.sketchContext = this.sketchCanvas.getContext( "2d" );

                // If the viewport is not currently rotated, the sketchCanvas
                // will have the same size as the main canvas. However, if
                // the viewport get rotated later on, we will need to resize it.
                if (this.viewport.getRotation() === 0) {
                    let self = this;
                    this.viewer.addListener('rotate', function resizeSketchCanvas() {
                        self.viewer.removeListener('rotate', resizeSketchCanvas);
                        let sketchCanvasSize = self.calculateSketchCanvasSize();
                        self.sketchCanvas.width = sketchCanvasSize.x;
                        self.sketchCanvas.height = sketchCanvasSize.y;
                    });
                }
            }
            context = this.sketchContext;
        }
        return context;
    }

    // private
    saveContext( useSketch ) {
        this.getContext( useSketch ).save();
    }

    // private
    restoreContext( useSketch ) {
        this.getContext( useSketch ).restore();
    }

    // private
    setClip(rect, useSketch) {
        let context = this.getContext( useSketch );
        context.beginPath();
        context.rect(rect.x, rect.y, rect.width, rect.height);
        context.clip();
    }

    // private
    drawRectangle(rect, fillStyle, useSketch) {
        let context = this.getContext( useSketch );
        context.save();
        context.fillStyle = fillStyle;
        context.fillRect(rect.x, rect.y, rect.width, rect.height);
        context.restore();
    }

    /**
     * Blends the sketch canvas in the main canvas.
     * @param {Number} opacity The opacity of the blending.
     * @param {Number} [scale=1] The scale at which tiles were drawn on the sketch. Default is 1.
     *   Use scale to draw at a lower scale and then enlarge onto the main canvas.
     * @param {Point} [translate] A translation vector that was used to draw the tiles
     * @param {String} [blendingType] - How the image is composited onto other images; see blendingType in {@link Options} for possible values.
     * @returns {undefined}
     */
    blendSketch(opacity, scale, translate, blendingType) {
        if (!this.sketchCanvas) {
            return;
        }
        scale = scale || 1;
        let position = translate instanceof Point ?
            translate :
            new Point(0, 0);

        let widthExt = 0;
        let heightExt = 0;
        if (translate) {
            let widthDiff = this.sketchCanvas.width - this.canvas.width;
            let heightDiff = this.sketchCanvas.height - this.canvas.height;
            widthExt = Math.round(widthDiff / 2);
            heightExt = Math.round(heightDiff / 2);
        }

        this.context.save();
        this.context.globalAlpha = opacity;
        if (blendingType) {
            this.context.globalCompositeOperation = blendingType;
        }
        this.context.drawImage(
            this.sketchCanvas,
            position.x - widthExt * scale,
            position.y - heightExt * scale,
            (this.canvas.width + 2 * widthExt) * scale,
            (this.canvas.height  + 2 * heightExt) * scale,
            -widthExt,
            -heightExt,
            this.canvas.width + 2 * widthExt,
            this.canvas.height + 2 * heightExt
        );
        this.context.restore();
    }

    // private
    drawDebugInfo( tile, count, i ){
        let context = this.context;
        context.save();
        context.lineWidth = 2 * utils.pixelDensityRatio;
        context.font = 'small-caps bold ' + (13 * utils.pixelDensityRatio) + 'px arial';
        context.strokeStyle = this.debugGridColor;
        context.fillStyle = this.debugGridColor;

        if ( this.viewport.rotation !== 0 ) {
            this.offsetForRotation(this.viewport.rotation);
        }

        context.strokeRect(
            tile.position.x * utils.pixelDensityRatio,
            tile.position.y * utils.pixelDensityRatio,
            tile.size.x * utils.pixelDensityRatio,
            tile.size.y * utils.pixelDensityRatio
        );

        let tileCenterX = (tile.position.x + (tile.size.x / 2)) * utils.pixelDensityRatio;
        let tileCenterY = (tile.position.y + (tile.size.y / 2)) * utils.pixelDensityRatio;

        // Rotate the text the right way around.
        context.translate( tileCenterX, tileCenterY );
        context.rotate( Math.PI / 180 * -this.viewport.rotation );
        context.translate( -tileCenterX, -tileCenterY );

        if( tile.x === 0 && tile.y === 0 ){
            context.fillText(
                "Zoom: " + this.viewport.getZoom(),
                tile.position.x * utils.pixelDensityRatio,
                (tile.position.y - 30) * utils.pixelDensityRatio
            );
            context.fillText(
                "Pan: " + this.viewport.getBounds().toString(),
                tile.position.x * utils.pixelDensityRatio,
                (tile.position.y - 20) * utils.pixelDensityRatio
            );
        }
        context.fillText(
            "Level: " + tile.level,
            (tile.position.x + 10) * utils.pixelDensityRatio,
            (tile.position.y + 20) * utils.pixelDensityRatio
        );
        context.fillText(
            "Column: " + tile.x,
            (tile.position.x + 10) * utils.pixelDensityRatio,
            (tile.position.y + 30) * utils.pixelDensityRatio
        );
        context.fillText(
            "Row: " + tile.y,
            (tile.position.x + 10) * utils.pixelDensityRatio,
            (tile.position.y + 40) * utils.pixelDensityRatio
        );
        context.fillText(
            "Order: " + i + " of " + count,
            (tile.position.x + 10) * utils.pixelDensityRatio,
            (tile.position.y + 50) * utils.pixelDensityRatio
        );
        context.fillText(
            "Size: " + tile.size.toString(),
            (tile.position.x + 10) * utils.pixelDensityRatio,
            (tile.position.y + 60) * utils.pixelDensityRatio
        );
        context.fillText(
            "Position: " + tile.position.toString(),
            (tile.position.x + 10) * utils.pixelDensityRatio,
            (tile.position.y + 70) * utils.pixelDensityRatio
        );

        if ( this.viewport.rotation !== 0 ) {
            this.restoreRotationChanges();
        }
        context.restore();
    }

    // private
    debugRect(rect) {
        let context = this.context;
        context.save();
        context.lineWidth = 2 * utils.pixelDensityRatio;
        context.strokeStyle = this.debugGridColor;
        context.fillStyle = this.debugGridColor;

        context.strokeRect(
            rect.x * utils.pixelDensityRatio,
            rect.y * utils.pixelDensityRatio,
            rect.width * utils.pixelDensityRatio,
            rect.height * utils.pixelDensityRatio
        );

        context.restore();
    }

    /**
     * Get the canvas size
     * @param {Boolean} sketch If set to true return the size of the sketch canvas
     * @returns {Point} The size of the canvas
     */
    getCanvasSize(sketch) {
        let canvas = this.getContext(sketch).canvas;
        return new Point(canvas.width, canvas.height);
    }

    // private
    offsetForRotation(rotation, useSketch) {
        let cx = this.canvas.width / 2;
        let cy = this.canvas.height / 2;

        let context = this.getContext(useSketch);
        context.save();

        context.translate(cx, cy);
        context.rotate(Math.PI / 180 * rotation);
        context.translate(-cx, -cy);
    }

    // private
    restoreRotationChanges(useSketch) {
        let context = this.getContext(useSketch);
        context.restore();
    }

    // private
    calculateCanvasSize() {
        let pixelDensityRatio = utils.pixelDensityRatio;
        let viewportSize = this.viewport.getContainerSize();
        return {
            x: viewportSize.x * pixelDensityRatio,
            y: viewportSize.y * pixelDensityRatio
        };
    }

    // private
    calculateSketchCanvasSize() {
        let canvasSize = this.calculateCanvasSize();
        if (this.viewport.getRotation() === 0) {
            return canvasSize;
        }
        // If the viewport is rotated, we need a larger sketch canvas in order
        // to support edge smoothing.
        let sketchCanvasSize = Math.ceil(Math.sqrt(
            canvasSize.x * canvasSize.x +
            canvasSize.y * canvasSize.y));
        return {
            x: sketchCanvasSize,
            y: sketchCanvasSize
        };
    }
}
export default Drawer;