import utils from "./index";

const Level = {
    ERROR : 0,
    WARNING : 1,
    INFO : 2,
    LOG : 3,
    DEBUG : 4
};

class Logger {
    logLevel = Level.LOG;
    constructor(options) {
        this.logLevel = utils.clamp(Math.round(options.logLevel), Level.ERROR) || Level.LOG;
    }
    error(){
        console.error(arguments)
    }
    warning(){
        if(this.logLevel < Level.WARNING) return;
        console.warn(arguments)
    }
    info(){
        if(this.logLevel < Level.INFO) return;
        console.info(arguments)
    }
    log(){
        if(this.logLevel < Level.LOG) return;
        console.log(arguments)
    }
    debug(){
        if(this.logLevel < Level.DEBUG) return;
        console.debug(arguments)
    }
    err() {
        this.error(arguments)
    }
    e() {
        this.error(arguments)
    }
    warn() {
        this.warning(arguments)
    }
    w() {
        this.warning(arguments)
    }
    i() {
        this.info(arguments)
    }
    l() {
        this.log(arguments)
    }
    dbg() {
        this.debug(arguments)
    }
    d() {
        this.debug(arguments)
    }
}

export default Logger
export { Level }
