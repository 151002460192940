import utils from '../utils'

const ControlAnchor = {
    NONE: 0,
    TOP_LEFT: 1,
    TOP_RIGHT: 2,
    BOTTOM_RIGHT: 3,
    BOTTOM_LEFT: 4,
    BOTTOM_CENTER: 6,
    TOP_CENTER: 7,
    ABSOLUTE: 5
};

class Control {
    /**
     * The element providing the user interface with some type of control (e.g. a zoom-in button).
     * @member {HTMLElement} element
     * @memberof XLviewer.Control#
     */
    element;
    /**
     * True if the control should have autofade behavior.
     * @member {Boolean} autoFade
     * @memberof XLviewer.Control#
     */
    autoFade = true;
    /**
     * The position of the Control relative to its container.
     * @member {ControlAnchor} anchor
     * @memberof XLviewer.Control#
     */
    anchor;
    /**
     * The Control's containing element.
     * @member {HTMLElement} container
     * @memberof XLviewer.Control#
     */
    container;
    /**
     * A neutral element surrounding the control element.
     * @member {HTMLElement} wrapper
     * @memberof XLviewer.Control#
     */
    wrapper;
    top;
    left;
    height;
    width;
    constructor( element, options, container ) {
        this.element = element;
        this.container = container;
        let parent = element.parentNode;
        options.attachToViewer = (typeof options.attachToViewer === 'undefined') ? true : options.attachToViewer;
        Object.assign(this, options);


        if ( this.anchor === ControlAnchor.ABSOLUTE ) {
            this.wrapper = utils.makeNeutralElement( "div" );
            this.wrapper.style.position = "absolute";
            this.wrapper.style.top = typeof ( this.top )  == "number" ? ( this.top + 'px' ) : this.top;
            this.wrapper.style.left  = typeof ( this.left )  == "number" ?  (this.left + 'px' ) : this.left;
            this.wrapper.style.height = typeof ( this.height )  == "number" ? ( this.height + 'px' ) : this.height;
            this.wrapper.style.width  = typeof ( this.width )  == "number" ? ( this.width + 'px' ) : this.width;
            this.wrapper.style.margin = "0px";
            this.wrapper.style.padding = "0px";

            this.element.style.position = "relative";
            this.element.style.top = "0px";
            this.element.style.left = "0px";
            this.element.style.height = "100%";
            this.element.style.width = "100%";
        } else {
            this.wrapper    = utils.makeNeutralElement( "div" );
            this.wrapper.style.display = "inline-block";
            this.wrapper.className = "xlv-control-wrapper";
        }
        this.wrapper.appendChild( this.element );

        if (options.attachToViewer ) {
            if ( this.anchor === ControlAnchor.TOP_RIGHT || this.anchor === ControlAnchor.BOTTOM_RIGHT ) {
                this.container.insertBefore( this.wrapper, this.container.firstChild);
            } else {
                this.container.appendChild( this.wrapper );
            }
        } else {
            parent.appendChild( this.wrapper );
        }
    }

    /**
     * Removes the control from the container.
     * @function
     */
    destroy() {
        this.wrapper.removeChild( this.element );
        this.container.removeChild( this.wrapper );
    }

    /**
     * Determines if the control is currently visible.
     * @function
     * @return {Boolean} true if currenly visible, false otherwise.
     */
    isVisible() {
        return this.wrapper.style.display !== "none";
    }

    /**
     * Toggles the visibility of the control.
     * @function
     * @param {Boolean} visible - true to make visible, false to hide.
     */
    setVisible( visible ) {
        this.wrapper.style.display = visible ?
            ( this.anchor === xlv.ControlAnchor.ABSOLUTE ? 'block' : 'inline-block' ) :
            "none";
    }

    /**
     * Sets the opacity level for the control.
     * @function
     * @param {Number} opacity - a value between 1 and 0 inclusively.
     */
    setOpacity( opacity ) {
        utils.setElementOpacity( this.wrapper, opacity  );
    }
}

export default Control;

export {
    ControlAnchor
}