class Point {
    /**
     * The vector component 'x'.
     * @member {Number} x
     * @memberof Point#
     */
    x;
    /**
     * The vector component 'y'.
     * @member {Number} y
     * @memberof Point#
     */
    y;

    constructor(x, y) {
        this.x = typeof (x) == "number" ? x : 0;
        this.y = typeof (y) == "number" ? y : 0;
    }

    /**
     * @function
     * @returns {Point} a duplicate of this Point
     */
    clone() {
        return new Point(this.x, this.y);
    }

    /**
     * Add another Point to this point and return a new Point.
     * @function
     * @param {Point} point The point to add vector components.
     * @returns {Point} A new point representing the sum of the
     *  vector components
     */
    plus(point) {
        return new Point(
            this.x + point.x,
            this.y + point.y
        );
    }

    /**
     * Substract another Point to this point and return a new Point.
     * @function
     * @param {Point} point The point to substract vector components.
     * @returns {Point} A new point representing the substraction of the
     *  vector components
     */
    minus(point) {
        return new Point(
            this.x - point.x,
            this.y - point.y
        );
    }

    /**
     * Multiply this point by a factor and return a new Point.
     * @function
     * @param {Number} factor The factor to multiply vector components.
     * @returns {Point} A new point representing the multiplication
     *  of the vector components by the factor
     */
    times(factor) {
        return new Point(
            this.x * factor,
            this.y * factor
        );
    }

    /**
     * Divide this point by a factor and return a new Point.
     * @function
     * @param {Number} factor The factor to divide vector components.
     * @returns {Point} A new point representing the division of the
     *  vector components by the factor
     */
    divide(factor) {
        return new Point(
            this.x / factor,
            this.y / factor
        );

    }

    /**
     * Compute the opposite of this point and return a new Point.
     * @function
     * @returns {Point} A new point representing the opposite of the
     *  vector components
     */
    negate() {
        return new Point( -this.x, -this.y );
    }

    /**
     * Compute the distance between this point and another point.
     * @function
     * @param {Point} point The point to compute the distance with.
     * @returns {Number} The distance between the 2 points
     */
    distanceTo( point ) {
        return Math.sqrt(
            Math.pow( this.x - point.x, 2 ) +
            Math.pow( this.y - point.y, 2 )
        );
    }

    /**
     * Apply a function to each coordinate of this point and return a new point.
     * @function
     * @param {function} func The function to apply to each coordinate.
     * @returns {Point} A new point with the coordinates computed
     * by the specified function
     */
    apply( func ) {
        return new Point( func( this.x ), func( this.y ) );
    }

    /**
     * Check if this point is equal to another one.
     * @function
     * @param {Point} point The point to compare this point with.
     * @returns {Boolean} true if they are equal, false otherwise.
     */
    equals( point ) {
        return (
            point instanceof Point
        ) && (
            this.x === point.x
        ) && (
            this.y === point.y
        );
    }

    /**
     * Rotates the point around the specified pivot
     * From http://stackoverflow.com/questions/4465931/rotate-rectangle-around-a-point
     * @function
     * @param {Number} rotation degrees to rotate around the pivot.
     * @param {Point} [pivot=(0,0)] Point around which to rotate.
     * Defaults to the origin.
     * @returns {Point}. A new point representing the point rotated around the specified pivot
     */
    rotate (rotation, pivot) {
        pivot = pivot || new Point(0, 0);
        let cos, sin;
        // Avoid float computations when possible
        if (rotation % 90 === 0) {
            let d = rotation % 360;
            if (d < 0) {
                d += 360;
            }
            switch (d) {
                case 0:
                    cos = 1;
                    sin = 0;
                    break;
                case 90:
                    cos = 0;
                    sin = 1;
                    break;
                case 180:
                    cos = -1;
                    sin = 0;
                    break;
                case 270:
                    cos = 0;
                    sin = -1;
                    break;
            }
        } else {
            let angle = rotation * Math.PI / 180.0;
            cos = Math.cos(angle);
            sin = Math.sin(angle);
        }
        let x = cos * (this.x - pivot.x) - sin * (this.y - pivot.y) + pivot.x;
        let y = sin * (this.x - pivot.x) + cos * (this.y - pivot.y) + pivot.y;
        return new Point(x, y);
    }

    toString() {
        return "(" + (Math.round(this.x * 100) / 100) + "," + (Math.round(this.y * 100) / 100) + ")";
    }
}

export default Point;