import EventDispatcher from "../utils/event.dispatcher";
import log from '../log'
import config from "../config";

const WsEvents = {
    CONNECTED : "connected",
    CONNECTION_ERROR : "connectionError",
    ERROR : "error",
    CHAT : "chat",
    CHAT_LIST : "chatList",
}

export { WsEvents };


export default class Ws extends EventDispatcher {
    conn;
    connected = false;

    constructor() {
        super([WsEvents.CONNECTED, WsEvents.CONNECTION_ERROR, WsEvents.ERROR, WsEvents.CHAT_LIST, WsEvents.CHAT])
    }

    connect(){
        log.d("WS.connect()")
        this.conn = new WebSocket(config.wsServer+'?xt='+encodeURIComponent(sessionStorage.token))
        this.conn.onopen = (e) => {this.onConnectionOpen(e)};
        this.conn.onclose =  (e) => {this.onConnectionClose(e)};
        this.conn.onmessage =  (e) => {this.onMessage(e)};
        this.conn.onerror =  (e) => {this.onError(e)};
    }

    onConnectionOpen(e) {
        this.connected = true;
        log.d("Ws.onConnectionOpen()", e);
        this.emit(WsEvents.CONNECTED, e)
    }
    onConnectionClose(e) {
        this.connected = false;
        log.d("Ws.onConnectionClose()", e);
        this.emit(WsEvents.CONNECTION_ERROR, e);
        this.conn.onopen = undefined;
        this.conn.onclose = undefined;
        this.conn.onmessage = undefined;
        this.conn.onerror = undefined;
        setTimeout(()=>{
            this.connect()
        }, 1000)

    }
    onMessage(e) {
        log.d("Ws.onMessage()", e);
        let msg = null;
        try {
            msg = JSON.parse(e.data)
        } catch (e) {
            log.d(e)
            this.dispatchError({ code: 500, status: 'fail', message:'cannot parse message from server'})
            return
        }
        log.d("Ws.onMessage() msg:", msg);


        switch(msg.type) {
            case WsEvents.CHAT:
                if(!msg.data) {
                    this.dispatchError({ code: 500, status: 'fail', message:'invalid message data from server'});
                    return;
                }
                this.emit(WsEvents.CHAT, { data : msg.data })
                break
            case WsEvents.CHAT_LIST:
                if(!msg.data) {
                    this.dispatchError({ code: 500, status: 'fail', message:'invalid message data from server'});
                    return;
                }
                this.emit(WsEvents.CHAT_LIST, { data : msg.data })
                break
        }

    }

    onError(e) {
        log.d("Ws.onError()", e);
        log.d("Ws.onError()", e.toString());
        this.dispatchError({ code: 500, status: 'fail', message:'live connection error'});
    }

    sendChat( data ) {
        if(!this.conn) return;
        this.conn.send(JSON.stringify({ type:WsEvents.CHAT, data }))
    }
    sendChatListRequest() {
        if(!this.conn) return;
        log.d("Ws.sendChatListRequest()")
        this.conn.send(JSON.stringify({type:WsEvents.CHAT_LIST}))
    }
    dispatchError(err) {
        this.emit(WsEvents.ERROR, err)
    }

    close() {
        if(this.connected) {
            this.conn.close(1000);
            this.connected = false;
        }
    }

    dispose() {
        this.conn.onopen = undefined;
        this.conn.onclose = undefined;
        this.conn.onmessage = undefined;
        this.conn.onerror = undefined;
        this.close()
        this.offAll();
    }
}