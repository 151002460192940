import utils from '../utils'
import Control from './Control'
import { ControlAnchor } from './Control'

const getControlIndex = ( dock, element ) => {
    let controls = dock.controls;
    for ( let i = controls.length - 1; i >= 0; i-- ) {
        if ( controls[ i ].element === element ) {
            return i;
        }
    }
    return -1;
}


class ControlDock {
    id;
    element = null;
    #layouts = [ 'topleft', 'topright', 'bottomright', 'bottomleft', 'bottomcenter', 'topcenter'];
    controls = [];
    constructor( options ) {
        Object.assign(this, options);
        this.id = 'controldock-'+Date.now()+'-'+Math.floor(Math.random()*1000000);
        this.container = utils.makeNeutralElement( 'div' );
        this.container.onsubmit = () => false;

        if( this.element ){
            this.element = utils.getElement( this.element );
            this.element.appendChild( this.container );
            this.element.classList.add("xlv-control-dock");
            this.element.style.position = 'relative';
            this.container.style.width = '100%';
            this.container.style.height = '100%';
        }

        for( let i = 0; i < this.#layouts.length; i++ ){
            let layout = this.#layouts[ i ];
            this.controls[ layout ] = utils.makeNeutralElement( "div" );
            this.controls[ layout ].style.position = 'absolute';
            this.controls[ layout ].className = 'xlv-controls xlv-controls-' + layout.toLowerCase();
            if ( layout.match( 'left' ) ){
                this.controls[ layout ].style.left = '0px';
            }
            if ( layout.match( 'right' ) ){
                this.controls[ layout ].style.right = '0px';
            }
            if ( layout.match( 'top' ) ){
                this.controls[ layout ].style.top = '0px';
            }
            if ( layout.match( 'bottom' ) ){
                this.controls[ layout ].style.bottom = '0px';
            }
            if ( layout.match( 'center' ) ){
                this.controls[ layout ].style.width = '100%';
                this.controls[ layout ].style.textAlign = 'center';
            }
            this.container.appendChild( this.controls[ layout ] );
        }
    }

    addControl( element, controlOptions ) {
        console.log("add control")
        element = utils.getElement( element );
        let div = null;
        if ( getControlIndex( this, element ) >= 0 ) {
            return;     // they're trying to add a duplicate control
        }

        switch ( controlOptions.anchor ) {
            case ControlAnchor.TOP_RIGHT:
                div = this.controls.topright;
                element.style.position = "relative";
                element.style.paddingRight = "0px";
                element.style.paddingTop = "0px";
                break;
            case ControlAnchor.BOTTOM_RIGHT:
                div = this.controls.bottomright;
                element.style.position = "relative";
                element.style.paddingRight = "0px";
                element.style.paddingBottom = "0px";
                break;
            case ControlAnchor.BOTTOM_LEFT:
                div = this.controls.bottomleft;
                element.style.position = "relative";
                element.style.paddingLeft = "0px";
                element.style.paddingBottom = "0px";
                break;
            case ControlAnchor.TOP_LEFT:
                div = this.controls.topleft;
                element.style.position = "relative";
                element.style.paddingLeft = "0px";
                element.style.paddingTop = "0px";
                break;
            case ControlAnchor.ABSOLUTE:
                div = this.container;
                element.style.position = "relative";
                element.style.margin = "0px";
                element.style.padding = "0px";
                break;
            case ControlAnchor.TOP_CENTER:
                div = this.controls.topcenter;
                element.style.position = "relative";
                break;
            case ControlAnchor.BOTTOM_CENTER:
                div = this.controls.bottomcenter;
                element.style.position = "relative";
                break;
            default:
            case ControlAnchor.NONE:
                div = this.container;
                element.style.margin = "0px";
                element.style.padding = "0px";
                break;

        }

        this.controls.push(
            new Control( element, controlOptions, div )
        );
        element.style.display = "inline-block";
    }

    removeControl( element ) {
        element = utils.getElement( element );
        let i = getControlIndex( this, element );
        if ( i >= 0 ) {
            this.controls[ i ].destroy();
            this.controls.splice( i, 1 );
        }
        return this;
    }

    clearControls() {
        while ( this.controls.length > 0 ) {
            this.controls.pop().destroy();
        }
        return this;
    }

    areControlsEnabled() {
        let i;
        for ( i = this.controls.length - 1; i >= 0; i-- ) {
            if ( this.controls[ i ].isVisible() ) {
                return true;
            }
        }
        return false;
    }

    setControlsEnabled( enabled ) {
        let i;
        for ( i = this.controls.length - 1; i >= 0; i-- ) {
            this.controls[ i ].setVisible( enabled );
        }
        return this;
    }
}

export default ControlDock;
export {
    ControlAnchor
}

