import {Point} from '../utils'
import utils from '../utils'

class Tile {
    /**
     * The zoom level this tile belongs to.
     * @member {Number} level
     */
    level;
    /**
     * The vector component 'x'.
     * @member {Number}
     */
    x;
    /**
     * The vector component 'y'.
     * @member {Number} y

     */
    y;
    /**
     * Where this tile fits, in normalized coordinates
     * @member {Rect} bounds
     */
    bounds;
    /**
     * Is this tile a part of a sparse image? Also has this tile failed to load?
     * @member {Boolean} exists
     */
    exists;
    /**
     * The URL of this tile's image.
     * @member {String} url
     */
    url;
    /**
     * The context2D of this tile if it is provided directly by the tile source.
     * @member {CanvasRenderingContext2D} context2D
     */
    context2D;
    /**
     * Is this tile loaded?
     * @member {Boolean} loaded
     */
    loaded  = false;
    /**
     * Is this tile loading?
     * @member {Boolean} loading
     */
    loading = false;
    /**
     * The HTML div element for this tile
     * @member {Element} element
     */
    element = null;
    /**
     * The HTML img element for this tile.
     * @member {Element} imgElement
     */
    imgElement = null;
    /**
     * The Image object for this tile.
     * @member {Object} image
     */
    image = null;
    /**
     * The alias of element.style.
     * @member {String} style
     */
    style = null;
    /**
     * This tile's position on screen, in pixels.
     * @member {Point} position
     */
    position = null;
    /**
     * This tile's size on screen, in pixels.
     * @member {Point} size
     */
    size = null;
    /**
     * The start time of this tile's blending.
     * @member {Number} blendStart
     */
    blendStart = null;
    /**
     * The current opacity this tile should be.
     * @member {Number} opacity
     */
    opacity = null;
    /**
     * The distance of this tile to the viewport center.
     * @member {Number} distance
     */
    distance = null;
    /**
     * The visibility score of this tile.
     * @member {Number} visibility
     */
    visibility = null;
    /**
     * Whether this tile is currently being drawn.
     * @member {Boolean} beingDrawn
     */
    beingDrawn = false;
    /**
     * Timestamp the tile was last touched.
     * @member {Number} lastTouchTime
     */
    lastTouchTime = 0;
    cacheImageRecord;
    /**
     * @ignore
     * @class Tile
     * @param {Number} level The zoom level this tile belongs to.
     * @param {Number} x The vector component 'x'.
     * @param {Number} y The vector component 'y'.
     * @param {Rect} bounds Where this tile fits, in normalized
     *      coordinates.
     * @param {Boolean} exists Is this tile a part of a sparse image? ( Also has
     *      this tile failed to load? )
     * @param {String} url The URL of this tile's image.
     * @param {CanvasRenderingContext2D} context2D The context2D of this tile if it
     * is provided directly by the tile source.
     */
    constructor(level, x, y, bounds, exists, url, context2D) {
        this.level   = level;
        this.x       = x;
        this.y       = y;
        this.bounds  = bounds;
        this.exists  = exists;
        this.url     = url;
        this.context2D = context2D;
    }


    /**
     * Provides a string representation of this tiles level and (x,y)
     * components.
     * @function
     * @returns {String}
     */
    toString() {
        return this.level + "/" + this.x + "_" + this.y;
    }

    // private
    hasTransparencyChannel() {
        return this.context2D || this.url.match('.png');
    }


    /**
     * Renders the tile in a canvas-based context.
     * @function
     * @param {CanvasRenderingContext2D} context
     * @param {Function} drawingHandler - Method for firing the drawing event.
     * drawingHandler({context, tile, rendered})
     * where <code>rendered</code> is the context with the pre-drawn image.
     * @param {Number} [scale=1] - Apply a scale to position and size
     * @param {Point} [translate] - A translation vector
     */
    drawCanvas( context, drawingHandler, scale, translate ) {

        let position = this.position.times(utils.pixelDensityRatio),
            size     = this.size.times(utils.pixelDensityRatio),
            rendered;

        if (!this.context2D && !this.cacheImageRecord) {
            throw new Error('[Tile.drawCanvas] attempting to draw tile %s when it\'s not cached: ' + this.toString());
        }

        rendered = this.context2D || this.cacheImageRecord.getRenderedContext();

        if ( !this.loaded || !rendered ){
            throw new Error('[Tile.drawCanvas] Attempting to draw tile '+this.toString()+' when it\'s not yet loaded.: ');
        }

        context.save();
        context.globalAlpha = this.opacity;

        if (typeof scale === 'number' && scale !== 1) {
            // draw tile at a different scale
            position = position.times(scale);
            size = size.times(scale);
        }

        if (translate instanceof Point) {
            // shift tile position slightly
            position = position.plus(translate);
        }

        //if we are supposed to be rendering fully opaque rectangle,
        //ie its done fading or fading is turned off, and if we are drawing
        //an image with an alpha channel, then the only way
        //to avoid seeing the tile underneath is to clear the rectangle
        if (context.globalAlpha === 1 && this.hasTransparencyChannel()) {
            //clearing only the inside of the rectangle occupied
            //by the png prevents edge flikering
            context.clearRect(
                position.x + 1,
                position.y + 1,
                size.x - 2,
                size.y - 2
            );
        }

        // This gives the application a chance to make image manipulation
        // changes as we are rendering the image
        drawingHandler({context: context, tile: this, rendered: rendered});
        context.drawImage(
            rendered.canvas,
            0,
            0,
            rendered.canvas.width,
            rendered.canvas.height,
            position.x,
            position.y,
            size.x,
            size.y
        );
        context.restore();
    }

    /**
     * Get the ratio between current and original size.
     * @function
     * @return {Number}
     */
    getScaleForEdgeSmoothing() {
        if (!this.cacheImageRecord) {
            console.w(
                '[Tile.drawCanvas] attempting to get tile scale %s when tile\'s not cached',
                this.toString());
            return 1;
        }

        let rendered = this.cacheImageRecord.getRenderedContext();
        return rendered.canvas.width / this.size.times(utils.pixelDensityRatio).x;
    }

    /**
     * Get a translation vector that when applied to the tile position produces integer coordinates.
     * Needed to avoid swimming and twitching.
     * @function
     * @param {Number} [scale=1] - Scale to be applied to position.
     * @param {Point} canvasSize.
     * @param {Point} sketchCanvasSize.
     * @return {Point}
     */
    getTranslationForEdgeSmoothing(scale, canvasSize, sketchCanvasSize) {
        // The translation vector must have positive values, otherwise the image goes a bit off
        // the sketch canvas to the top and left and we must use negative coordinates to repaint it
        // to the main canvas. In that case, some browsers throw:
        // INDEX_SIZE_ERR: DOM Exception 1: Index or size was negative, or greater than the allowed value.
        const x = Math.max(1, Math.ceil((sketchCanvasSize.x - canvasSize.x) / 2));
        const y = Math.max(1, Math.ceil((sketchCanvasSize.y - canvasSize.y) / 2));
        return new Point(x, y).minus(
            this.position
                .times(utils.pixelDensityRatio)
                .times(scale || 1)
                .apply((x) => x % 1)
        );
    }

    /**
     * Removes tile from its container.
     * @function
     */
    unload() {
        if ( this.imgElement && this.imgElement.parentNode ) {
            this.imgElement.parentNode.removeChild( this.imgElement );
        }
        if ( this.element && this.element.parentNode ) {
            this.element.parentNode.removeChild( this.element );
        }
        this.element    = null;
        this.imgElement = null;
        this.loaded     = false;
        this.loading    = false;
    }
}

export default Tile