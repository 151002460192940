import { Point, Rect, EventDispatcher, Logger } from './utils'
import utils from "./utils";

import {ControlDock, Drawer, ImageLoader, InteractionManager, Manager, TileCache, Viewport, Navigator, Imgf, TiledImage} from './ui'
import defaults from "./defaults";
import {ControlAnchor} from "./ui/ControlDock";



class XLViewer extends ControlDock {
    /**
     * @type {EventDispatcher}
     */
    dispatcher = new EventDispatcher();
    log;
    canvas = null;
    drawer = null;
    viewport = null;
    navigator = null;
    bodyWidth;
    bodyHeight;
    _prevContainerSize = null;
    _animating = false;
    _forceRedraw = false;
    _mouseInside = false;
    _opening = false;
    _closed = false;
    _zooming = false;
    _fullPage = false;
    _firstOpen = true;
    _updateRequestId = null;
    _loadQueue = [];
    _lastScrollTime = Date.now();
    _rotateEnabled = false;
    currentImageIndex = 0;
    preserveViewport;
    imageLoader;
    /**
     * @type InteractionManager
     */
    innerTracker;

    constructor(options) {
        super(options);
        Object.assign(this, defaults);
        Object.assign(this, options);

        this.log = new Logger({logLevel : options.logLevel})

        if(!this.image && this.images && this.images.length) {
            this.image = this.images[0];
        }

        this.element = this.element || document.getElementById( this.id );
        this.canvas = utils.makeNeutralElement( "div" );
        this.canvas.className = "xlv-stage"; //"xlv-canvas"

        utils.applyStyle(this.canvas, {
            width : "100%",
            height : "100%",
            overflow : "hidden",
            position : "absolute",
            top : "0px",
            left : "0px"
        });

        utils.setElementTouchActionNone( this.canvas );

        this.container.className = "xlv-root"; //"xlv-container"
        utils.applyStyle(this.container, {
            width : "100%",
            height : "100%",
            overflow : "hidden",
            position : "relative",
            textAlign : "left",
            top : "0px",
            left : "0px"
        });

        this.container.insertBefore( this.canvas, this.container.firstChild );
        this.element.appendChild( this.container );
        this.bodyWidth = document.body.style.width;
        this.bodyHeight = document.body.style.height;

        this.innerTracker = new InteractionManager({
            element: this.canvas,
            startDisabled: !this.mouseEnabled,
            clickMaxDuration: this.clickMaxDuration,
            clickAreaRadius: this.clickAreaRadius,
            doubleClickMaxDuration: this.doubleClickMaxDuration,
            doubleClickAreaRadius: this.doubleClickAreaRadius,
            keyDownHandler: (e) => { this.onKeyDown(e) },
            keyHandler: (e) => { this.onKeyPress(e) },
            clickHandler: (e) => { this.onClick(e) },
            dblClickHandler: (e) => { this.onDoubleClick(e) },
            dragHandler: (e) => { this.onDrag(e) },
            dragEndHandler: (e) => { this.onDragEnd(e) },
            enterHandler: (e) => { this.onEnter(e) },
            exitHandler: (e) => { this.onExit(e) },
            pressHandler: (e) => { this.onPress(e) },
            releaseHandler: (e) => { this.onRelease(e) },
            nonPrimaryPressHandler: (e) => { this.onNonPrimaryPress(e) },
            nonPrimaryReleaseHandler: (e) => { this.onNonPrimaryRelease(e) },
            scrollHandler: (e) => { this.onScroll(e) },
            pinchHandler: (e) => { this.onPinch(e) },
            moveHandler : (e) => { this.onMove(e) },
        });

        this._prevContainerSize = utils.getSafeElemSize( this.container );

        this.manager = new Manager({
            core: this
        });

        this.manager.addListener('addItem', (event) => {
            this._forceRedraw = true;

            if (!this._updateRequestId) {
                this._updateRequestId = requestAnimationFrame( () => {
                    this.updateMulti();
                } );
            }
        });

        this.manager.addListener('removeItem', (event) => {
            this._forceRedraw = true;
        });

        this.manager.addListener('metricsChange', (event) => {
            if (this.viewport) {
                this.viewport.setContentBounds(this.manager.getHomeBounds(), this.manager.getContentFactor());
            }
        });


        // Create the viewport
        this.viewport = new Viewport({
            containerSize: this._prevContainerSize,
            tweenSmoothness: this.tweenSmoothness,
            tweenDuration: this.tweenDuration,
            underZoomRatio: this.underZoomRatio,
            overZoomRatio: this.overZoomRatio,
            visibilityRatio: this.visibilityRatio,
            wrapHorizontal: this.wrapHorizontal,
            wrapVertical: this.wrapVertical,
            defaultZoomLevel: this.defaultZoomLevel,
            minZoomLevel: this.minZoomLevel,
            maxZoomLevel: this.maxZoomLevel,
            viewer: this,
            rotation: this.rotation,
            homeFillsViewer: this.homeFillsViewer,
            //margins: this.viewportMargins
        });

        this.viewport.setContentBounds(this.manager.getHomeBounds(), this.manager.getContentFactor());

        // Create the image loader
        this.imageLoader = new ImageLoader({
            jobLimit: this.imageLoaderLimit
        });

        // Create the tile cache
        this.tileCache = new TileCache({
            maxImageCacheCount: this.maxImageCacheCount
        });

        // Create the drawer
        this.drawer = new Drawer({
            viewer:             this,
            viewport:           this.viewport,
            element:            this.canvas,
            debugGridColor:     this.debugGridColor
        });

        //Instantiate a navigator if configured
        if ( this.useNavigator){
            this.navigator = new Navigator({
                id:                this.navigatorConfig.id,
                source :           this.source,
                image:             this.image,
                quality:           this.quality,
                position:          this.navigatorConfig.position,
                sizeRatio:         this.navigatorConfig.sizeRatio,
                maintainSizeRatio: this.navigatorConfig.maintainSizeRatio,
                top:               this.navigatorConfig.top,
                left:              this.navigatorConfig.left,
                width:             this.navigatorConfig.width,
                height:            this.navigatorConfig.height,
                autoResize:        this.navigatorConfig.autoResize,
                rotate:            this.navigatorConfig.rotate,
                borderWidth:       this.navigatorConfig.borderWidth,
                borderColor:        this.navigatorConfig.borderColor,
                focusStroke:       this.navigatorConfig.focusStroke,
                focusColor:        this.navigatorConfig.focusColor,
                viewer:            this,
                enableCORS:        this.enableCORS
            });
        }

        this.imageInfoUrl = Imgf.buildInfoUrl(this.source, this.image);
        if (this.imageInfoUrl) {
            this.open( { imageInfoUrl : this.imageInfoUrl } );
        }
    }

    /**
     * @function
     * @return {Boolean}
     */
    isOpen() {
        return !!this.manager.getItemCount();
    }

    /**
     * @function
     * Open an imgf image
     * @param image {string} - filename widthout server url
     * @param onOpenCallback {function?} - callback called when image is opened, can be undefined
     */
    openImage(image, onOpenCallback) {
        this.image = image;
        this.imageInfoUrl = Imgf.buildInfoUrl(this.source, this.image);
        if(this.navigator) {
            this.navigator.image = this.image;
            this.navigator.imageInfoUrl = this.imageInfoUrl;
        }

        // Open initial tilesources
        if (this.imageInfoUrl) {
            this.open( { imageInfoUrl : this.imageInfoUrl }, onOpenCallback );
        }
    }

    /**
     * @function
     * Open tiled images into the viewer, closing any others.
     * @param options {Object} - viewer options
     * @param onOpenCallback {function?} - callback called when image is opened, can be undefined
     */
    open(options, onOpenCallback) {
        this.options = options;
        this.close();
        if (!options.imageInfoUrl) {
            return;
        }

        this._opening = true;
        let expected = 1;
        let successes = 0;
        let failures = 0;
        let failEvent;

        let onComplete = (success) => {
            if(onOpenCallback) {
                onOpenCallback(success);
            }
            if (success) {
                if (this._firstOpen || !this.preserveViewport) {
                    this.viewport.doZoomReset( true );
                    this.viewport.update();
                }

                this._firstOpen = false;
                this._opening = false;
                /**
                 * Dispatched when the viewer has opened and loaded one or more TileSources.
                 */
                // TODO: what if there are multiple sources?
                this.dispatchEvent( 'open' );
            } else {
                this._opening = false;

                /**
                 * Dispatched when an error occurs loading a TileSource.
                 */
                this.dispatchEvent( 'openFailed', failEvent );
            }
        };


        if (options.index !== undefined) {
            this.log.e('[Viewer.open] setting indexes here is not supported; use addTiledImage instead');
            delete options.index;
        }

        if (options.collectionImmediately === undefined) {
            options.collectionImmediately = true;
        }

        let originalSuccess = options.success;
        options.success = function(event) {
            if (originalSuccess) {
                originalSuccess(event);
            }
            onComplete(true);
        };

        let originalError = options.error;
        options.error = function(event) {
            failures++;

            if (!failEvent) {
                failEvent = event;
            }

            if (originalError) {
                originalError(event);
            }

            onComplete(false);
        };

        this.addTiledImage(options);

        return this;
    }

    /**
     * Close the currently opened image
     */
    close( ) {
        if ( this._closed ) {
            //this viewer has already been destroyed: returning immediately
            return;
        }

        this._opening = false;

        if ( this.navigator ) {
            this.navigator.destroy();
        }

        this._animating = false;
        this.manager.removeAll();
        this.imageLoader.clear();

        /**
         * Dispatched when the viewer is closed
         */
        this.dispatchEvent( 'close' );
        this._closed = true;
    }

    /**
     * Function to destroy the viewer and clean up everything created by 
     */
    destroy() {
        if(!this.element) return;

        this.close();

        if ( this._updateRequestId !== null ) {
            cancelAnimationFrame( this._updateRequestId );
            this._updateRequestId = null;
        }

        if ( this.drawer ) {
            this.drawer.destroy();

        }

        this.removeAllHandlers();

        // Go through top element (passed to us) and remove all children
        // Use removeChild to make sure it handles SVG or any non-html
        // also it performs better - http://jsperf.com/innerhtml-vs-removechild/15
        if (this.element){
            while (this.element.firstChild) {
                this.element.removeChild(this.element.firstChild);
            }
        }

        // destroy the mouse trackers
        if (this.innerTracker){
            this.innerTracker.destroy();
        }

        // clear all our references to dom objects
        this.canvas = null;
        this.container = null;

        // clear our reference to the main element - they will need to pass it in again, creating a new viewer
        this.element = null;
        this.drawer = null;
        this.manager = null;
        this.innerTracker = null;

    }

    /**
     *  true/false to enable/disable mouse
     *  @param enabled {boolean}
     */
    setMouseNavEnabled( enabled ){
        this.innerTracker.setTracking( enabled );
        /**
         * Dispatched when mouse/touch navigation is enabled or disabled (see setMouseNavEnabled).
         */
        this.dispatchEvent( 'mouseEnabled', { enabled: enabled } );
    }

    /**
     * @function
     * return if is ion full page
     * @return {boolean}
     */
    isFullPage() {
        return this._fullPage;
    }

    /**
     * Toggle full page mode.
     * @function
     * @param {boolean} fullPage
     *      If true, enter full page mode.  If false, exit full page mode.
     * @fires event:pre-full-page
     * @fires event:full-page
     */
    setFullPage( fullPage ) {

        let body = document.body,
            bodyStyle = body.style,
            docStyle = document.documentElement.style,
            hash,
            nodes,
            i;

        //dont bother modifying the DOM if we are already in full page mode.
        if ( fullPage === this.isFullPage() ) {
            return;
        }

        let fullPageEventArgs = {
            fullPage: fullPage,
            preventDefaultAction: false
        };
        /**
         * Dispatched when the viewer is about to change to/from fullPage mode.
         */
        this.dispatchEvent( 'preFullPage', fullPageEventArgs );
        if ( fullPageEventArgs.preventDefaultAction ) {
            return;
        }

        if ( fullPage ) {

            this.elementSize = utils.getElementSize( this.element );
            this.pageScroll = utils.getPageScroll();

            this.elementMargin = this.element.style.margin;
            this.element.style.margin = "0";
            this.elementPadding = this.element.style.padding;
            this.element.style.padding = "0";

            this.bodyMargin = bodyStyle.margin;
            this.docMargin = docStyle.margin;
            bodyStyle.margin = "0";
            docStyle.margin = "0";

            this.bodyPadding = bodyStyle.padding;
            this.docPadding = docStyle.padding;
            bodyStyle.padding = "0";
            docStyle.padding = "0";

            this.bodyWidth = bodyStyle.width;
            this.bodyHeight = bodyStyle.height;
            bodyStyle.width = "100%";
            bodyStyle.height = "100%";

            //when entering full screen on the ipad it wasnt sufficient to leave
            //the body intact as only only the top half of the screen would
            //respond to touch events on the canvas, while the bottom half treated
            //them as touch events on the document body.  Thus we remove and store
            //the bodies elements and replace them when we leave full screen.
            this.previousBody = [];
            this.prevElementParent = this.element.parentNode;
            this.prevNextSibling = this.element.nextSibling;
            this.prevElementWidth = this.element.style.width;
            this.prevElementHeight = this.element.style.height;
            let i, nodes = body.childNodes.length;
            for ( i = 0; i < nodes; i++ ) {
                this.previousBody.push( body.childNodes[ 0 ] );
                body.removeChild( body.childNodes[ 0 ] );
            }
            this.element.classList.add('fullpage')
            body.appendChild( this.element );

            this.element.style.height = window.innerHeight + 'px';
            this.element.style.width = window.innerWidth + 'px';
            this._fullPage = true;
            this.onContainerEnter({});
        } else {

            this.element.style.margin = this.elementMargin;
            this.element.style.padding = this.elementPadding;

            bodyStyle.margin = this.bodyMargin;
            docStyle.margin = this.docMargin;

            bodyStyle.padding = this.bodyPadding;
            docStyle.padding = this.docPadding;

            bodyStyle.width = this.bodyWidth;
            bodyStyle.height = this.bodyHeight;

            body.removeChild( this.element );
            nodes = this.previousBody.length;
            for ( i = 0; i < nodes; i++ ) {
                body.appendChild( this.previousBody.shift() );
            }

            this.element.classList.remove('fullpage');

            this.prevElementParent.insertBefore(
                this.element,
                this.prevNextSibling
            );

            this.element.style.width = this.prevElementWidth;
            this.element.style.height = this.prevElementHeight;

            // After exiting fullPage or fullScreen, it can take some time
            // before the browser can actually set the scroll.
            let restoreScrollCounter = 0;
            let restoreScroll = function() {
                window.scrollTo( this.pageScroll.x, this.pageScroll.y );
                let pageScroll = utils.getPageScroll();
                restoreScrollCounter++;
                if ( restoreScrollCounter < 10 &&
                    pageScroll.x !== this.pageScroll.x ||
                    pageScroll.y !== this.pageScroll.y ) {
                    requestAnimationFrame( restoreScroll );
                }
            };
            requestAnimationFrame( restoreScroll );

            this._fullPage = false;

            // mouse will likely be outside now
            this.onContainerExit({ pointers : 0 })

        }

        if ( this.navigator && this.viewport ) {
            this.navigator.update( this.viewport );
        }

        /**
         * Dispatched when the viewer has changed to/from fullPage mode
         */
        this.dispatchEvent( 'fullPage', { fullPage: fullPage } );

        return this;
    }


    /**
     * Toggle full screen mode if supported. Toggle full page mode otherwise.
     * @param fullScreen {boolean} - true to go fullscreen, false to exit
     */
    setFullScreen( fullScreen ) {
        if ( !document.exitFullscreen ) {
            return this.setFullPage( fullScreen );
        }

        let isFullscreen = document.fullscreenElement !== null;
        if ( isFullscreen === fullScreen ) {
            return;
        }

        let fullScreeEventArgs = {
            fullScreen: fullScreen,
            preventDefaultAction: false
        };
        /**
         * Dispatched when the viewer is about to change to/from full screen mode
         * Note: the preFullScreen event is not raised when the user is exiting
         * fullScreen mode by pressing the Esc key. In that case, consider using
         * the fullScreen, preFullPage or fullPage events.
         */
        this.dispatchEvent( 'preFullScreen', fullScreeEventArgs );
        if ( fullScreeEventArgs.preventDefaultAction ) {
            return this;
        }

        if ( fullScreen ) {

            this.setFullPage( true );
            // If the full page mode is not actually entered, we need to prevent
            // the full screen mode.
            if ( !this.isFullPage() ) {
                return this;
            }

            this.fullPageStyleWidth = this.element.style.width;
            this.fullPageStyleHeight = this.element.style.height;
            this.element.style.width = '100%';
            this.element.style.height = '100%';

            let onFullScreenChange = () => {
                isFullscreen = document.fullscreenElement !== null;
                if ( !isFullscreen ) {
                    utils.unbind( document, 'fullscreenchange', onFullScreenChange );
                    utils.unbind( document, 'fullscreenerror', onFullScreenChange );

                    this.setFullPage( false );
                    if ( this.isFullPage() ) {
                        this.element.style.width = this.fullPageStyleWidth;
                        this.element.style.height = this.fullPageStyleHeight;
                    }
                }
                if ( this.navigator && this.viewport ) {
                    this.navigator.update( this.viewport );
                }
                /**
                 * Dispatched when the viewer has changed to/from full-screen mode.
                 */
                this.dispatchEvent( 'fullScreen', { fullScreen: isFullscreen } );
            };
            utils.bind( document, 'fullscreenchange', onFullScreenChange );
            utils.bind( document, 'fullscreenerror', onFullScreenChange );
            document.body.requestFullScreen();

        } else {
            document.exitFullscreen();
        }
        return this;
    }

    /**
     * @function
     * Toggle the ratotation state of the viewer
     */
    toggleRotate() {
        this._rotateEnabled = !this._rotateEnabled;
    }

    /**
     * Return if viewer is visible
     * @returns {boolean}
     */
    isVisible() {
        return this.container.style.visibility !== "hidden";
    }

    /**
     * Set viewer visibility
     * @param visible {boolean}
     * @returns {XLviewer} - viewer instance
     */
    setVisible( visible ){
        this.container.style.visibility = visible ? "" : "hidden";
        /**
         * Dispatched when the viewer is shown or hidden (see {@link XLViewer#setVisible}).
         */
        this.dispatchEvent( 'visible', { visible: visible } );
    }

    addStaticPreview(staticPreview) {
        if(!staticPreview) {
            return;
        }
        this.staticPreview = staticPreview;
        this.container.insertBefore( this.staticPreview.element, this.container.firstChild );
    }
    addHotspotLayer(layer) {
        if(!layer) {
            return;
        }
        this.hotspotLayer = layer;
        this.addControl(this.hotspotLayer.element, {anchor: ControlAnchor.NONE});
        //this.container.appendChild( this.hotspotLayer.element );
    }

    setImageVisibility(visible) {
        const visibility = visible ? "visible" : "hidden";
        utils.applyStyle(this.canvas,{ visibility });
    }

    /**
     * @ignore
     * Add a tiled image to the viewer.
     * options.tileSource can be anything that {@link Core#open}
     *  supports except arrays of images.
     * Note that you can specify options.width or options.height, but not both.
     * The other dimension will be calculated according to the item's aspect ratio.
     */
    addTiledImage( options ) {
        this.log.d(options, "[Core.addTiledImage] options is required");
        this.log.d(!options.replace || (options.index > -1 && options.index < this.manager.getItemCount()),
            "[Core.addTiledImage] if options.replace is used, options.index must be a valid index in Core.manager");

        if (options.replace) {
            options.replaceItem = this.manager.getItemAt(options.index);
        }

        this.#hideMessage();

        if (options.placeholderFillStyle === undefined) {
            options.placeholderFillStyle = this.placeholderFillStyle;
        }
        if (options.opacity === undefined) {
            options.opacity = this.opacity;
        }
        if (options.blendingType === undefined) {
            options.blendingType = this.blendingType;
        }

        let myQueueItem = {
            options: options
        };

        let raiseAddItemFailed = ( event ) => {
            for (let i = 0; i < this._loadQueue.length; i++) {
                if (this._loadQueue[i] === myQueueItem) {
                    this._loadQueue.splice(i, 1);
                    break;
                }
            }

            if (this._loadQueue.length === 0) {
                refreshScene(myQueueItem);
            }

            /**
             * Dispatched when an error occurs while adding a item.
             */
            this.dispatchEvent( 'addItemFailed', event );

            if (options.error) {
                options.error(event);
            }
        }

        let refreshScene = (theItem) => {
            if (this.collectionMode) {
                this.manager.arrange({
                    immediately: theItem.options.collectionImmediately,
                    rows: this.collectionRows,
                    columns: this.collectionColumns,
                    layout: this.collectionLayout,
                    tileSize: this.collectionTileSize,
                    tileMargin: this.collectionTileMargin
                });
                this.manager.setAutoRefigureSizes(true);
            }
        }

        if(Array.isArray(options.tileSource)) {
            setTimeout(function() {
                raiseAddItemFailed({
                    message: "[Viewer.addTiledImage] Sequences can not be added; add them one at a time instead.",
                    source: options.tileSource,
                    options: options
                });
            });
            return;
        }

        this._loadQueue.push(myQueueItem);
        Imgf.loadTileSource( this, this.imageInfoUrl, ( tileSource ) => {

            myQueueItem.tileSource = tileSource;

            // add everybody at the front of the queue that's ready to go
            let queueItem, tiledImage, optionsClone;
            while (this._loadQueue.length) {
                queueItem = this._loadQueue[0];
                if (!queueItem.tileSource) {
                    break;
                }

                this._loadQueue.splice(0, 1);

                if (queueItem.options.replace) {
                    let newIndex = this.manager.getIndexOfItem(queueItem.options.replaceItem);
                    if (newIndex !== -1) {
                        queueItem.options.index = newIndex;
                    }
                    this.manager.removeItem(queueItem.options.replaceItem);
                }

                tiledImage = new TiledImage({
                    viewer: this,
                    quality: this.quality,
                    source: queueItem.tileSource,
                    viewport: this.viewport,
                    drawer: this.drawer,
                    tileCache: this.tileCache,
                    imageLoader: this.imageLoader,
                    x: queueItem.options.x,
                    y: queueItem.options.y,
                    width: queueItem.options.width,
                    height: queueItem.options.height,
                    fitBounds: queueItem.options.fitBounds,
                    fitBoundsPlacement: queueItem.options.fitBoundsPlacement,
                    clip: queueItem.options.clip,
                    placeholderFillStyle: queueItem.options.placeholderFillStyle,
                    opacity: queueItem.options.opacity,
                    blendingType: queueItem.options.blendingType,
                    tweenSmoothness: this.tweenSmoothness,
                    tweenDuration: this.tweenDuration,
                    underZoomRatio: this.underZoomRatio,
                    wrapHorizontal: this.wrapHorizontal,
                    wrapVertical: this.wrapVertical,
                    noWaitForRendering: this.noWaitForRendering,
                    blendDuration: this.blendDuration,
                    alwaysBlend: this.alwaysBlend,
                    minPixelRatio: this.minPixelRatio,
                    overZoomRatioForSmoothingTile: this.overZoomRatioForSmoothingTile,
                    enableCORS: this.enableCORS,
                    debug: this.debug
                });

                this.manager.addItem( tiledImage, {
                    index: queueItem.options.index
                });

                if (this._loadQueue.length === 0) {
                    //this restores the autoRefigureSizes flag to true.
                    refreshScene(queueItem);
                }

                if (this.manager.getItemCount() === 1 && !this.preserveViewport) {
                    this.viewport.doZoomReset(true);
                }

                if (this.navigator) {
                    optionsClone = Object.assign({}, queueItem.options, {
                        originalTiledImage: tiledImage,
                        tileSource: queueItem.tileSource
                    });

                    this.navigator.loadImage();
                }

                if (queueItem.options.success) {
                    queueItem.options.success({
                        item: tiledImage
                    });
                }
            }
        }, function( event ) {
            event.options = options;
            raiseAddItemFailed(event);
        } );
    }

    /**
     * If is set param "images" on the XLviewer instance, go to next image.
     * If "is3D" is true, it maintains the selected bounds
     */
    next() {
        if(!Array.isArray(this.images) || this.images.length < 2) {
            return
        }
        this.currentImageIndex++;
        if(this.currentImageIndex >= this.images.length) {
            this.currentImageIndex = 0;
        }
        if (this.is3D) {
            this.preserveViewport = true;
            this.blendDuration = 0;
        }
        this.openImage(this.images[this.currentImageIndex]);
        if(this.staticPreview) {
            this.staticPreview.update();
        }
    }
    /**
     *  If is set "images" on the XLviewer instance, go to previous image.
     *  If "is3D" is true, it mantains the selected bounds
     */
    prev() {
        if(!Array.isArray(this.images) || this.images.length < 2) {
            return
        }
        this.currentImageIndex--;
        if(this.currentImageIndex < 0) {
            this.currentImageIndex = this.images.length - 1
        }
        if (this.is3D) {
            this.preserveViewport = true;
            this.blendDuration = 0;
        }
        this.openImage(this.images[this.currentImageIndex])
        if(this.staticPreview) {
            this.staticPreview.update();
        }
    }

    /**
     * Force the viewer to redraw its contents.
     */
    forceRedraw() {
        this._forceRedraw = true;
    }
    /**
     * @ignore
     * Cancel the "in flight" images.
     */
    cancelPendingImages() {
        this._loadQueue = [];
    }


    /**
     * Do an automatic zoom in
     */
    zoomIn() {
        if ( this.viewport ) {
            this.zooming = false;
            this.viewport.zoomBy(
                this.clickZoomFactor / 1.0
            );
            this.viewport.applyConstraints();
        }
        if(this.staticPreview) {
            this.staticPreview.stopAutorotate();
        }
    }
    /**
     * Do an automatic zoom out
     */
    zoomOut() {
        if ( this.viewport ) {
            this.zooming = false;
            this.viewport.zoomBy(
                1.0 / this.clickZoomFactor
            );
            this.viewport.applyConstraints();
        }
        if(this.staticPreview) {
            this.staticPreview.stopAutorotate();
        }
    }
    /**
     * Reset the zoom to fit the image in viewport
     */
    zoomReset() {
        if ( this.viewport ) {
            this.viewport.setRotation(0);
            this.viewport.doZoomReset();
        }
        if(this.staticPreview) {
            this.staticPreview.stopAutorotate();
        }
    }

    /**
     * Rotate left by 90°
     */
    rotateLeft() {
        if ( this.viewport ) {
            if(this.staticPreview && this.staticPreview.enabled) {
                this.staticPreview.stopAutorotate();
                this.staticPreview.toggle();
            }
            let currRotation = this.viewport.getRotation();
            if (currRotation === 0) {
                currRotation = 270;
            }
            else {
                currRotation -= 90;
            }
            this.viewport.setRotation(currRotation);
        }
    }

    /**
     * Rotate right by 90°
     */
    rotateRight() {
        if ( this.viewport ) {
            if(this.staticPreview && this.staticPreview.enabled) {
                this.staticPreview.stopAutorotate();
                this.staticPreview.toggle();
            }
            let currRotation = this.viewport.getRotation();
            if (currRotation === 270) {
                currRotation = 0;
            }
            else {
                currRotation += 90;
            }
            this.viewport.setRotation(currRotation);
        }
    }

    /**
     * Effettua uno zoom verso un certo rettangolo {Rect}
     * le dimensioni del rettangolo vanno da 0 a 1.
     * @param bounds {Rect} rettangolo di destinazione
     * @param immediately {boolean} se false va al rettangolo di destinazione con una animazioneù
     * @param applyConstraint {boolean} se true aggiusta il bpounds fornito per non violare delle regole di corretta visualizzazione
     */
    zoomToBounds(bounds, immediately, applyConstraint) {
        this.viewport.fitNormalizedBounds(bounds, immediately, applyConstraint);
    }

    /**
     * Ritorna il rettangolo dei bounds normalizzato alle dimensioni che vanno da 0 a 1
     * @param current {boolean} - se false indica di ritornare il bounds di destinazione invece di quello corrente in caso di animazione in corso
     * @returns {Rect}
     */
    getBounds( current ) {
        return this.viewport.getNormalizedBounds( current );
    }

    /**
     * Ritorna il rettangolo dei bounds in pixel
     * @param current {boolean} - se false indica di ritornare il bounds di destinazione invece di quello corrente in caso di animazione in corso
     * @returns {Rect}
     */
    getPixelBounds( current ) {
        return this.viewport.getBounds( current );
    }


    /**
     * Ritorna il rettangolo dei bounds normalizzato alle dimensioni che vanno da 0 a 1
     * @returns {Rect}
     */
    getCurrentImageBounds( current ) {
        let bounds = this.getBounds(current);
        let contSize = this.viewport.getContainerSize();
        let w = Math.round(contSize.x / bounds.width);
        let h = Math.round(contSize.y / bounds.height);
        let x = -Math.round(w * bounds.x);
        let y = -Math.round(h * bounds.y);
        return new Rect(x,y,w,h);
    }






    /**
     * data un un punto con coordinate (0 - 1) ritorna la stesso punto in pixel rispetto la scene
     * @param coord {Point}
     * @param current {Boolean}
     * @returns {Point}
     */
    localToScene(coord , current) {
        // prendo le misure reali dell'immagine alla view corrente
        let imageBounds = this.getCurrentImageBounds( current );
        if(!imageBounds)
            return null;
        let x = Math.round(coord.x * imageBounds.width + imageBounds.x);
        let y = Math.round(coord.y * imageBounds.height + imageBounds.y);
        return new Point(x, y);
    }

    sceneToLocal(coord , current, notNormalized) {
        let bounds = this.getBounds( current );
        let contSize = this.viewport.getContainerSize();

        let rawX = (coord.x * bounds.width) / contSize.x + bounds.x;
        let rawY = (coord.y * bounds.height) / contSize.y + bounds.y;

        if(notNormalized) {
            return new Point(rawX, rawY);
        }

        let localX = utils.clamp(rawX, 0, 1);
        let localY = utils.clamp(rawY, 0, 1);
        return new Point(localX, localY);
    }

    getSize() {
        return utils.getElementSize( this.element );
    }

    /**
     * @ignore
     * Display a message in the viewport
     */
    #showMessage( message ) {
        this.#hideMessage();
        let div = utils.makeNeutralElement( "div" );
        div.appendChild( document.createTextNode( message ) );
        this.messageDiv = utils.makeCenteredNode( div );
        this.messageDiv.classList.add("xlv-message")
        this.container.appendChild( this.messageDiv );
    }

    /**
     * @ignore
     * Hide any currently displayed viewport message
     */
    #hideMessage() {
        let div = this.messageDiv;
        if (div) {
            div.parentNode.removeChild(div);
            delete this.messageDiv;
        }
    }

    onKeyDown( event ) {
        if ( !event.preventDefaultAction && !event.ctrl && !event.alt && !event.meta ) {
            switch( event.keyCode ){
                case 38://up arrow
                    if ( event.shift ) {
                        this.viewport.zoomBy(1.1);
                    } else {
                        this.viewport.panBy(this.viewport.deltaPointsFromPixels(new Point(0, -40)));
                    }
                    this.viewport.applyConstraints();
                    return false;
                case 40://down arrow
                    if ( event.shift ) {
                        this.viewport.zoomBy(0.9);
                    } else {
                        this.viewport.panBy(this.viewport.deltaPointsFromPixels(new Point(0, 40)));
                    }
                    this.viewport.applyConstraints();
                    return false;
                case 37://left arrow
                    this.viewport.panBy(this.viewport.deltaPointsFromPixels(new Point(-40, 0)));
                    this.viewport.applyConstraints();
                    return false;
                case 39://right arrow
                    this.viewport.panBy(this.viewport.deltaPointsFromPixels(new Point(40, 0)));
                    this.viewport.applyConstraints();
                    return false;
                default:
                    //console.log( 'navigator keycode %s', event.keyCode );
                    return true;
            }
        } else {
            return true;
        }
    }

    onKeyPress( event ) {
        if ( !event.preventDefaultAction && !event.ctrl && !event.alt && !event.meta ) {
            switch( event.keyCode ){
                case 43://=|+
                case 61://=|+
                    this.viewport.zoomBy(1.1);
                    this.viewport.applyConstraints();
                    return false;
                case 45://-|_
                    this.viewport.zoomBy(0.9);
                    this.viewport.applyConstraints();
                    return false;
                case 48://0|)
                    this.viewport.doZoomReset();
                    this.viewport.applyConstraints();
                    return false;
                case 119://w
                case 87://W
                    if ( event.shift ) {
                        this.viewport.zoomBy(1.1);
                    } else {
                        this.viewport.panBy(this.viewport.deltaPointsFromPixels(new Point(0, -40)));
                    }
                    this.viewport.applyConstraints();
                    return false;
                case 115://s
                case 83://S
                    if ( event.shift ) {
                        this.viewport.zoomBy(0.9);
                    } else {
                        this.viewport.panBy(this.viewport.deltaPointsFromPixels(new Point(0, 40)));
                    }
                    this.viewport.applyConstraints();
                    return false;
                case 97://a
                    this.viewport.panBy(this.viewport.deltaPointsFromPixels(new Point(-40, 0)));
                    this.viewport.applyConstraints();
                    return false;
                case 100://d
                    this.viewport.panBy(this.viewport.deltaPointsFromPixels(new Point(40, 0)));
                    this.viewport.applyConstraints();
                    return false;
                default:
                    //console.log( 'navigator keycode %s', event.keyCode );
                    return true;
            }
        } else {
            return true;
        }
    }

    onClick( event ) {
        let gestureSettings;
        let haveKeyboardFocus = document.activeElement === this.canvas;
        // If we don't have keyboard focus, request it.
        if ( !haveKeyboardFocus ) {
            this.canvas.focus();
        }

        let coords = null;
        if ( !event.preventDefaultAction && this.viewport && event.quick ) {
            gestureSettings = this.gestureSettingsByDeviceType( event.pointerType );
            if ( gestureSettings.trackClickZoom ) {
                coords = this.viewport.pointFromPixel( event.position, true )
                this.viewport.zoomBy(
                    event.shift ? 1.0 / this.clickZoomFactor : this.clickZoomFactor,
                    coords
                );
                this.viewport.applyConstraints();
                this.dispatchEvent( 'zoomClick', {
                    tracker: event.eventSource,
                    position: event.position,
                    quick: event.quick,
                    shift: event.shift,
                    originalEvent: event.originalEvent,
                    coords
                });
            }
        }
        /**
         * Raised when a mouse press/release or touch/remove occurs on the canvas element.
         */
        this.dispatchEvent( 'canvasClick', {
            tracker: event.eventSource,
            position: event.position,
            quick: event.quick,
            shift: event.shift,
            originalEvent: event.originalEvent,
            coords
        });
    }

    onDoubleClick( event ) {
        let gestureSettings;
        let coords;
        if ( !event.preventDefaultAction && this.viewport ) {
            gestureSettings = this.gestureSettingsByDeviceType( event.pointerType );
            if ( gestureSettings.trackDoubleClickZoom ) {
                coords = this.viewport.pointFromPixel( event.position, true );
                this.viewport.zoomBy(
                    event.shift ? 1.0 / this.clickZoomFactor : this.clickZoomFactor,
                    coords
                );
                this.viewport.applyConstraints();
                this.dispatchEvent( 'zoomClick', {
                    tracker: event.eventSource,
                    position: event.position,
                    quick: event.quick,
                    shift: event.shift,
                    originalEvent: event.originalEvent,
                    coords
                });
            }
        }
        /**
         * Raised when a double mouse press/release or touch/remove occurs on the canvas element.
         */
        this.dispatchEvent( 'canvasDoubleClick', {
            tracker: event.eventSource,
            position: event.position,
            shift: event.shift,
            originalEvent: event.originalEvent,
            coords,
        });
    }

    onDrag( event ) {
        let gestureSettings;

        if ( !event.preventDefaultAction && this.viewport ) {
            gestureSettings = this.gestureSettingsByDeviceType( event.pointerType );
            this.viewport.panBy( this.viewport.deltaPointsFromPixels( event.delta.negate() ), gestureSettings.afterDragTweenEnabled );
            this.viewport.applyConstraints();
        }
        /**
         * Raised when a mouse or touch drag operation occurs on the canvas element.
         */
        this.dispatchEvent( 'canvasDrag', {
            tracker: event.eventSource,
            position: event.position,
            delta: event.delta,
            speed: event.speed,
            direction: event.direction,
            shift: event.shift,
            originalEvent: event.originalEvent
        });
    }

    onDragEnd( event ) {
        if (!event.preventDefaultAction && this.viewport) {
            let gestureSettings = this.gestureSettingsByDeviceType(event.pointerType);
            if (gestureSettings.afterDragTweenEnabled &&
                event.speed >= gestureSettings.afterDragTweenMinSpeed) {
                let amplitudeX = gestureSettings.afterDragTweenAccel * event.speed * Math.cos(event.direction);
                let amplitudeY = gestureSettings.afterDragTweenAccel * event.speed * Math.sin(event.direction);
                let center = this.viewport.pixelFromPoint(
                    this.viewport.getCenter(true));
                let target = this.viewport.pointFromPixel(
                    new Point(center.x - amplitudeX, center.y - amplitudeY));
                this.viewport.panTo(target, false);
            }
            this.viewport.applyConstraints();
        }
        /**
         * Raised when a mouse or touch drag operation ends on the canvas element.
         */
        this.dispatchEvent('canvasDragEnd', {
            tracker: event.eventSource,
            position: event.position,
            speed: event.speed,
            direction: event.direction,
            shift: event.shift,
            originalEvent: event.originalEvent
        });
    }

    onEnter( event ) {
        /**
         * Raised when a pointer enters the canvas element.
         */
        this.dispatchEvent( 'canvasEnter', {
            tracker: event.eventSource,
            pointerType: event.pointerType,
            position: event.position,
            buttons: event.buttons,
            pointers: event.pointers,
            insideElementPressed: event.insideElementPressed,
            buttonDownAny: event.buttonDownAny,
            originalEvent: event.originalEvent
        });
    }

    onExit( event ) {
        /**
         * Raised when a pointer leaves the canvas element.
         */
        this.dispatchEvent( 'canvasExit', {
            tracker: event.eventSource,
            pointerType: event.pointerType,
            position: event.position,
            buttons: event.buttons,
            pointers: event.pointers,
            insideElementPressed: event.insideElementPressed,
            buttonDownAny: event.buttonDownAny,
            originalEvent: event.originalEvent
        });
    }

    onPress( event ) {
        /**
         * Raised when the primary mouse button is pressed or touch starts on the canvas element.
         */
        this.dispatchEvent( 'canvasPress', {
            tracker: event.eventSource,
            pointerType: event.pointerType,
            position: event.position,
            insideElementPressed: event.insideElementPressed,
            insideElementReleased: event.insideElementReleased,
            originalEvent: event.originalEvent
        });
    }

    onRelease( event ) {
        /**
         * Raised when the primary mouse button is released or touch ends on the canvas element.
         */
        this.dispatchEvent( 'canvasRelease', {
            tracker: event.eventSource,
            pointerType: event.pointerType,
            position: event.position,
            insideElementPressed: event.insideElementPressed,
            insideElementReleased: event.insideElementReleased,
            originalEvent: event.originalEvent
        });
    }

    onMove( event ) {
        /**
         * Raised when the primary mouse button is released or touch ends on the canvas element.
         */
        this.dispatchEvent( 'canvasMove', {
            tracker: event.eventSource,
            pointerType: event.pointerType,
            position: event.position,
            insideElementPressed: event.insideElementPressed,
            insideElementReleased: event.insideElementReleased,
            originalEvent: event.originalEvent
        });
    }

    onNonPrimaryPress( event ) {
        /**
         * Raised when any non-primary pointer button is pressed on the canvas element.
         */
        this.dispatchEvent( 'canvasNonprimaryPress', {
            tracker: event.eventSource,
            position: event.position,
            pointerType: event.pointerType,
            button: event.button,
            buttons: event.buttons,
            originalEvent: event.originalEvent
        });
    }

    onNonPrimaryRelease( event ) {
        /**
         * Raised when any non-primary pointer button is released on the canvas element.
         */
        this.dispatchEvent( 'canvasNonprimaryRelease', {
            tracker: event.eventSource,
            position: event.position,
            pointerType: event.pointerType,
            button: event.button,
            buttons: event.buttons,
            originalEvent: event.originalEvent
        });
    }

    onPinch( event ) {
        let gestureSettings,
            centerPt,
            lastCenterPt,
            panByPt;

        if ( !event.preventDefaultAction && this.viewport ) {
            gestureSettings = this.gestureSettingsByDeviceType( event.pointerType );
            if ( gestureSettings.trackPinchZoom ) {
                centerPt = this.viewport.pointFromPixel( event.center, true );
                lastCenterPt = this.viewport.pointFromPixel( event.lastCenter, true );
                panByPt = lastCenterPt.minus( centerPt );
                if( this.blockHorizontalPanning ) {
                    panByPt.x = 0;
                }
                if( this.blockVerticalPanning ) {
                    panByPt.y = 0;
                }
                this.viewport.zoomBy( event.distance / event.lastDistance, centerPt, true );
                this.viewport.panBy( panByPt, true );
                this.viewport.applyConstraints();
            }
            if ( gestureSettings.trackPinchRotate && this._rotateEnabled ) {
                // Pinch rotate
                let angle1 = Math.atan2(event.gesturePoints[0].currentPos.y - event.gesturePoints[1].currentPos.y,
                    event.gesturePoints[0].currentPos.x - event.gesturePoints[1].currentPos.x);
                let angle2 = Math.atan2(event.gesturePoints[0].lastPos.y - event.gesturePoints[1].lastPos.y,
                    event.gesturePoints[0].lastPos.x - event.gesturePoints[1].lastPos.x);
                this.viewport.setRotation(this.viewport.getRotation() + ((angle1 - angle2) * (180 / Math.PI)));
            }
        }
        /**
         * Raised when a pinch event occurs on the canvaS element.
         */
        this.dispatchEvent('canvasPinch', {
            tracker: event.eventSource,
            gesturePoints: event.gesturePoints,
            lastCenter: event.lastCenter,
            center: event.center,
            lastDistance: event.lastDistance,
            distance: event.distance,
            shift: event.shift,
            originalEvent: event.originalEvent
        });
        //cancels event
        return false;
    }

    onScroll( event ) {
        let gestureSettings,
            factor,
            thisScrollTime,
            scrollTrackDuration;

        /* Certain scroll devices fire the scroll event way too fast so we are injecting a simple adjustment to keep things
        * partially normalized. If we have already fired an event within the last 'minScrollDelta' milliseconds we skip
        * this one and wait for the next event. */
        thisScrollTime = Date.now();
        scrollTrackDuration = thisScrollTime - this._lastScrollTime;
        if (scrollTrackDuration > this.scrollTrackMinDuration) {
            this._lastScrollTime = thisScrollTime;

            if ( !event.preventDefaultAction && this.viewport ) {
                gestureSettings = this.gestureSettingsByDeviceType( event.pointerType );
                if ( gestureSettings.trackScrollZoom ) {
                    factor = Math.pow( this.scrollZoomFactor, event.scroll );
                    this.viewport.zoomBy(
                        factor,
                        this.viewport.pointFromPixel( event.position, true )
                    );
                    this.viewport.applyConstraints();
                }
            }
            /**
             * Raised when a scroll event occurs on the canvas element (mouse wheel).
             */
            this.dispatchEvent( 'canvasScroll', {
                tracker: event.eventSource,
                position: event.position,
                scroll: event.scroll,
                shift: event.shift,
                originalEvent: event.originalEvent
            });
            if (gestureSettings && gestureSettings.trackScrollZoom) {
                //cancels event
                return false;
            }
        }
        else {
            gestureSettings = this.gestureSettingsByDeviceType( event.pointerType );
            if (gestureSettings && gestureSettings.trackScrollZoom) {
                return false; // We are swallowing this event
            }
        }
    }

    onContainerEnter( event ) {
        this._mouseInside = true;
        /**
         * Raised when the cursor enters the container element.
         */
        this.dispatchEvent( 'containerEnter', {
            tracker: event.eventSource,
            position: event.position,
            buttons: event.buttons,
            pointers: event.pointers,
            insideElementPressed: event.insideElementPressed,
            buttonDownAny: event.buttonDownAny,
            originalEvent: event.originalEvent
        });
    }

    onContainerExit( event ) {
        if ( event.pointers < 1 ) {
            this._mouseInside = false;
        }
        /**
         * Raised when the cursor leaves the container element.
         */
        this.dispatchEvent( 'containerExit', {
            tracker: event.eventSource,
            position: event.position,
            buttons: event.buttons,
            pointers: event.pointers,
            insideElementPressed: event.insideElementPressed,
            buttonDownAny: event.buttonDownAny,
            originalEvent: event.originalEvent
        });
    }

    gestureSettingsByDeviceType( type ) {
        switch ( type ) {
            case 'touch':
                return this.touchBehavior;
            case 'mouse':
            default:
                return this.mouseBehavior;
        }
    }

    updateMulti() {
        this.updateOnce();

        // Request the next frame, unless we've been closed
        if ( this.isOpen() ) {
            this._updateRequestId = requestAnimationFrame( () => {
                this.updateMulti();
            });
        } else {
            this._updateRequestId = false;
        }
    }

    updateOnce() {

        //viewer.profiler.beginUpdate();

        if (this._opening) {
            return;
        }

        let containerSize;
        if ( this.autoResizeViewer ) {
            containerSize = utils.getSafeElemSize( this.container );
            if ( !containerSize.equals( this._prevContainerSize ) ) {
                if ( !this.autoResizeImage ) {
                    let prevContainerSize = this._prevContainerSize;
                    let bounds = this.viewport.getBounds(true);
                    let deltaX = (containerSize.x - prevContainerSize.x);
                    let deltaY = (containerSize.y - prevContainerSize.y);
                    let viewportDiff = this.viewport.deltaPointsFromPixels(new Point(deltaX, deltaY), true);
                    this.viewport.resize(new Point(containerSize.x, containerSize.y), false);

                    // Keep the center of the image in the center and just adjust the amount of image shown
                    bounds.width += viewportDiff.x;
                    bounds.height += viewportDiff.y;
                    bounds.x -= (viewportDiff.x / 2);
                    bounds.y -= (viewportDiff.y / 2);
                    this.viewport.fitBoundsWithConstraints(bounds, true);
                }
                else {
                    // maintain image position
                    let oldBounds = this.viewport.getBounds();
                    let oldCenter = this.viewport.getCenter();
                    this.resizeViewportAndRecenter(containerSize, oldBounds, oldCenter);
                }
                this._prevContainerSize = containerSize;
                this._forceRedraw = true;
            }
        }

        let viewportChange = this.viewport.update();
        let animated = this.manager.update() || viewportChange;

        if (viewportChange) {
            /**
             * Raised when any spring animation update occurs (zoom, pan, etc.),
             * before the viewer has drawn the new location.
             */
            this.dispatchEvent('viewportChange');
            if(this.navigator) {
                this.navigator.update(this.viewport);
            }
        }

        if ( !this._animating && animated ) {
            /**
             * Raised when any spring animation starts (zoom, pan, etc.).
             */
            this.dispatchEvent( "animationStart" );
        }

        if ( animated || this.forceRedraw || this.manager.needsDraw() ) {
            this.drawWorld();
            if( this.navigator ){
                this.navigator.update( this.viewport );
            }

            this._forceRedraw = false;

            if (animated) {
                /**
                 * Raised when any spring animation update occurs (zoom, pan, etc.),
                 * after the viewer has drawn the new location.
                 */
                this.dispatchEvent( "animation" );
            }
        }

        if ( this._animating && !animated ) {
            /**
             * Raised when any spring animation ends (zoom, pan, etc.).
             */
            this.dispatchEvent( "animationFinish" );
        }

        this._animating = animated;

        //viewer.profiler.endUpdate();
    }

    resizeViewportAndRecenter( containerSize, oldBounds, oldCenter ) {
        let viewport = this.viewport;

        viewport.resize( containerSize, true );

        let newBounds = new Rect(
            oldCenter.x - ( oldBounds.width / 2.0 ),
            oldCenter.y - ( oldBounds.height / 2.0 ),
            oldBounds.width,
            oldBounds.height
        );

        // let the viewport decide if the bounds are too big or too small
        viewport.fitBoundsWithConstraints( newBounds, true );
    }

    drawWorld() {
        this.imageLoader.clear();
        this.drawer.clear();
        this.manager.draw();
        this.dispatchEvent( 'updateViewport', {} );
    }


    /** INTREFACE TO DISPATCHER **/
    addListenerOnce(eventName, handler, userData, times) {
        this.dispatcher.addListenerOnce(eventName, handler, userData, times);
    }

    /**
     * Add an event handler for a given event.
     */
    addListener( eventName, handler, userData ) {
        this.dispatcher.addListener( eventName, handler, userData );
    }

    /**
     * Remove a specific event handler for a given event.
     */
    removeListener( eventName, handler ) {
        this.dispatcher.removeListener( eventName, handler );
    }


    /**
     * Remove all event handlers for a given event type. If no type is given all
     * event handlers for every event type are removed.
     */
    removeAllHandlers( eventName ) {
        this.dispatcher.removeAllHandlers( eventName );
    }

    /**
     * Get a function which iterates the list of all handlers registered for a given event, calling the handler for each.
     */
    getHandler( eventName ) {
        this.dispatcher.getHandler( eventName );
    }

    /**
     * Dispatch an event, optionally passing additional information.
     */
    dispatchEvent( eventName, eventArgs ) {
        this.dispatcher.dispatchEvent( eventName, eventArgs );
    }


}

export default XLViewer

export {
    Rect, Point
}