import { Level } from './utils/Logger'

export default {
    //DATA SOURCE DETAILS
    id: "xlviewer",
    source: 'http://localhost:7711/closer/',
    image : null,
    images : null,
    enableCORS : true,
    is3D: false,
    quality : 75,
    //PAN AND ZOOM SETTINGS AND CONSTRAINTS
    visibilityRatio: 1, //-> how much of the viewer can be negative space
    minPixelRatio: 0.8, //->closer to 0 draws tiles meant for a higher zoom at this zoom
    defaultZoomLevel: 0,
    minZoomLevel: null,
    maxZoomLevel: null,
    blockHorizontalPanning: false,
    blockVerticalPanning: false,
    //UI RESPONSIVENESS AND FEEL
    clickMaxDuration: 300,
    clickAreaRadius: 5,
    doubleClickMaxDuration: 300,
    doubleClickAreaRadius: 20,
    tweenSmoothness: 3.5,
    tweenDuration: 0.4,
    mouseBehavior: {
        trackScrollZoom: true,
        trackClickZoom: true,
        trackDoubleClickZoom: false,
        trackPinchZoom: false,
        afterDragTweenEnabled: true,
        afterDragTweenMinSpeed: 120,
        afterDragTweenAccel: 0.25,
        trackPinchRotate: false
    },
    touchBehavior: {
        trackScrollZoom: false,
        trackClickZoom: false,
        trackDoubleClickZoom: true,
        trackPinchZoom: true,
        afterDragTweenEnabled: true,
        afterDragTweenMinSpeed: 120,
        afterDragTweenAccel: 0.25,
        trackPinchRotate: true
    },
    clickZoomFactor: 2,
    scrollZoomFactor: 1.2,
    defaultZoomFactor: 1.0,
    blendDuration: 0.5,
    alwaysBlend: false,
    noWaitForRendering: false,
    underZoomRatio: 1, //-> closer to 0 allows zoom out to infinity
    overZoomRatio: 1, //-> higher allows 'over zoom' into pixels
    overZoomRatioForSmoothingTile: 1, //-> higher than overZoomRatio disables it
    mouseScrollFactor: 40,
    scrollTrackMinDuration: 50,
    //DEFAULT CONTROL SETTINGS
    autoResizeViewer: true,
    autoResizeImage: true, // requires autoResizeViewer=true
    mouseEnabled: true,  //GENERAL MOUSE INTERACTIVITY
    //VIEWPORT NAVIGATOR SETTINGS
    useNavigator: false,
    navigatorConfig : {
        id : null,
        position : null,
        sizeRatio: 0.2,
        maintainSizeRatio: false,
        top: null,
        left: null,
        height: null,
        width: null,
        autoResize: true,
        rotate: true,
        borderWidth : 1,
        borderColor : '#555',
        focusColor : '#900',
        focusStroke : 2,
    },
    // INITIAL ROTATION
    rotation: 0,
    // APPEARANCE
    opacity: 1,
    // blendingType = canvas.context.globalCompositeOperation -> (default source-over)
    // http://www.w3schools.com/tags/canvas_globalcompositeoperation.asp
    // no sense to change this
    blendingType: null,
    placeholderFillStyle: null,
    //PERFORMANCE SETTINGS
    imageLoaderLimit: 16,
    maxImageCacheCount: 200,
    timeout: 30000,
    useCanvas: true,  // Use canvas element for drawing if available
    //DEVELOPER SETTINGS
    debug: false,
    logLevel : Level.LOG,
    debugGridColor: '#ff0000'
};