import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import Chat from 'vue3-beautiful-chat'
import './styles/variables.scss'
import './css/style.css'
import ws from "@/plugins/ws";
import heatmap from "@/plugins/heatmap";
import helps from "@/plugins/helps";
import annotations from "@/plugins/annotations";
import auth from "@/plugins/auth";
import localizations from "@/plugins/localizations";
import data from "@/plugins/data";
import vote from "@/plugins/vote";
import router from "@/router";
loadFonts()

createApp(App)
  .use(localizations)
  .use(data)
  .use(vuetify)
  .use(ws)
  .use(Chat)
  .use(auth)
  .use(heatmap)
  .use(helps)
  .use(annotations)
  .use(router)
  .use(vote)
  .mount('#app')
