import { reactive, computed } from "vue";
import Ws, {WsEvents} from "@/ws";

export default {
    install: (app) => {
        const language = (navigator.language || navigator.userLanguage).toLowerCase().slice(0, 2);
        const ws = new Ws()
        const state = reactive({
            nicknameDialog : false,
            chats : [],
            nickname : localStorage.nickname || null,
            connected : false,
            error : null,
            sendChat : ( text ) => {
                ws.sendChat({
                    nickname : state.nickname,
                    message : text,
                    language
                })
            },
            getChats() {
                ws.sendChatListRequest();
            },
        })

        state.waitForConnection = new Promise((resolve)=>{
            if(state.connected) {
                resolve();
                return;
            }
            let intv = setInterval(()=>{
                if(state.connected) {
                    clearInterval(intv);
                    resolve();
                }
            })
        });


        ws.on(WsEvents.CONNECTED, () => {
            state.connected = true;
        })
        ws.on(WsEvents.CONNECTION_ERROR, () => {
            state.connected = false;
        })
        ws.on(WsEvents.ERROR, (e) => {
            state.error = e;
        })
        ws.on(WsEvents.CHAT, ({data}) => {
            state.chats.push(data);
        })
        ws.on(WsEvents.CHAT_LIST, ({data}) => {
            state.chats = data;
        })
        state.connect = ()=> {
            ws.connect();
        };
        //ws.connect();

        app.provide('ws', state)
    },
}