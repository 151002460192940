import {reactive, computed, ref} from "vue";
import axios from "axios";
import config from "@/config";

export default {
    install: (app) => {
        const items = ref({});
        axios.get(`${config.server}/localizations`, { headers : {'x-token':sessionStorage.token}}).then(res => {
            items.value = res.data.data || {};
        }).catch(e => {
            console.log("error loading localization data", e)
        })

        app.provide('localizations', {
            L(key) {
                const language = (navigator.language || navigator.userLanguage).toLowerCase().slice(0, 2);
                let dict = items.value[language] || {};
                let dictEn = items.value.en || {};
                let dictIt = items.value.it || {};
                return dict[key] || dictEn[key] || dictIt[key] || key;
            }
        })
    },
}