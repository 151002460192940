class Tween{
    initial;
    /**
     * Spring stiffness.
     * @member {Number} tweenSmoothness
     * @memberof Tween#
     */
    tweenSmoothness;
    /**
     * Animation duration per spring.
     * @member {Number} tweenDuration
     * @memberof Tween#
     */
    tweenDuration;
    #exponential = false
    /**
     * @member {Object} current
     * @property {Number} value
     * @property {Number} time
     */
    current = {
        value : 0,
        time : 0,
    };
    /**
     * @member {Object} start
     * @property {Number} value
     * @property {Number} time
     */
    start = {
        value : 0,
        time : 0,
    };
    /**
     * @member {Object} target
     * @property {Number} value
     * @property {Number} time
     */
    target = {
        value : 0,
        time : 0,
    };
    
    constructor(options) {

        if(!(typeof options.tweenSmoothness === "number") || options.tweenSmoothness === 0) {
            throw new Error("[Tween] options.tweenSmoothness must be a non-zero number")
        }
        if(!(typeof options.tweenDuration === "number") || options.tweenDuration <= 0) {
            throw new Error("[Tween] options.tweenDuration must be a number greater than or equal to 0")
        }


        this.initial = options.initial;
        this.tweenSmoothness = options.tweenSmoothness;
        this.tweenDuration = options.tweenDuration;
        if (options.exponential) {
            this.#exponential = true;
            delete options.exponential;
        }
        Object.assign(this, options)

        this.current = {
            value: typeof ( this.initial ) == "number" ?
                this.initial :
                (this.#exponential ? 0 : 1),
            time:  Date.now() // always work in milliseconds
        };
        this.start = {
            value: this.current.value,
            time:  this.current.time
        };
        this.target = {
            value: this.current.value,
            time:  this.current.time
        };
        if (this.#exponential) {
            this.start._logValue = Math.log(this.start.value);
            this.target._logValue = Math.log(this.target.value);
            this.current._logValue = Math.log(this.current.value);
        }
    }

    /**
     * @function
     * @param {Number} target
     */
    resetTo( target ) {
        if(this.#exponential && target === 0) {
            throw new Error("[Tween.resetTo] target must be non-zero for exponential springs")
        }

        this.start.value = this.target.value = this.current.value = target;
        this.start.time = this.target.time = this.current.time = Date.now();

        if (this.#exponential) {
            this.start._logValue = Math.log(this.start.value);
            this.target._logValue = Math.log(this.target.value);
            this.current._logValue = Math.log(this.current.value);
        }
    }

    /**
     * @function
     * @param {Number} target
     */
    springTo( target ) {
        if(this.#exponential && target === 0) {
            throw new Error("[Tween.resetTo] target must be non-zero for exponential springs")
        }

        this.start.value  = this.current.value;
        this.start.time   = this.current.time;
        this.target.value = target;
        this.target.time  = this.start.time + 1000 * this.tweenDuration;

        if (this.#exponential) {
            this.start._logValue = Math.log(this.start.value);
            this.target._logValue = Math.log(this.target.value);
        }
    }

    /**
     * @function
     * @param {Number} delta
     */
    shiftBy( delta ) {
        this.start.value  += delta;
        this.target.value += delta;

        if (this.#exponential) {
            if(this.target.value === 0 || this.start.value === 0) {
                throw new Error("[Tween.resetTo] spring value must be non-zero for exponential springs")
            }

            this.start._logValue = Math.log(this.start.value);
            this.target._logValue = Math.log(this.target.value);
        }
    }

    setExponential(value) {
        this.#exponential = value;

        if (this.#exponential) {
            if(this.current.value === 0 || this.target.value === 0 || this.start.value === 0) {
                throw new Error("[Tween.setExponential] spring value must be non-zero for exponential springs")
            }
            this.start._logValue = Math.log(this.start.value);
            this.target._logValue = Math.log(this.target.value);
            this.current._logValue = Math.log(this.current.value);
        }
    }

    /**
     * @function
     */
    update() {
        this.current.time  = Date.now();

        let startValue, targetValue;
        if (this.#exponential) {
            startValue = this.start._logValue;
            targetValue = this.target._logValue;
        } else {
            startValue = this.start.value;
            targetValue = this.target.value;
        }

        let currentValue = (this.current.time >= this.target.time) ?
            targetValue :
            startValue +
            ( targetValue - startValue ) *
            this.#transform(
                this.tweenSmoothness,
                ( this.current.time - this.start.time ) /
                ( this.target.time  - this.start.time )
            );

        if (this.#exponential) {
            this.current.value = Math.exp(currentValue);
        } else {
            this.current.value = currentValue;
        }
    }

    /**
     * @private
     */
    #transform( stiffness, x ) {
        return ( 1.0 - Math.exp( stiffness * -x ) ) /
            ( 1.0 - Math.exp( -stiffness ) );
    }
}

export default Tween;