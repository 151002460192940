class EventDispatcher {
    constructor() {}

    _events = {};
    /**
     * Add an event handler to be triggered only once (or a given number of times)
     * for a given event.
     */
    addListenerOnce(eventName, handler, userData, times) {
        times = times || 1;
        let count = 0;
        let onceHandler = (event) => {
            count++;
            if (count === times) {
                this.removeListener(eventName, onceHandler);
            }
            handler(event);
        };
        this.addListener(eventName, onceHandler, userData);
    }

    /**
     * Add an event handler for a given event.
     */
    addListener( eventName, handler, userData ) {
        let events = this._events[ eventName ];
        if ( !events ) {
            this._events[ eventName ] = events = [];
        }
        if ( handler && typeof handler === 'function') {
            events[ events.length ] = { handler: handler, userData: userData || null };
        }
    }

    /**
     * Remove a specific event handler for a given event.
     */
    removeListener( eventName, handler ) {
        let events = this._events[ eventName ],
            handlers = [],
            i;
        if ( !events ) {
            return;
        }
        if ( Array.isArray( events ) ) {
            for ( i = 0; i < events.length; i++ ) {
                if ( events[i].handler !== handler ) {
                    handlers.push( events[ i ] );
                }
            }
            this._events[ eventName ] = handlers;
        }
    }


    /**
     * Remove all event handlers for a given event type. If no type is given all
     * event handlers for every event type are removed.
     */
    removeAllHandlers( eventName ) {
        if ( eventName ){
            this._events[ eventName ] = [];
        } else{
            for ( let eventType in this._events ) {
                this._events[ eventType ] = [];
            }
        }
    }

    /**
     * Get a function which iterates the list of all handlers registered for a given event, calling the handler for each.
     */
    getHandler( eventName ) {
        let events = this._events[ eventName ];
        if ( !events || !events.length ) {
            return null;
        }
        events = events.length === 1 ?
            [ events[ 0 ] ] :
            Array.apply( null, events );
        return function ( source, args ) {
            let i, length = events.length;
            for ( i = 0; i < length; i++ ) {
                if ( events[ i ] ) {
                    args.eventSource = source;
                    args.userData = events[ i ].userData;
                    events[ i ].handler( args );
                }
            }
        };
    }

    /**
     * Dispatch an event, optionally passing additional information.
     */
    dispatchEvent( eventName, eventArgs ) {

        let handler = this.getHandler( eventName );
        if ( handler ) {
            if ( !eventArgs ) {
                eventArgs = {};
            }
            handler( this, eventArgs );
        }
    }
}

export default EventDispatcher;