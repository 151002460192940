import {reactive, computed, ref} from "vue";
import axios from "axios";
import config from "@/config";

export default {
    install: (app) => {
        const slides = ref([]);
        app.provide('helps', {
            show : ref(false),
            slides,
            async load() {
                try {
                    let res = await axios.get(`${config.server}/helps?limit=0`, { headers : {'x-token':sessionStorage.token}})
                    if(res.data.data) {
                        slides.value = res.data.data;
                    }
                    return true
                } catch (e) {
                    console.log("error loading help data", e)
                    return false
                }

            }
        })
    },
}