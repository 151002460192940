const LEVEL = "prod";

const commons = {
    defaultLanguage : 'en',
};

const dev = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    server : 'http://localhost:10015',
    wsServer : 'ws://localhost:8000/ws',
    breraServer : 'https://breraplus.org',
    manualToken : true,
};
const devlan = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    server : 'http://192.168.1.42:10015',
    wsServer : 'ws://192.168.1.42:8000/ws',
    breraServer : 'https://breraplus.org',
    manualToken : true,
};
const preprod = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    server : 'https://api.brera.artcentrica.com',
    wsServer : 'wss://api.brera.artcentrica.com/ws',
    breraServer : 'https://breraplus.org',
    manualToken : true,
};

const prod = {
    debug : false,
    logEnabled : false,
    logLevel : 'error', // debug, info, warning, error
    server : 'https://api.brera.artcentrica.com',
    wsServer : 'wss://api.brera.artcentrica.com/ws',
    breraServer : 'https://breraplus.org',
    manualToken : false,
};

let config;

switch (LEVEL) {
    case "prod":
        config = Object.assign(commons, prod);
        break;
    case "preprod":
        config = Object.assign(commons, preprod);
        break;
    case "devlan":
        config = Object.assign(commons, devlan);
        break
    case "dev":
    default:
        config = Object.assign(commons, dev);
        break;
}

export default config;