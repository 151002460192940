<template>
  <div class="xlv-wrap" >
    <div ref="wrap" :class="'xlviewer ' + className"></div>
  </div>

</template>

<script>
import {
  onMounted,
  onBeforeUnmount,
  ref, computed, watch,
} from "vue";
import _ from 'lodash'
import XLviewer from '../xlviewer'
import Point from '../xlviewer/utils/Point'
import Rect from '../xlviewer/utils/Rect'
import utils from '../utils'
import log from '../log'
export default {
  name: "XlViewer",
  props: {
    modelValue : {
      type: String
    },
    server : {
      type: String
    },
    quality : {
      type: Number,
      default: 90
    },
    className: {
      type: String,
      default : ''
    },
    forceHttps : {
      type :Boolean,
      default : false
    },
    navigator : {
      type:Boolean,
      default : true
    },
  },
  emits : ['update:modelValue','viewportChange','open','resize','panEnd','zoomClick'],
  setup(props, ctx) {
    const wrap = ref(null)
    const _initialized = ref(false)
    let viewer, toolbar, ro;
    const seed = ref(0)

    const _onViewportChange = () => {
      //console.log(this.viewer.getBounds( true ))
      ctx.emit('viewportChange', viewer.getBounds( true ))
    }
    const _onImageOpen = () => {
      ctx.emit('open');
      _initialized.value = true;
    }

    const _onResize = () => {
      ctx.emit('resize', {width:wrap.value.offsetWidth, height:wrap.value.offsetHeight})
    }

    const _onClick = (e) => {
      ctx.emit('zoomClick', e)
    }
    const _onDragEnd = (e) => {
      ctx.emit('panEnd', e)
    }

    const _build = () => {
      if(!props.modelValue)  {
        return;
      }
      viewer = new XLviewer({
        // debug: true,
        element: wrap.value,
        quality: props.quality,
        useNavigator : props.navigator,
        source : serverUrl.value,
        image : props.modelValue
      });
      viewer.addListener('viewportChange', _onViewportChange)
      viewer.addListener('open', _onImageOpen)
      viewer.addListener('zoomClick', _onClick)
      viewer.addListener('canvasDragEnd', _onDragEnd)
    }
    const _load = () => {
      if(viewer) {
        viewer.destroy();
      }
      _build();
    }

    const serverUrl = computed(() => {
      if(!props.server) return '';
      let url = props.server;
      if(props.forceHttps && url.indexOf('https://') === -1) {
        url = url.replace('http://', 'https://')
      }
      return url;
    })

    onMounted(() => {
      ro = new ResizeObserver(_onResize);
      ro.observe(wrap.value, {box:"border-box"});
      if(!props.modelValue) return;
      _load();
    });
    onBeforeUnmount(()=>{
      viewer && viewer.destroy();
      ro && ro.unobserve(wrap.value)
      ro = null;
    });

    ctx.expose({
      setLocked( value ) {
        viewer.setMouseNavEnabled(!value)
      },
      zoomIn() {
        viewer.zoomIn()
      },
      zoomOut() {
        viewer.zoomOut()
      },
      reset() {
        viewer.zoomReset()
      },
      getPoint( clientX, clientY ) {
        return viewer.sceneToLocal( new Point(clientX,clientY), true, false )
      },
      getLocalPoint( boundsX, boundsY ) {
        return viewer.localToScene( new Point(boundsX,boundsY), true )
      },
      getBounds( current ) {
        return viewer.getBounds( current )
      },
      measure( a, b, w, h ) {
        let pointA = viewer.sceneToLocal( new Point(a.x,a.y), true, true )
        pointA.x *= w;
        pointA.y *= h;
        let pointB = viewer.sceneToLocal( new Point(b.x,b.y), true, true )
        pointB.x *= w;
        pointB.y *= h;
        return utils.calculateDistanceBetweenTwoPoints(pointA, pointB)
      },
      getZoomPercent() {
        if(!viewer) return 0;
        let min = viewer.viewport.getMinZoom();
        let max = viewer.viewport.getMaxZoom();
        let current = viewer.viewport.getZoom(true)
        return Math.round(_.clamp(100 / (max - min)  * (current - min), 0 , 100))
      },
      zoomToBounds(x, y, w, h, immediately, applyConstraint) {
        log.d("zoom to bounds!", x, y, w, h, immediately, applyConstraint)
        viewer.zoomToBounds( new Rect(x,y,w,h), immediately, applyConstraint);
      },
      forceRedraw() {
        seed.value++
      },
      initialized : computed(() => _initialized.value),
      width: computed(() => wrap.value.offsetWidth),
      height: computed(() => wrap.value.offsetHeight),
    });
    return {
      wrap,
      viewer,
      toolbar,
      seed,
      ro,
      serverUrl,
    }
  }
}
</script>


