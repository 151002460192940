// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// Vuetify
import { createVuetify  } from 'vuetify'


const breraTheme = {
    dark: true,
    colors: {
        background: '#FFFFFF',
        surface: '#1c1f22',
        primary: '#a4947d',
        'primary-darken-1': '#000000',
        secondary: '#1c1f22',
        'secondary-darken-1': '#000000',
        error: '#FF0000',
        info: '#999999',
        success: '#4CAF50',
        warning: '#FFC107',
    }
};


export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    {
        components,
        directives,
        theme: {
            defaultTheme: 'breraTheme',
            themes: {
                breraTheme,
            }
        }
    }
)

/*
primary: '#FD4616',
        secondary: '#1C222B',
        accent: '#FD4616',
        error: '#FF0000',
        info: '#999999',
        success: '#4CAF50',
        warning: '#FFC107'
 */

/*
import it from 'vuetify/es5/locale/it';
import en from 'vuetify/es5/locale/en';
import de from 'vuetify/es5/locale/de';
import zhHans from 'vuetify/es5/locale/zh-Hans';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FD4616',
        secondary: '#1C222B',
        accent: '#FD4616',
        error: '#FF0000',
        info: '#999999',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#FD4616',
        secondary: '#1C222B',
        accent: '#FD4616',
        error: '#FF0000',
        info: '#999999',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
    lang: {
      locales: { it, en, de, zhHans },
      current: 'en',
    },
});

 */