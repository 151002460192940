<template>
  <div class="full-width fill-height">
    <v-img src="../assets/bg.png" class="full-width fill-height" cover ></v-img>

    <div class="d-flex align-center flex-column full-width" style="position: absolute; bottom: 64px">
      <h1 class="text-white playfair text-center text-pre" style="line-height: 1">{{L('RESTRICTED AREA')}}</h1>
      <p class="text-white text-center mt-6 text-pre">{{L('To access this area you need to log in into BreraPlus')}}</p>
      <v-btn rounded="pill" color="primary" size="x-large" class="font-weight-bold text-body-2 spaced pl-12 pr-12 mt-6" @click="goToLogin()">{{L('LOG IN')}}</v-btn>
    </div>
  </div>
</template>

<script>
import {inject} from "vue";

export default {
  name: "InvalidAccess",
  setup(props, ctx) {
    const localizations = inject('localizations')
    return {
      goToLogin() {
        window.open('https://breraplus.org/sign-in/', '_self')
      },
      L : localizations.L,
    }
  }
}
</script>

<style scoped>

</style>