class GesturePointList {
   _gPoints = [];
    /**
     * The pointer device type: "mouse", "touch", "pen", etc.
     * @type {string}
     */
    type;
    /**
     * Current buttons pressed for the device.
     * Combination of bit flags 0: none, 1: primary (or touch contact), 2: secondary, 4: aux (often middle), 8: X1 (often back), 16: X2 (often forward), 32: pen eraser.
     */
    buttons = 0;
    /**
     * Current number of contact points (touch points, mouse down, etc.) for the device.
     */
    contacts = 0;
    /**
     * Current number of clicks for the device. Used for multiple click gesture tracking.
     */
    clicks = 0;
    /**
     * Current number of captured pointers for the device.
     */
    captureCount = 0;
    constructor( type ) {
        this.type = type;
    }

    getLength() {
        return this._gPoints.length;
    }
    asArray () {
        return this._gPoints;
    }
    add ( gp ) {
        return this._gPoints.push( gp );
    }
    removeById( id ) {
        let i, len = this._gPoints.length;
        for ( i = 0; i < len; i++ ) {
            if ( this._gPoints[ i ].id === id ) {
                this._gPoints.splice( i, 1 );
                break;
            }
        }
        return this._gPoints.length;
    }
    getByIndex ( index ) {
        if ( index < this._gPoints.length) {
            return this._gPoints[ index ];
        }
        return null;
    }
    getById ( id ) {
        let i, len = this._gPoints.length;
        for ( i = 0; i < len; i++ ) {
            if ( this._gPoints[ i ].id === id ) {
                return this._gPoints[ i ];
            }
        }
        return null;
    }
    getPrimary() {
        let i, len = this._gPoints.length;
        for ( i = 0; i < len; i++ ) {
            if ( this._gPoints[ i ].isPrimary ) {
                return this._gPoints[ i ];
            }
        }
        return null;
    }
}

export default GesturePointList;