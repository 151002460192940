<template>
  <div class="full-width fill-height pa-6 overflow-y-auto bg-white">
    <v-row>
      <v-col cols="12">
        <h2>{{name}}</h2>
        <h4 class="accent--text" v-html="displayDating"></h4>
        <p class="text-justify mt-5 pre">{{description}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {computed, inject, onMounted, ref} from "vue";
import config from "@/config";
import axios from "axios";
import router from "@/router";

export default {
  name: "Biography",
  setup(props, ctx) {
    const data = inject('data');

    const getLangVal = (dict, language) => {
      return dict ? dict[language] || dict.en || dict.it : '';
    }
    const auth = inject('auth')
    onMounted(()=>{
      if(auth.invalidAccess.value) {
        router.replace('/invalid-access')
      }
    })

    return {
      artist : data.artist,
      name : computed(()=>{
        const language = (navigator.language || navigator.userLanguage).toLowerCase().slice(0, 2);
        return data.artist.value ? getLangVal(data.artist.value.name, language) : '';
      }),
      description : computed(()=>{
        const language = (navigator.language || navigator.userLanguage).toLowerCase().slice(0, 2);
        return data.artist.value ? getLangVal(data.artist.value.biography, language) : '';
      }),
      displayDating : computed(()=>{
        const language = (navigator.language || navigator.userLanguage).toLowerCase().slice(0, 2);
        return data.artist.value ? `${getLangVal(data.artist.value.born_place, language)}, ${getLangVal(data.artist.value.born_date, language)} - ${getLangVal(data.artist.value.death_place, language)}, ${getLangVal(data.artist.value.death_date, language)}` : '';
      })
    }
  }
}
</script>

<style scoped>

</style>