import {EventDispatcher, Point, Rect} from "../utils";
import utils from "../utils";

function calculateNumLevels(mMaxZoom, width, height)
{
    let r = mMaxZoom;
    let count = 0;
    let currWidth = width;
    let currHeight = height;
    for(let i =0; i < mMaxZoom; i++) {
        count++;
        currWidth = Math.floor(currWidth/2);
        currHeight = Math.floor(currHeight/2);
        if(Math.ceil(r) === 1)
            break;
        r /= 2;
    }
    return count;
}



class TileSource extends EventDispatcher {
    #levelScaleCache = {};
    tileside;
    levels;
    baseUrl;
    width;
    tileWidth;
    height;
    tileHeight;
    /**
     * Ratio of width to height
     * @member {Number} aspectRatio
     */
    aspectRatio;
    tileSize;
    /**
     * The overlap in pixels each tile shares with its adjacent neighbors.
     * @member {Number} tileOverlap
     */
    tileOverlap = 0;
    /**
     * The minimum pyramid level this tile source supports or should attempt to load.
     * @member {Number} minLevel
     */
    minLevel = 0;
    /**
     * The maximum pyramid level this tile source supports or should attempt to load.
     * @member {Number} maxLevel
     */
    maxLevel = 0;
    /**
     * @member {Boolean} ready
     */
    ready = false;
    dimensions;
    enableCORS = false;
    ajaxWithCredentials = false;
    /**
     * @member {Function} success callback
     */
    success;

    constructor( options )  {
        super();
        Object.assign(this, options);
        if (this.success) {
            this.addListener( 'ready', ( event ) => {
                this.success( event );
            });
        }

        if (this.url) {
            this.baseUrl = this.url.replace('?cmd=info','');
            //in case the getImageInfo method is overriden and/or implies an
            //async mechanism set some safe defaults first
            this.aspectRatio = 1;
            this.dimensions  = new Point( 10, 10 );
            this.tileWidth  = 0;
            this.tileHeight = 0;
            this.tileOverlap = 0;
            this.minLevel    = 0;
            this.maxLevel    = 0;
            this.ready       = false;
            //configuration via url implies the extending class
            //implements and 'configure'
            this.getImageInfo( this.url );

        } else {
            if ( !( this.height && this.width  && this.tileside && this.levels) ) {
                throw new Error( 'XLimage required parameters not in image info.' );
            }
            this.tileSize = this.tileside;
            this.maxLevel = calculateNumLevels(this.levels / 2, this.width, this.height);
            //explicit configuration via positional args in constructor
            //or the more idiomatic 'options' object
            this.ready = true;
            this.aspectRatio = (options.width && options.height) ?
                (options.width / options.height) : 1;
            this.dimensions = new Point(options.width, options.height);
            if (this.tileSize) {
                this.tileWidth = this.tileHeight = this.tileSize;
                delete this.tileSize;
            }
        }

        if(!this.maxLevel && this.width && this.height) {
            this.maxLevel = Math.ceil(Math.log( Math.max( options.width, options.height ) ) / Math.log( 2 ));
        }

        let i;
        for( i = 0; i <= this.maxLevel; i++ ){
            this.#levelScaleCache[ i ] = 1 / Math.pow(2, this.maxLevel - i);
        }

        if( this.success){
            this.success( this );
        }
    }

    getTileSize() {
        return this.tileSize;
    }

    /**
     * Return the tileWidth for a given level.
     * Subclasses should override this if tileWidth can be different at different levels
     *   such as in TileSource.  Code should use this function rather than reading
     *   from .tileWidth directly.
     * @function
     */
    getTileWidth() {
        if (!this.tileWidth) {
            return this.getTileSize();
        }
        return this.tileWidth;
    }

    /**
     * Return the tileHeight for a given level.
     * Subclasses should override this if tileHeight can be different at different levels
     *   such as in TileSource.  Code should use this function rather than reading
     *   from .tileHeight directly.
     * @function
     */
    getTileHeight() {
        if (!this.tileHeight) {
            return this.getTileSize();
        }
        return this.tileHeight;
    }

    /**
     * @function
     * @param {Number} level
     */
    getLevelScale( level ) {
        return this.#levelScaleCache[ level ];
    }

    /**
     * @function
     * @param {Number} level
     */
    getNumTiles( level ) {
        let scale = this.getLevelScale( level ),
            x = Math.ceil( scale * this.dimensions.x / this.getTileWidth(level) ),
            y = Math.ceil( scale * this.dimensions.y / this.getTileHeight(level) );

        return new Point( x, y );
    }

    /**
     * @function
     * @param {Number} level
     */
    getPixelRatio( level ) {
        let imageSizeScaled = this.dimensions.times( this.getLevelScale( level ) ),
            rx = 1.0 / imageSizeScaled.x,
            ry = 1.0 / imageSizeScaled.y;

        return new Point(rx, ry);
    }


    /**
     * @function
     * @param {Point|Rect} rect
     */
    getClosestLevel( rect ) {
        let i,
            tilesPerSide,
            tiles;

        for( i = this.minLevel; i < this.maxLevel; i++ ){
            tiles = this.getNumTiles( i );
            tilesPerSide = new Point(
                Math.floor( rect.x / this.getTileWidth(i) ),
                Math.floor( rect.y / this.getTileHeight(i) )
            );

            if( tiles.x + 1 >= tilesPerSide.x && tiles.y + 1 >= tilesPerSide.y ){
                break;
            }
        }
        return Math.max( 0, i - 1 );
    }

    /**
     * @function
     * @param {Number} level
     * @param {Point} point
     */
    getTileAtPoint( level, point ) {
        let pixel = point.times( this.dimensions.x ).times( this.getLevelScale(level) ),
            tx = Math.floor( pixel.x / this.getTileWidth(level) ),
            ty = Math.floor( pixel.y / this.getTileHeight(level) );

        return new Point( tx, ty );
    }

    /**
     * @function
     * @param {Number} level
     * @param {Number} x
     * @param {Number} y
     */
    getTileBounds( level, x, y ) {
        let dimensionsScaled = this.dimensions.times( this.getLevelScale( level ) ),
            tileWidth = this.getTileWidth(level),
            tileHeight = this.getTileHeight(level),
            px = ( x === 0 ) ? 0 : tileWidth * x - this.tileOverlap,
            py = ( y === 0 ) ? 0 : tileHeight * y - this.tileOverlap,
            sx = tileWidth + ( x === 0 ? 1 : 2 ) * this.tileOverlap,
            sy = tileHeight + ( y === 0 ? 1 : 2 ) * this.tileOverlap,
            scale = 1.0 / dimensionsScaled.x;

        sx = Math.min( sx, dimensionsScaled.x - px );
        sy = Math.min( sy, dimensionsScaled.y - py );

        return new Rect( px * scale, py * scale, sx * scale, sy * scale );
    }


    /**
     * Responsible for retrieving, and caching the
     * image metadata pertinent to this TileSources implementation.
     * @function
     * @param {String} url
     * @throws {Error}
     */
    getImageInfo( url ) {
        let readySource,
            options,
            urlParts,
            filename,
            lastDot;


        if (url) {
            urlParts = url.split('/');
            filename = urlParts[urlParts.length - 1];
            lastDot = filename.lastIndexOf('.');
            if (lastDot > -1) {
                urlParts[urlParts.length - 1] = filename.slice(0, lastDot);
            }
        }

        let callback = (success, data) => {

            if (!success) {
                /**
                 * Raised when an error occurs loading a TileSource.
                 *
                 * @event open-failed
                 * @memberof TileSource
                 * @type {object}
                 * @property {TileSource} eventSource - A reference to the TileSource which raised the event.
                 * @property {String} message
                 * @property {String} source
                 * @property {?Object} userData - Arbitrary subscriber-defined object.
                 */
                this.dispatchEvent('openFailed', {message: "Unable to load TileSource", source: url});
                return;
            }
            data = utils.xmlToJSON.parseString(data, {
                mergeCDATA: false,   // extract cdata and merge with text nodes
                grokAttr: true,     // convert truthy attributes to boolean, etc
                grokText: true,     // convert truthy text/attr to boolean, etc
                normalize: true,    // collapse multiple spaces to single space
                xmlns: false,        // include namespaces as attributes in output
                namespaceKey: '_ns',    // tag name for namespace objects
                textKey: '_text',   // tag name for text nodes
                valueKey: '_value',     // tag name for attribute values
                attrKey: '_attr',   // tag for attr groups
                attrsAsObject: true,    // if false, key is used as prefix to name, set prefix to '' to merge children and attrs.
                stripAttrPrefix: true,  // remove namespace prefixes from attributes
                stripElemPrefix: true,  // for elements of same name in diff namespaces, you can enable namespaces and access the nskey property
                childrenAsArray: false   // force children into arrays
            });

            let parseddata = {
                baseUrl: this.baseUrl,
                width: data["package"]["width"]["_text"],
                height: data["package"]["height"]["_text"],
                levels: data["package"]["levels"]["_text"],
                tileside: data["package"]["tileside"]["_text"]
            };

            readySource = new TileSource(parseddata);
            this.ready = true;
            /**
             * Raised when a TileSource is opened and initialized.
             *
             * @event ready
             * @memberof TileSource
             * @type {object}
             * @property {TileSource} eventSource - A reference to the TileSource which raised the event.
             * @property {Object} tileSource
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.dispatchEvent('ready', {tileSource: readySource});
        };

        this.makeAjaxRequest( {
            url: url,
            withCredentials: this.ajaxWithCredentials,
            success: ( xhr ) => {
                let data = this.processResponse( xhr );
                callback( true, data );
            },
            error: function ( xhr, exc ) {
                let msg;
                try {
                    msg = "HTTP " + xhr.status + " attempting to load TileSource";
                } catch ( e ) {
                    let formattedExc;
                    if ( typeof( exc ) == "undefined" || !exc.toString ) {
                        formattedExc = "Unknown error";
                    } else {
                        formattedExc = exc.toString();
                    }
                    msg = formattedExc + " attempting to load TileSource";
                }

                /***
                 * Raised when an error occurs loading a TileSource.
                 *
                 * @event open-failed
                 * @memberof XLviewer.TileSource
                 * @type {object}
                 * @property {XLviewer.TileSource} eventSource - A reference to the TileSource which raised the event.
                 * @property {String} message
                 * @property {String} source
                 * @property {?Object} userData - Arbitrary subscriber-defined object.
                 */
                this.dispatchEvent( 'openFailed', {
                    message: msg,
                    source: url
                });
            }
        });
    }
    /**
     * Decides whether to try to process the response as xml, json, or hand back
     * the text
     * @private
     * @inner
     * @function
     * @param {XMLHttpRequest} xhr - the completed network request
     */
    processResponse( xhr ){
        let status = xhr.status,
            statusText;
        if ( !xhr ) {
            throw new Error( "Security Error" );
        } else if ( xhr.status !== 200 && xhr.status !== 0 ) {
            status     = xhr.status;
            statusText = ( status === 404 ) ?
                "Not Found" :
                xhr.statusText;
            throw new Error( "XHR Error: " + status + " - " + statusText  );
        }
        return xhr.responseText;
    }

    makeAjaxRequest( options ) {
        let onSuccess = options.success;
        let onError = options.error;
        let withCredentials = options.withCredentials;
        let method = options.method || 'GET';
        let body = options.body || null;
        let url = options.url;
        let protocol = this.getUrlProtocol( url );
        let request = new XMLHttpRequest();
        if ( typeof onSuccess !== 'function' ) {
            throw new Error( "makeAjaxRequest requires a success callback" );
        }
        request.onreadystatechange = function() {
            // 4 = DONE (https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest#Properties)
            if ( request.readyState === 4 ) {
                request.onreadystatechange = function(){};

                // With protocols other than http/https, the status is 200
                // on Firefox and 0 on other browsers
                if ( request.status === 200 ||
                    ( request.status === 0 &&
                        protocol !== "http:" &&
                        protocol !== "https:" )) {
                    onSuccess( request );
                } else {
                    //xlv.log.l( "AJAX request returned %d: %s", request.status, url );
                    if ( onError && typeof onError === 'function' ) {
                        onError( request );
                    }
                }
            }
        };

        if (withCredentials) {
            request.withCredentials = true;
        }

        request.open( method , url, true );
        request.send( body );
    }

    getUrlProtocol( url ) {
        let match = url.match(/^([a-z]+:)\/\//i);
        if ( match === null ) {
            // Relative URL, retrive the protocol from window.location
            return window.location.protocol;
        }
        return match[1].toLowerCase();
    }


    /**
     * @function
     * @param {Number} level
     * @param {Number} x
     * @param {Number} y
     */
    tileExists( level, x, y ) {
        let numTiles = this.getNumTiles( level );
        return  level >= this.minLevel &&
            level <= this.maxLevel &&
            x >= 0 &&
            y >= 0 &&
            x < numTiles.x &&
            y < numTiles.y;
    }

    /**
     * Responsible for retriving the url which will return an image for the
     * region specified by the given x, y, and level components.
     * This method is not implemented by this class other than to throw an Error
     * announcing you have to implement it.  Because of the variety of tile
     * server technologies, and various specifications for building image
     * pyramids, this method is here to allow easy integration.
     * @function
     * @param {Number} level
     * @param {Number} x
     * @param {Number} y
     * @param {Number} q
     * @throws {Error}
     */
    getTileUrl( level, x, y, q ) {
        let zoomLev = this.levels;
        let i = level;
        while (i > 0) {
            zoomLev /= 2;
            i--;
        }
        return [this.baseUrl, "?cmd=tile&x=", x, "&y=", y, "&z=", zoomLev, "&q=", q].join("");
    }
}


const buildInfoUrl = (source, image) => {
    if(!source || !image) {
        return null;
    }
    return source + image + "?cmd=info";
};
const loadTileSource = (viewer, url, successCallback, failCallback) => {
    const tileSource = new TileSource({
        url: url,
        enableCORS: viewer.enableCORS,
        ajaxWithCredentials: viewer.ajaxWithCredentials,
        success( event ) {
            successCallback( event.tileSource );
        }
    });
    tileSource.addListener( 'openFailed', function( event ) {
        failCallback( event );
    } );
}

export {
    buildInfoUrl,
    loadTileSource,
    TileSource
}