import defaults from "../defaults";
import ImageRecord from "./ImageRecord";
import TileRecord from "./TileRecord";


class TileCache {
    maxImageCacheCount = defaults.maxImageCacheCount;
    tilesLoaded = [];
    imagesLoaded = [];
    imagesLoadedCount = 0;

    constructor(options) {
        options = options || {};
        if(options.maxImageCacheCount) {
            this.maxImageCacheCount = options.maxImageCacheCount;
        }
    }

    /**
     * @returns {Number} The total number of tiles that have been loaded by
     * this TileCache.
     */
    numTilesLoaded() {
        return this.tilesLoaded.length;
    }

    /**
     * Caches the specified tile, removing an old tile if necessary to stay under the
     * maxImageCacheCount specified on construction. Note that if multiple tiles reference
     * the same image, there may be more tiles than maxImageCacheCount; the goal is to keep
     * the number of images below that number. Note, as well, that even the number of images
     * may temporarily surpass that number, but should eventually come back down to the max specified.
     * @param {Object} options - Tile info.
     * @param {Tile} options.tile - The tile to cache.
     * @param {Image} options.image - The image of the tile to cache.
     * @param {TiledImage} options.tiledImage - The TiledImage that owns that tile.
     * @param {Number} [options.cutoff=0] - If adding this tile goes over the cache max count, this
     * function will release an old tile. The cutoff option specifies a tile level at or below which
     * tiles will not be released.
     */
    cacheTile( options ) {
        /*
        xlv.log.d( options, "[TileCache.cacheTile] options is required" );
        xlv.log.d( options.tile, "[TileCache.cacheTile] options.tile is required" );
        xlv.log.d( options.tile.url, "[TileCache.cacheTile] options.tile.url is required" );
        xlv.log.d( options.tiledImage, "[TileCache.cacheTile] options.tiledImage is required" );
         */
        let cutoff = options.cutoff || 0;
        let insertionIndex = this.tilesLoaded.length;

        let imageRecord = this.imagesLoaded[options.tile.url];
        if (!imageRecord) {
            //xlv.log.d( options.image, "[TileCache.cacheTile] options.image is required to create an ImageRecord" );
            imageRecord = this.imagesLoaded[options.tile.url] = new ImageRecord({
                image: options.image
            });

            this.imagesLoadedCount++;
        }

        imageRecord.addTile(options.tile);
        options.tile.cacheImageRecord = imageRecord;

        // Note that just because we're unloading a tile doesn't necessarily mean
        // we're unloading an image. With repeated calls it should sort itself out, though.
        if ( this.imagesLoadedCount > this.maxImageCacheCount ) {
            let worstTile       = null;
            let worstTileIndex  = -1;
            let worstTileRecord = null;
            let prevTile, worstTime, worstLevel, prevTime, prevLevel, prevTileRecord;

            for ( let i = this.tilesLoaded.length - 1; i >= 0; i-- ) {
                prevTileRecord = this.tilesLoaded[ i ];
                prevTile = prevTileRecord.tile;

                if ( prevTile.level <= cutoff || prevTile.beingDrawn ) {
                    continue;
                } else if ( !worstTile ) {
                    worstTile       = prevTile;
                    worstTileIndex  = i;
                    worstTileRecord = prevTileRecord;
                    continue;
                }

                prevTime    = prevTile.lastTouchTime;
                worstTime   = worstTile.lastTouchTime;
                prevLevel   = prevTile.level;
                worstLevel  = worstTile.level;

                if ( prevTime < worstTime ||
                    ( prevTime === worstTime && prevLevel > worstLevel ) ) {
                    worstTile       = prevTile;
                    worstTileIndex  = i;
                    worstTileRecord = prevTileRecord;
                }
            }

            if ( worstTile && worstTileIndex >= 0 ) {
                this.unloadTile(worstTileRecord);
                insertionIndex = worstTileIndex;
            }
        }

        this.tilesLoaded[ insertionIndex ] = new TileRecord({
            tile: options.tile,
            tiledImage: options.tiledImage
        });
    }

    /**
     * Clears all tiles associated with the specified tiledImage.
     * @param {TiledImage} tiledImage
     */
    clearTilesFor( tiledImage ) {
        let tileRecord;
        for ( let i = 0; i < this.tilesLoaded.length; ++i ) {
            tileRecord = this.tilesLoaded[ i ];
            if ( tileRecord.tiledImage === tiledImage ) {
                this.unloadTile(tileRecord);
                this.tilesLoaded.splice( i, 1 );
                i--;
            }
        }
    }

    // private
    getImageRecord(url) {
        return this.imagesLoaded[url];
    }

    // private
    unloadTile(tileRecord) {
        let tile = tileRecord.tile;
        let tiledImage = tileRecord.tiledImage;

        tile.unload();
        tile.cacheImageRecord = null;

        let imageRecord = this.imagesLoaded[tile.url];
        imageRecord.removeTile(tile);
        if (!imageRecord.getTileCount()) {
            imageRecord.destroy();
            delete this.imagesLoaded[tile.url];
            this.imagesLoadedCount--;
        }

        /**
         * Triggered when a tile has just been unloaded from memory.
         */
        tiledImage.viewer.dispatchEvent("tileUnloaded", {
            tile: tile,
            tiledImage: tiledImage
        });
    }
}

export default TileCache;