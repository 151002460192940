import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'
import Artwork from '../pages/Artwork.vue'
import Biography from '../pages/Biography.vue'
import InvalidAccess from '../pages/InvalidAccess.vue'
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/artwork',
        name: 'artwork',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Artwork
    },
    {
        path: '/biography',
        name: 'biography',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Biography
    },
    {
        path: '/invalid-access',
        name: 'invalid-access',
        component: InvalidAccess
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router