import Point from './Point'
class Rect {
    /**
     * The vector component 'x'.
     * @member {Number} x
     */
    x = 0;
    /**
     * The vector component 'y'.
     * @member {Number} y
     */
    y = 0;
    /**
     * The vector component 'width'.
     * @member {Number} width
     */
    width = 0;
    /**
     * The vector component 'height'.
     * @member {Number} height
     */
    height = 0;
    /**
     * The vector component 'rotation'.
     * @member {Number} rotation
     */
    rotation = 0;

    /**
     * @class Rect
     * @classdesc A Rectangle is described by it top left coordinates (x, y), width,
     * height and rotation of rotation around (x, y).
     * Note that the coordinate system used is the one commonly used with images:
     * x increases when going to the right
     * y increases when going to the bottom
     * rotation increases clockwise with 0 being the horizontal
     *
     * The constructor normalizes the rectangle to always have 0 <= rotation < 90
     *
     * @param {Number} [x=0] The vector component 'x'.
     * @param {Number} [y=0] The vector component 'y'.
     * @param {Number} [width=0] The vector component 'width'.
     * @param {Number} [height=0] The vector component 'height'.
     * @param {Number} [rotation=0] Rotation of the rectangle around (x,y) in rotation.
     **/
    constructor(x, y, width, height, rotation) {
        this.x = typeof(x) === "number" ? x : 0;
        this.y = typeof(y) === "number" ? y : 0;
        this.width  = typeof(width) === "number" ? width : 0;
        this.height = typeof(height) === "number" ? height : 0;
        this.rotation = typeof(rotation) === "number" ? rotation : 0;
        this.rotation = this.rotation % 360;
        if (this.rotation < 0) {
            this.rotation += 360;
        }

        let newTopLeft, newWidth;
        if (this.rotation >= 270) {
            newTopLeft = this.getTopRight();
            this.x = newTopLeft.x;
            this.y = newTopLeft.y;
            newWidth = this.height;
            this.height = this.width;
            this.width = newWidth;
            this.rotation -= 270;
        } else if (this.rotation >= 180) {
            newTopLeft = this.getBottomRight();
            this.x = newTopLeft.x;
            this.y = newTopLeft.y;
            this.rotation -= 180;
        } else if (this.rotation >= 90) {
            newTopLeft = this.getBottomLeft();
            this.x = newTopLeft.x;
            this.y = newTopLeft.y;
            newWidth = this.height;
            this.height = this.width;
            this.width = newWidth;
            this.rotation -= 90;
        }
    }

    /**
     * Builds a rectangle having the 3 specified points as summits.
     * @static
     * @memberof Rect
     * @param {Point} topLeft
     * @param {Point} topRight
     * @param {Point} bottomLeft
     * @returns {Rect}
     */
    static fromSummits(topLeft, topRight, bottomLeft) {
        const width = topLeft.distanceTo(topRight);
        const height = topLeft.distanceTo(bottomLeft);
        const diff = topRight.minus(topLeft);
        let radians = Math.atan(diff.y / diff.x);
        if (diff.x < 0) {
            radians += Math.PI;
        } else if (diff.y < 0) {
            radians += 2 * Math.PI;
        }
        return new Rect(
            topLeft.x,
            topLeft.y,
            width,
            height,
            radians / Math.PI * 180);
    }


    /**
     * @function
     * @returns {Rect} a duplicate of this Rect
     */
    clone() {
        return new Rect(
            this.x,
            this.y,
            this.width,
            this.height,
            this.rotation);
    }

    /**
     * The aspect ratio is simply the ratio of width to height.
     * @function
     * @returns {Number} The ratio of width to height.
     */
    getAspectRatio() {
        return this.width / this.height;
    }

    /**
     * Provides the coordinates of the upper-left corner of the rectangle as a
     * point.
     * @function
     * @returns {Point} The coordinate of the upper-left corner of
     *  the rectangle.
     */
    getTopLeft() {
        return new Point(
            this.x,
            this.y
        );
    }

    /**
     * Provides the coordinates of the bottom-right corner of the rectangle as a
     * point.
     * @function
     * @returns {Point} The coordinate of the bottom-right corner of
     *  the rectangle.
     */
    getBottomRight() {
        return new Point(this.x + this.width, this.y + this.height)
            .rotate(this.rotation, this.getTopLeft());
    }

    /**
     * Provides the coordinates of the top-right corner of the rectangle as a
     * point.
     * @function
     * @returns {Point} The coordinate of the top-right corner of
     *  the rectangle.
     */
    getTopRight() {
        return new Point(this.x + this.width, this.y)
            .rotate(this.rotation, this.getTopLeft());
    }

    /**
     * Provides the coordinates of the bottom-left corner of the rectangle as a
     * point.
     * @function
     * @returns {Point} The coordinate of the bottom-left corner of
     *  the rectangle.
     */
    getBottomLeft() {
        return new Point(this.x, this.y + this.height)
            .rotate(this.rotation, this.getTopLeft());
    }

    /**
     * Computes the center of the rectangle.
     * @function
     * @returns {Point} The center of the rectangle as represented
     *  as represented by a 2-dimensional vector (x,y)
     */
    getCenter() {
        return new Point(
            this.x + this.width / 2.0,
            this.y + this.height / 2.0
        ).rotate(this.rotation, this.getTopLeft());
    }

    /**
     * Returns the width and height component as a vector Point
     * @function
     * @returns {Point} The 2 dimensional vector representing the
     *  the width and height of the rectangle.
     */
    getSize() {
        return new Point(this.width, this.height);
    }

    /**
     * Determines if two Rectangles have equivalent components.
     * @function
     * @param {Rect} other The Rectangle to compare to.
     * @return {Boolean} 'true' if all components are equal, otherwise 'false'.
     */
    equals(other) {
        return (other instanceof Rect) &&
            this.x === other.x &&
            this.y === other.y &&
            this.width === other.width &&
            this.height === other.height &&
            this.rotation === other.rotation;
    }

    /**
     * Multiply all dimensions (except rotation) in this Rect by a factor and
     * return a new Rect.
     * @function
     * @param {Number} factor The factor to multiply vector components.
     * @returns {Rect} A new rect representing the multiplication
     *  of the vector components by the factor
     */
    times(factor) {
        return new Rect(
            this.x * factor,
            this.y * factor,
            this.width * factor,
            this.height * factor,
            this.rotation);
    }

    /**
     * Translate/move this Rect by a vector and return new Rect.
     * @function
     * @param {Point} delta The translation vector.
     * @returns {Rect} A new rect with altered position
     */
    translate(delta) {
        return new Rect(
            this.x + delta.x,
            this.y + delta.y,
            this.width,
            this.height,
            this.rotation);
    }

    /**
     * Returns the smallest rectangle that will contain this and the given
     * rectangle bounding boxes.
     * @param {Rect} rect
     * @return {Rect} The new rectangle.
     */
    union(rect) {
        let thisBoundingBox = this.getBoundingBox();
        let otherBoundingBox = rect.getBoundingBox();

        let left = Math.min(thisBoundingBox.x, otherBoundingBox.x);
        let top = Math.min(thisBoundingBox.y, otherBoundingBox.y);
        let right = Math.max(
            thisBoundingBox.x + thisBoundingBox.width,
            otherBoundingBox.x + otherBoundingBox.width);
        let bottom = Math.max(
            thisBoundingBox.y + thisBoundingBox.height,
            otherBoundingBox.y + otherBoundingBox.height);

        return new Rect(
            left,
            top,
            right - left,
            bottom - top);
    }

    /**
     * Rotates a rectangle around a point.
     * @function
     * @param {Number} rotation The angle in rotation to rotate.
     * @param {Point} [pivot] The point about which to rotate.
     * Defaults to the center of the rectangle.
     * @return {Rect}
     */
    rotate(rotation, pivot) {
        rotation = rotation % 360;
        if (rotation === 0) {
            return this.clone();
        }
        if (rotation < 0) {
            rotation += 360;
        }

        pivot = pivot || this.getCenter();
        let newTopLeft = this.getTopLeft().rotate(rotation, pivot);
        let newTopRight = this.getTopRight().rotate(rotation, pivot);

        let diff = newTopRight.minus(newTopLeft);
        let radians = Math.atan(diff.y / diff.x);
        if (diff.x < 0) {
            radians += Math.PI;
        } else if (diff.y < 0) {
            radians += 2 * Math.PI;
        }
        return new Rect(
            newTopLeft.x,
            newTopLeft.y,
            this.width,
            this.height,
            radians / Math.PI * 180);
    }

    /**
     * Retrieves the smallest horizontal (rotation=0) rectangle which contains
     * this rectangle.
     * @returns {Rect}
     */
    getBoundingBox() {
        if (this.rotation === 0) {
            return this.clone();
        }
        let topLeft = this.getTopLeft();
        let topRight = this.getTopRight();
        let bottomLeft = this.getBottomLeft();
        let bottomRight = this.getBottomRight();
        let minX = Math.min(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x);
        let maxX = Math.max(topLeft.x, topRight.x, bottomLeft.x, bottomRight.x);
        let minY = Math.min(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y);
        let maxY = Math.max(topLeft.y, topRight.y, bottomLeft.y, bottomRight.y);
        return new Rect(
            minX,
            minY,
            maxX - minX,
            maxY - minY);
    }

    /**
     *
     * @param other {Rect}
     * @returns {boolean}
     */
    overlap  ( other ) {
        let l1 = this.getTopLeft();
        let r1 = this.getBottomRight();

        let l2 = other.getTopLeft();
        let r2 = other.getBottomRight();

        // If one rectangle is on left side of other && If one rectangle is above other
        return !(l1.x > r2.x || l2.x > r1.x) && !(l1.y < r2.y || l2.y < r1.y)
    }

    /**
     *
     * @param point {Point}
     * @returns {boolean}
     */
    overlapPoint  ( point ) {
        let topLeft = this.getTopLeft();
        let bottomRight = this.getBottomRight();

        return (point.x >= topLeft.x && point.x <= bottomRight.x && point.y >= topLeft.y && point.y <= bottomRight.y);
    }

    /**
     * Provides a string representation of the rectangle which is useful for
     * debugging.
     * @function
     * @returns {String} A string representation of the rectangle.
     */
    toString() {
        return "[" +
            (Math.round(this.x * 100) / 100) + "," +
            (Math.round(this.y * 100) / 100) + "," +
            (Math.round(this.width * 100) / 100) + "x" +
            (Math.round(this.height * 100) / 100) + "," +
            (Math.round(this.rotation * 100) / 100) + "deg" +
            "]";
    }
}

export default Rect;