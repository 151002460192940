<template>
<div class="full-width fill-height">
  <xl-viewer ref="viewer" server="https://art.centrica.it/7711/closer/5c0e7e4e9263464388dd9eb8/" v-model="image" @viewportChange="onViewportChange"
             force-https class="full-width fill-height" :style="{ position: 'absolute', top: 0, left: 0, opacity: showTokenDialog ? 0 : 1}"
             @resize="onViewerResize" @panEnd="onPanEnd" @zoomClick="onZoomClick"/>

  <div ref="paperDiv" :style="{ position: 'absolute', top: 0, left: 0}" :class="heatmapMode ? 'full-width fill-height no-events' : 'd-none'" ></div>

  <div class="annotations full-width fill-height no-events" :key="annSeed" v-if="annotationsEnabled" >
    <v-tooltip :location="getAnchor(a)" v-for="a in annotations" :key="a._id" >
      <template v-slot:activator="{ props }">
        <div class="annotation" :style="annotationStyle(a)" v-bind="props"></div>
      </template>
      <div class="annotation-tooltip d-flex">
        <v-img :src="a.image" v-if="a.image" :title="a.title[language] || a.title.en || a.title.it" :aspect-ratio="3/4" width="140" class="ml-3" />
        <div style="max-width: 260px" class="pa-3">
          <h4>{{a.title[language] || a.title.en || a.title.it }}</h4>
          <p v-if="a.content">{{a.content[language] || a.content.en || a.content.it }}</p>
        </div>

      </div>
    </v-tooltip>

  </div>

  <beautiful-chat
      :participants="participants"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="true"
      :showEmojiInText="true"
      :showFile="false"
      :showEdition="false"
      :showDeletion="false"
      :deletionConfirmation="true"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="true"
      :showCloseButton="true"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :disableUserListToggle="true"
      :showHeader="false"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @edit="editMessage" >
    <template v-slot:text-message-body="{ message }">
      <small class="d-block font-weight-bold user">{{message.author}}</small>
      {{message.data.text}}
    </template>
  </beautiful-chat>



  <div class="full-width d-flex justify-center align-center position-absolute no-events" style="bottom: 0; z-index: 0">
    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <v-btn @click="viewer.zoomIn()" icon="mdi-magnify-plus-outline" color="black" small class="ma-2 all-events" v-bind="props"></v-btn>
      </template>
      <p class="bg-black pa-2">{{L('zoom in')}}</p>
    </v-tooltip>

    <span class="text-white">{{zoom}}%</span>

    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <v-btn @click="viewer.zoomOut()" icon="mdi-magnify-minus-outline" color="black" small class="ma-2 all-events" v-bind="props"></v-btn>
      </template>
      <p class="bg-black pa-2">{{L('zoom out')}}</p>
    </v-tooltip>

    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <v-btn @click="viewer.reset()" icon="mdi-autorenew" color="black" small class="ma-2 all-events" v-bind="props"></v-btn>
      </template>
      <p class="bg-black pa-2">{{L('reset view')}}</p>
    </v-tooltip>

    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <v-btn @click="heatmapMode = !heatmapMode" icon="mdi-blur" :color="heatmapMode ? 'primary':'black'" small class="ma-2 all-events" v-bind="props"></v-btn>
      </template>
      <p class="bg-black pa-2">{{L('toggle heatmap')}}</p>
    </v-tooltip>
    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <v-btn @click="annotationsEnabled = !annotationsEnabled" icon="mdi-comment-eye-outline" :color="annotationsEnabled ? 'primary':'black'" small class="ma-2 all-events" v-bind="props"></v-btn>
      </template>
      <p class="bg-black pa-2">{{L('toggle annotations')}}</p>
    </v-tooltip>
    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <v-btn @click="infoDialog = true" icon="mdi-information-outline" color="black" small class="ma-2 all-events" v-bind="props"></v-btn>
      </template>
      <p class="bg-black pa-2">{{L('show info')}}</p>
    </v-tooltip>



    <v-tooltip location="top">
      <template v-slot:activator="{ props }">
        <v-badge color="primary" :content="ratingAverage !== null ? ratingAverage.toFixed(2) : ''" text-color="black" rounded :offset-x="12" :offset-y="12">
          <v-btn @click="rateDialog = true" icon="mdi-star" color="black" small class="ma-2 all-events" v-bind="props"></v-btn>
        </v-badge>

      </template>
      <p class="bg-black pa-2">{{L('rate this experience')}}</p>
    </v-tooltip>





  </div>


  <v-dialog v-model="helpDialog" persistent max-width="600">
    <v-card>
      <v-card-text>
        <v-window v-model="helpIndex" show-arrows>
          <v-window-item v-for="slide in helpSlides" :key="slide._id">
            <h4>{{slide.title[language] || slide.title.en || slide.title.it }}</h4>
            <v-img v-if="slide.image" class="full-width" :aspect-ratio="16/9" :src="slide.image" ></v-img>
            <p>{{slide.content[language] || slide.content.en || slide.content.it }}</p>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeHelp()" >{{L('Close')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-dialog v-model="infoDialog" max-width="900">
    <v-card class="black">
      <v-card-text v-if="artwork">
        <p class="text-h3 mb-0 font-weight-regular">{{getLang('title')}}</p>
        <p class="text-h5 mb-0 font-weight-regular text-primary">{{getLang('artist_name')}}</p>
        <p class="text-h6 mb-0 font-weight-medium" v-if="getLang('date_display')">{{getLang('date_display')}}</p>
        <p class="text-h6 mb-0 font-weight-medium font-italic" v-if="getLang('technique')">{{getLang('technique')}}</p>
        <p class="text-h6 mb-0 font-weight-medium" v-if="getLang('room_name')">{{getLang('room_name')}}</p>
        <p class="text-h6 mb-0 font-weight-medium" v-if="getLang('size_diplay')">{{getLang('size_display')}}</p>
        <p class="text-body-1 mt-5 mb-5 font-weight-regular pre">{{getLang('desc')}}</p>
        <p class="text-body-1 mb-0 font-weight-regular" v-if="getLang('credits')">{{getLang('credits')}}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="infoDialog = false">{{L('Cancel')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="rateDialog" max-width="500">
    <v-card class="black">
      <v-card-text class="d-flex flex-column align-center justify-center">
        <p class="text-body-1 mb-2 font-weight-regular">{{L('How much do you like this experience?')}}</p>
        <v-rating
            v-model="rating"
            color="primary"
        ></v-rating>
        <p v-if="ratingAverage !== null && ratingNum !== null" class="text-body-1 mb-2 font-weight-regular">{{ratingNum + ' ' + L('users had already voted with an average of') + ' ' + ratingAverage.toFixed(2)}}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelVote()">{{L('Cancel')}}</v-btn>
        <v-btn color="primary" :disabled="rating === null" text @click="sendVote()">{{L('Send vote')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import {computed, inject, onMounted, ref, watch} from "vue";
import axios from "axios";
import config from "@/config";
import XlViewer from "@/components/XLviewer";
import Raphael from "raphael";
import router from "@/router";
export default {
  components : {
    'xl-viewer' : XlViewer
  },
  name: "Artwork",
  setup(props) {
    const auth = inject('auth')
    const ws = inject('ws')
    const heatmap = inject('heatmap')
    const annotations = inject('annotations')
    let paper;
    const paperDiv = ref(null)
    const heatmapMode = ref(false)
    const annotationsEnabled = ref(false);

    const viewer = ref(null);
    const image = ref('626ba753b6f58eaef5c55fe0.imgf');
    const participants = ref([]);

    const isChatOpen = ref(false);
    const helpIndex = ref(0);
    const newMessagesCount = ref(0);
    const showTypingIndicator = ref('');
    const infoDialog = ref(false);
    const rateDialog = ref(false);
    const helps = inject('helps');
    const data = inject('data');
    const vote = inject('vote');
    const localizations = inject('localizations');

    let tm;
    const annSeed = ref(0);
    const zoom = ref(0);
    const language = (navigator.language || navigator.userLanguage).toLowerCase().slice(0, 2);
    ws.waitForConnection.then(()=>{
      ws.getChats();
    });
    const clearHeatmap = () => {
      if(paper) paper.clear();
    };

    const nickname = computed({
      get: () => ws.nickname,
      set: (val) => {
        localStorage.nickname = val;
        ws.nickname = val
      }
    })
    const drawHeatmap = () => {
      clearHeatmap();
      if(!heatmapMode.value) return;
      //let bounds = viewer.value.getBounds(true);

      for(let i = 0; i < heatmap.heatmap.value.length; i++) {
        let p = heatmap.heatmap.value[i];

        let center = viewer.value.getLocalPoint(p.x, p.y);
        let top = viewer.value.getLocalPoint(p.x, p.y-p.r);
        let left =  viewer.value.getLocalPoint(p.x-p.r, p.y);

        let xDiff = center.x - left.x;
        let yDiff = center.y - top.y;

        let r = Math.min(Math.round(xDiff), Math.round(yDiff))


        let coeff  = p.r - 0.04;
        let green = coeff * 255 / 0.16;
        let alpha = Math.min(0.05 * p.c, 1);
        //let fill = `radial-gradient(rgba(255,${Math.round(green)},0,${alpha.toFixed(2)}), transparent)`
        let fill = `rrgba(255,${Math.round(green)},0,${alpha.toFixed(2)})-transparent`
        //console.log(fill);
        paper.circle(center.x, center.y, r).attr('fill', fill).attr('stroke', 'transparent');
      }

    };

    watch(heatmapMode,()=>{
      drawHeatmap();
    })

    const messageList = computed(() => {
      console.log("messageList", ws.chats.length)
      return ws.chats.map(item => {
        const author = item.nickname === ws.nickname ? 'me' : item.nickname;
        return { type: 'text', author, data: { text: item.message } };
      });
    });


    const onMessageWasSent = (message) => {
      console.log("onMessageWasSent!!!!", message)
      // called when the user sends a message
      //messageList.value = [ ...messageList.value, message ]
      if (message.data.text.length > 0) {
        newMessagesCount.value = isChatOpen.value ? newMessagesCount.value : newMessagesCount.value + 1
        ws.sendChat(message.data.text)
        //onMessageWasSent({ author: 'support', type: 'text', data: { text } })
      }
    }

    const sendEvent = (coords) => {
      clearTimeout(tm);
      tm = setTimeout(()=>{
        const bounds = viewer.value.getBounds(true);
        let side = Math.min(bounds.width, bounds.height);
        let r = parseFloat((side / 4).toFixed(4));
        let x = parseFloat((coords.x).toFixed(4));
        let y = parseFloat((coords.y).toFixed(4));
        console.log('sendEvent', x,y,r)
        heatmap.sendEvent(x,y,r).then(()=>{
          //TODO
        })



      }, 500)
    };

    onMounted(()=>{
      auth.waitForLogin.then(()=>{
        if(!ws.nickname) {
          ws.nicknameDialog = true;
        } else {
          ws.connect();
        }
        heatmap.load().then(()=>{
          //console.log('viewer dim', viewer.value.width, viewer.value.height)
          paper = Raphael(paperDiv.value, viewer.value.width, viewer.value.height);
          drawHeatmap();
        })

        helps.load().then(()=>{
          //console.log("help loaded", helps.slides.value.length, localStorage.helpRead)
          if(helps.slides.value.length > 0 && !localStorage.helpRead) {
            helps.show.value = true;
          }
        })

        annotations.load().then(()=>{});
        vote.load().then(()=>{});

      });
      if(auth.invalidAccess.value) {
        router.replace('/invalid-access')
      }
    })

    return {
      language,
      L : localizations.L,
      artwork:data.artwork,
      infoDialog,
      rateDialog,
      zoom,
      annSeed,
      annotationsEnabled,
      annotations : annotations.items,
      getAnchor( ann ) {
        const pos = viewer.value.getLocalPoint(ann.x, ann.y);
        const w = viewer.value.width;
        const h = viewer.value.height;
        let toolW = 260 + (ann.image ? 140 : 0)


        console.log(pos, w, h, toolW)

        if(w - pos.x < toolW/2) {
          return 'start'
        }
        if(pos.x < toolW/2) {
          return 'end'
        }
        if(pos.y < h/2) return 'bottom'
        return 'top'
      },
      annotationStyle(ann) {
        if(!viewer.value) return 'display: none';
        const zoom = viewer.value.getZoomPercent();
        if(ann.min_zoom <= zoom && ann.max_zoom >= zoom) {
          const pos = viewer.value.getLocalPoint(ann.x, ann.y);
          return `left: ${pos.x-10}px; top: ${pos.y-10}px`;
        }
        return 'display: none';
      },
      helpDialog : helps.show,
      closeHelp() {
        helps.show.value = false;
        localStorage.helpRead = true;
      },
      rating : vote.value,
      ratingAverage : vote.average,
      ratingNum : vote.num,
      nickname,
      nicknameDialog : ws.nicknameDialog,
      helpIndex,
      helpSlides : helps.slides,
      heatmapMode,
      paperDiv,
      viewer,
      image,
      participants, // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      //titleImageUrl:'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      messageList, // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount,
      isChatOpen, // to determine whether the ws window should be open or closed
      showTypingIndicator, // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#000000',
          text: '#ffffff'
        },
        launcher: {
          bg: '#000000'
        },
        messageList: {
          bg: '#000000'
        },
        sentMessage: {
          bg: '#a4947d',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#1c1f22',
          text: '#ffffff'
        },
        userInput: {
          bg: '#1c1f22',
          text: '#ffffff'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the ws to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown),
      onMessageWasSent,
      openChat () {
        // called when the user clicks on the fab button to open the ws
        isChatOpen.value = true
        newMessagesCount.value = 0
      },
      closeChat () {
        // called when the user clicks on the botton to close the ws
        isChatOpen.value = false
      },
      handleScrollToTop () {
        // called when the user scrolls message list to top
        // leverage pagination for loading another page of messages
      },
      handleOnType () {
        //console.log('Emit typing event')
      },
      editMessage(message){

      },
      onZoomClick(e) {
        console.log('onZoomClick', e)
        let center = viewer.value.getPoint(e.position.x, e.position.y);
        sendEvent(center);
      },
      onPanEnd(e) {
        console.log('onPanEnd', e)
        const bounds = viewer.value.getBounds(true);
        sendEvent({x:bounds.x+bounds.width/2, y:bounds.y+bounds.height/2});
      },
      onViewportChange( bounds ) {
        drawHeatmap();
        annSeed.value++;

        zoom.value = viewer.value.getZoomPercent();
        console.log(zoom.value)
      },
      onViewerResize({ width, height}) {
        if(paper) paper.setSize(width, height);
      },
      getLang( lbl ) {
        if(!data.artwork.value) return '';
        let dict = data.artwork.value[lbl];
        return dict ? dict[language] || dict.en || dict.it : '';
      },
      showTokenDialog: auth.showTokenDialog,
      cancelVote() {
        vote.reset();
        rateDialog.value = false;
      },
      async sendVote() {
        await vote.save();
        rateDialog.value = false;
      }
    }
  }
}
</script>

<style scoped>

</style>