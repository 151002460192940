import { EventDispatcher, Point, Rect } from '../utils'
import TiledImage from './TiledImage'
class Manager extends EventDispatcher{
    core;
    #items = [];
    #needsDraw = false;
    #autoRefigureSizes = true;
    #needsSizesFigured = false;
    #homeBounds;
    #contentFactor;
    #contentSize = null;

    constructor({ core }) {
        super();
        if(!core) {
            core.d( "[Manager] options.core is required" );
        }

        this.core = core;
        this.#figureSizes();
    }

    #delegatedFigureSizes() {
        if (this.#autoRefigureSizes) {
            this.#figureSizes();
        } else {
            this.#needsSizesFigured = true;
        }
    }

    /**
     * Add the specified item.
     * @param {TiledImage} item - The item to add.
     * @param {Object} options - options.
     * @param {Number} [options.index] - Index for the item. If not specified, goes at the top.
     * @fires add-item
     * @fires metrics-change
     */
    addItem( item, options ) {
        if(!item) {
            this.core.w("[Manager.addItem] item is required");
            return
        }
        if(!(item instanceof TiledImage)){
            this.core.w("[Manager.addItem] item must be an instance of TiledImages");
            return;
        }
        options = options || {};
        if (options.index !== undefined) {
            const index = Math.max(0, Math.min(this.#items.length, options.index));
            this.#items.splice(index, 0, item);
        } else {
            this.#items.push( item );
        }

        if (this.#autoRefigureSizes) {
            this.#figureSizes();
        } else {
            this.#needsSizesFigured = true;
        }

        this.#needsDraw = true;

        item.addListener('boundsChange', this.#delegatedFigureSizes);

        /**
         * Dispatched when an item is added to the Manager.
         * @event add-item
         * @memberOf Manager
         * @type {Object}
         * @property {Viewer} eventSource - A reference to the Manager which raised the event.
         * @property {TiledImage} item - The item that has been added.
         * @property {?Object} userData - Arbitrary subscriber-defined object.
         */
        this.dispatchEvent( 'addItem', { item: item });
    }

    /**
     * Get the item at the specified index.
     * @param {Number} index - The item's index.
     * @returns {TiledImage} The item at the specified index.
     */
    getItemAt( index ) {
        if(index === undefined) {
            this.core.w("[Manager.getItemAt] index is required");
            return null;
        }
        return this.#items[ index ];
    }


    /**
     * Get the index of the given item or -1 if not present.
     * @param {TiledImage} item - The item.
     * @returns {Number} The index of the item or -1 if not present.
     */
    getIndexOfItem( item ) {
        if(!item) {
            this.core.w("[Manager.getIndexOfItem] item is required");
            return -1;
        }
        return this.#items.indexOf(item);
    }

    /**
     * @returns {Number} The number of items used.
     */
    getItemCount() {
        return this.#items.length;
    }

    /**
     * Change the index of a item so that it appears over or under others.
     * @param {TiledImage} item - The item to move.
     * @param {Number} index - The new index.
     * @fires Manager.event:item-index-change
     */
    setItemIndex( item, index ) {
        if(!item) {
            this.core.d("[Manager.setItemIndex] item is required");
            return;
        }
        if(!index) {
            this.core.d("[Manager.setItemIndex] index is required");
            return;
        }

        let oldIndex = this.getIndexOfItem( item );

        if ( index >= this.#items.length ) {
            throw new Error( "Index bigger than number of layers." );
        }

        if ( index === oldIndex || oldIndex === -1 ) {
            return;
        }

        this.#items.splice( oldIndex, 1 );
        this.#items.splice( index, 0, item );
        this.#needsDraw = true;

        /**
         * Dispatched when the order of the indexes has been changed.
         * @event item-index-change
         * @memberOf Manager
         * @type {object}
         * @property {Manager} eventSource - A reference to the Manager which raised the event.
         * @property {TiledImage} item - The item whose index has
         * been changed
         * @property {Number} previousIndex - The previous index of the item
         * @property {Number} newIndex - The new index of the item
         * @property {?Object} userData - Arbitrary subscriber-defined object.
         */
        this.dispatchEvent( 'itemIndexChange', {
            item: item,
            previousIndex: oldIndex,
            newIndex: index
        } );
    }

    /**
     * Remove an item.
     * @param {TiledImage} item - The item to remove.
     * @fires Manager.event:remove-item
     * @fires Manager.event:metrics-change
     */
    removeItem( item ) {
        if(!item) {
            this.core.w(item, "[Manager.removeItem] item is required");
            return;
        }
        let index = this.#items.indexOf( item );
        if ( index === -1 ) {
            return;
        }
        item.removeListener('boundsChange', this.#delegatedFigureSizes);
        item.destroy();
        this.#items.splice( index, 1 );
        this.#figureSizes();
        this.#needsDraw = true;
        this.#raiseRemoveItem(item);
    }

    /**
     * Remove all items.
     * @fires Manager.event:remove-item
     * @fires Manager.event:metrics-change
     */
    removeAll() {
        // We need to make sure any pending images are canceled so the manager items don't get messed up
        this.core.cancelPendingImages();
        let item;
        for (let i = 0; i < this.#items.length; i++) {
            item = this.#items[i];
            item.removeListener('boundsChange', this.#delegatedFigureSizes);
            item.destroy();
        }

        let removedItems = this.#items;
        this.#items = [];
        this.#figureSizes();
        this.#needsDraw = true;

        for (let i = 0; i < removedItems.length; i++) {
            item = removedItems[i];
            this.#raiseRemoveItem(item);
        }
    }

    /**
     * Clears all tiles and triggers updates for all items.
     */
    resetItems() {
        for ( let i = 0; i < this.#items.length; i++ ) {
            this.#items[i].reset();
        }
    }

    /**
     * Updates (i.e. animates bounds of) all items.
     */
    update() {
        let animated = false;
        for ( let i = 0; i < this.#items.length; i++ ) {
            animated = this.#items[i].update() || animated;
        }
        return animated;
    }

    /**
     * Draws all items.
     */
    draw() {
        for ( let i = 0; i < this.#items.length; i++ ) {
            this.#items[i].draw();
        }
        this.#needsDraw = false;
    }

    /**
     * @returns {Boolean} true if any items need updating.
     */
    needsDraw() {
        for ( let i = 0; i < this.#items.length; i++ ) {
            if ( this.#items[i].needsDraw() ) {
                return true;
            }
        }
        return this.#needsDraw;
    }

    /**
     * @returns {Rect} The smallest rectangle that encloses all items, in viewport coordinates.
     */
    getHomeBounds() {
        return this.#homeBounds.clone();
    }

    /**
     * To facilitate zoom constraints, we keep track of the pixel density of the
     * densest item in the Manager (i.e. the item whose content size to viewport size
     * ratio is the highest) and save it as this "content factor".
     * @returns {Number} the number of content units per viewport unit.
     */
    getContentFactor() {
        return this.#contentFactor;
    }

    /**
     * As a performance optimization, setting this flag to false allows the bounds-change event handler
     * on tiledImages to skip calculations on the manager bounds. If a lot of images are going to be positioned in
     * rapid succession, this is a good idea. When finished, setAutoRefigureSizes should be called with true
     * or the system may behave oddly.
     * @param {Boolean} [value] The value to which to set the flag.
     */
    setAutoRefigureSizes(value) {
        this.#autoRefigureSizes = value;
        if (value && this.#needsSizesFigured) {
            this.#figureSizes();
            this.#needsSizesFigured = false;
        }
    }

    /**
     * Arranges all of the TiledImages with the specified settings.
     * @param {Object} options - Specifies how to arrange.
     * @param {Boolean} [options.immediately=false] - Whether to animate to the new arrangement.
     * @param {String} [options.layout] - See collectionLayout in {@link Options}.
     * @param {Number} [options.rows] - See collectionRows in {@link Options}.
     * @param {Number} [options.columns] - See collectionColumns in {@link Options}.
     * @param {Number} [options.tileSize] - See collectionTileSize in {@link Options}.
     * @param {Number} [options.tileMargin] - See collectionTileMargin in {@link Options}.
     * @fires Manager.event:metrics-change
     */
    arrange(options) {
        options = options || {};
        let immediately = options.immediately || false;
        let layout = options.layout || this.core.collectionLayout;
        let rows = options.rows || this.core.collectionRows;
        let columns = options.columns || this.core.collectionColumns;
        let tileSize = options.tileSize || this.core.collectionTileSize;
        let tileMargin = options.tileMargin || this.core.collectionTileMargin;
        let increment = tileSize + tileMargin;
        let wrap;
        if (!options.rows && columns) {
            wrap = columns;
        } else {
            wrap = Math.ceil(this.#items.length / rows);
        }
        let x = 0;
        let y = 0;
        let item, box, width, height, position;

        this.setAutoRefigureSizes(false);
        for (let i = 0; i < this.#items.length; i++) {
            if (i && (i % wrap) === 0) {
                if (layout === 'horizontal') {
                    y += increment;
                    x = 0;
                } else {
                    x += increment;
                    y = 0;
                }
            }

            item = this.#items[i];
            box = item.getBounds();
            if (box.width > box.height) {
                width = tileSize;
            } else {
                width = tileSize * (box.width / box.height);
            }

            height = width * (box.height / box.width);
            position = new Point(x + ((tileSize - width) / 2),
                y + ((tileSize - height) / 2));

            item.setPosition(position, immediately);
            item.setWidth(width, immediately);

            if (layout === 'horizontal') {
                x += increment;
            } else {
                y += increment;
            }
        }
        this.setAutoRefigureSizes(true);
    }

    // private
    #figureSizes() {
        let oldHomeBounds = this.#homeBounds ? this.#homeBounds.clone() : null;
        let oldContentSize = this.#contentSize ? this.#contentSize.clone() : null;
        let oldContentFactor = this.#contentFactor || 0;

        if ( !this.#items.length ) {
            this.#homeBounds = new Rect(0, 0, 1, 1);
            this.#contentSize = new Point(1, 1);
            this.#contentFactor = 1;
        } else {
            let bounds = this.#items[0].getBounds();
            this.#contentFactor = this.#items[0].getContentSize().x / bounds.width;
            let left = bounds.x;
            let top = bounds.y;
            let right = bounds.x + bounds.width;
            let bottom = bounds.y + bounds.height;
            let box;
            for ( let i = 1; i < this.#items.length; i++ ) {
                box = this.#items[i].getBounds();
                this.#contentFactor = Math.max(this.#contentFactor, this.#items[i].getContentSize().x / box.width);
                left = Math.min( left, box.x );
                top = Math.min( top, box.y );
                right = Math.max( right, box.x + box.width );
                bottom = Math.max( bottom, box.y + box.height );
            }

            this.#homeBounds = new Rect( left, top, right - left, bottom - top );
            this.#contentSize = new Point(this.#homeBounds.width * this.#contentFactor,
                this.#homeBounds.height * this.#contentFactor);
        }

        if (this.#contentFactor !== oldContentFactor || !this.#homeBounds.equals(oldHomeBounds) ||
            !this.#contentSize.equals(oldContentSize)) {
            /**
             * Dispatched when the home bounds or content factor change.
             * @event metrics-change
             * @memberOf Manager
             * @type {object}
             * @property {Manager} eventSource - A reference to the Manager which raised the event.
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.dispatchEvent('metricsChange', {});
        }
    }

    // private
    #raiseRemoveItem(item) {
        /**
         * Dispatched when an item is removed.
         * @event remove-item
         * @memberOf Manager
         * @type {object}
         * @property {Manager} eventSource - A reference to the Manager which raised the event.
         * @property {TiledImage} item - The item's underlying item.
         * @property {?Object} userData - Arbitrary subscriber-defined object.
         */
        this.dispatchEvent( 'removeItem', { item: item } );
    }

}

export default Manager;