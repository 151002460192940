import {reactive, computed, ref} from "vue";
import axios from "axios";
import config from "@/config";
import log from "@/log";

export default {
    install: (app) => {
        const vote = ref(null);
        const value = ref(null);
        const average = ref(null)
        const num = ref(null)


        const load = async () => {
            try {
                let res = await axios.get(`${config.server}/vote`, { headers : {'x-token':sessionStorage.token}})
                if(res.data.data) {
                    vote.value = res.data.data;
                    average.value = res.data.average;
                    num.value = res.data.num;
                    value.value = vote.value ? vote.value.value : null;
                }
                return true
            } catch (e) {
                log.d("error loading vote data", e)
                return false
            }
        }

        app.provide('vote', {
            value,
            average,
            num,
            load,
            reset() {
              value.value = vote.value ? vote.value.value : null;
            },
            async save() {
                if(value.value === null) return false;
                try {
                    await axios.post(`${config.server}/vote`, {value: value.value}, { headers : {'x-token':sessionStorage.token}})
                    await load();
                    return true
                } catch (e) {
                    log.d("error loading vote data", e)
                    return false
                }
            }
        })
    },
}