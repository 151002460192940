import {reactive, computed, ref} from "vue";
import axios from "axios";
import config from "@/config";

export default {
    install: (app) => {
        const store = reactive({
            artwork : null,
            artist : null,
        })

        app.provide('data', {
            load() {
                axios.get(`${config.server}/artwork`, { headers : {'x-token':sessionStorage.token}}).then(res => {
                    if(res.data.data) {
                        store.artwork = res.data.data;
                    }
                }).catch(err => {
                    console.log(err)
                });
                axios.get(`${config.server}/artist`, { headers : {'x-token':sessionStorage.token}}).then(res => {
                    if(res.data.data) {
                        store.artist = res.data.data;
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            artwork : computed(()=>{
                return store.artwork;
            }),
            artist : computed(()=>{
                return store.artist;
            })
        })
    },
}