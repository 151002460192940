import {reactive, computed, ref} from "vue";
import axios from "axios";
import config from "@/config";

export default {
    install: (app) => {
        const items = ref([]);
        app.provide('annotations', {
            items,
            async load() {
                try {
                    let res = await axios.get(`${config.server}/annotations?limit=0`, { headers : {'x-token':sessionStorage.token}})
                    if(res.data.data) {
                        items.value = res.data.data;
                    }
                    return true
                } catch (e) {
                    console.log("error loading annotations data", e)
                    return false
                }

            }
        })
    },
}