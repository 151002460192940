import axios from "axios";
import config from "@/config";
import {ref} from "vue";
import jwt_decode from "jwt-decode";
const auth = {
    loggedIn : false,
    username : sessionStorage.username || null,
    token : sessionStorage.token || null,
    user : null,
    exp : null,
    showTokenDialog : ref(false),
    loading : ref(false),
    invalidAccess : ref(false),
};

const refreshToken = async () => {
    if(!auth.token) {
        throw new Error("no token");
    }
    const params = new URLSearchParams();
    params.append('token', auth.token);
    let reply = await axios.post(`${config.breraServer}/api/centrica/check-centrica-token`, params)

    sessionStorage.token = reply.data.token
    auth.token = reply.data.token;
    auth.user = jwt_decode(reply.data.token);
    return true
}


export default {
    install: (app) => {

        const tokenLoop = () => {
            auth.loading.value = true;
            refreshToken().then(()=>{
                auth.loading.value = false;
                auth.showTokenDialog.value = false;
                setTimeout(tokenLoop, 300000)
            }).catch(err => {
                auth.loading.value = false;
                console.log("refresh token error", err)
                if(config.manualToken) {
                    auth.showTokenDialog.value = true;
                } else {
                    auth.invalidAccess.value = true;
                    sessionStorage.removeItem('token')
                }
            })
        }

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if(params.xt) {
            sessionStorage.token = params.xt;
            auth.token = params.xt;
            tokenLoop();
        } else if(auth.token) {
            tokenLoop();
        }else if(config.manualToken) {
            auth.showTokenDialog.value = true;
        } else {
            auth.invalidAccess.value = true;
        }

        auth.setToken = (t) => {
            sessionStorage.token = t;
            auth.token = t;
            tokenLoop();
        }
        auth.waitForLogin = new Promise((resolve)=>{
            if(auth.user) {
                resolve();
                return
            }
            let intv = setInterval(()=>{
                if(auth.user) {
                    clearInterval(intv);
                    resolve();
                }
            })
        });

        app.provide('auth', auth)
    },
};
