import utils, {Point} from '../utils'
import defaults from '../defaults'
import GesturePointList from './GesturePointList'

function onClick( tracker, event ) {
    if ( tracker.clickHandler ) {
        utils.cancelEvent( event );
    }
}

function onDblClick( tracker, event ) {
    if ( tracker.dblClickHandler ) {
        utils.cancelEvent( event );
    }
}

function onKeyDown( tracker, event ) {
    //xlv.console.log( "keydown %s %s %s %s %s", event.keyCode, event.charCode, event.ctrlKey, event.shiftKey, event.altKey );
    let propagate;
    if ( tracker.keyDownHandler ) {
        propagate = tracker.keyDownHandler(
            {
                eventSource:          tracker,
                keyCode:              event.keyCode ? event.keyCode : event.charCode,
                ctrl:                 event.ctrlKey,
                shift:                event.shiftKey,
                alt:                  event.altKey,
                meta:                 event.metaKey,
                originalEvent:        event,
                preventDefaultAction: false,
                userData:             tracker.userData
            }
        );
        if ( !propagate ) {
            utils.cancelEvent( event );
        }
    }
}

function onKeyUp( tracker, event ) {
    //xlv.console.log( "keyup %s %s %s %s %s", event.keyCode, event.charCode, event.ctrlKey, event.shiftKey, event.altKey );
    let propagate;
    if ( tracker.keyUpHandler ) {
        propagate = tracker.keyUpHandler(
            {
                eventSource:          tracker,
                keyCode:              event.keyCode ? event.keyCode : event.charCode,
                ctrl:                 event.ctrlKey,
                shift:                event.shiftKey,
                alt:                  event.altKey,
                meta:                 event.metaKey,
                originalEvent:        event,
                preventDefaultAction: false,
                userData:             tracker.userData
            }
        );
        if ( !propagate ) {
            utils.cancelEvent( event );
        }
    }
}

function onKeyPress( tracker, event ) {
    //xlv.console.log( "keypress %s %s %s %s %s", event.keyCode, event.charCode, event.ctrlKey, event.shiftKey, event.altKey );
    let propagate;
    if ( tracker.keyHandler ) {
        propagate = tracker.keyHandler(
            {
                eventSource:          tracker,
                keyCode:              event.keyCode ? event.keyCode : event.charCode,
                ctrl:                 event.ctrlKey,
                shift:                event.shiftKey,
                alt:                  event.altKey,
                meta:                 event.metaKey,
                originalEvent:        event,
                preventDefaultAction: false,
                userData:             tracker.userData
            }
        );
        if ( !propagate ) {
            utils.cancelEvent( event );
        }
    }
}

function onFocus( tracker, event ) {
    //console.log( "focus %s", event );
    let propagate;
    if ( tracker.focusHandler ) {
        propagate = tracker.focusHandler(
            {
                eventSource:          tracker,
                originalEvent:        event,
                preventDefaultAction: false,
                userData:             tracker.userData
            }
        );
        if ( propagate === false ) {
            utils.cancelEvent( event );
        }
    }
}

function onBlur( tracker, event ) {
    //console.log( "blur %s", event );
    let propagate;
    if ( tracker.blurHandler ) {
        propagate = tracker.blurHandler(
            {
                eventSource:          tracker,
                originalEvent:        event,
                preventDefaultAction: false,
                userData:             tracker.userData
            }
        );
        if ( propagate === false ) {
            utils.cancelEvent( event );
        }
    }
}

function getPointRelativeToAbsolute( point, element ) {
    const offset = utils.getElementOffset( element );
    return point.minus( offset );
}

function getMouseRelative( event, element ) {
    return getPointRelativeToAbsolute( utils.getMousePosition( event ), element );
}

function onWheel( tracker, event ) {
    let propagate;

    // The nDelta letiable is gated to provide smooth z-index scrolling
    //   since the mouse wheel allows for substantial deltas meant for rapid
    //   y-index scrolling.
    // event.deltaMode: 0=pixel, 1=line, 2=page
    // TODO: Deltas in pixel mode should be accumulated then a scroll value computed after xlv.DEFAULT_SETTINGS.mouseScrollFactor threshold reached
    let nDelta = event.deltaY < 0 ? 1 : -1;

    if ( tracker.scrollHandler ) {
        propagate = tracker.scrollHandler(
            {
                eventSource:          tracker,
                pointerType:          'mouse',
                position:             getMouseRelative( event, tracker.element ),
                scroll:               nDelta,
                shift:                event.shiftKey,
                isTouchEvent:         false,
                originalEvent:        event,
                preventDefaultAction: false,
                userData:             tracker.userData
            }
        );
        if ( propagate === false ) {
            utils.cancelEvent( event );
        }
    }
}

function isParentChild( parent, child ) {
    if ( parent === child ) {
        return false;
    }
    while ( child && child !== parent ) {
        child = child.parentNode;
    }
    return child === parent;
}

function onMouseOver( tracker, event ) {
    if ( event.currentTarget === event.relatedTarget || isParentChild( event.currentTarget, event.relatedTarget ) ) {
        return;
    }

    let gPoint = {
        id: InteractionManager.mousePointerId,
        type: 'mouse',
        isPrimary: true,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };

    updatePointersEnter( tracker, event, [ gPoint ] );
}

function onMouseOut( tracker, event ) {
    if ( event.currentTarget === event.relatedTarget || isParentChild( event.currentTarget, event.relatedTarget ) ) {
        return;
    }
    let gPoint = {
        id: InteractionManager.mousePointerId,
        type: 'mouse',
        isPrimary: true,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };

    updatePointersExit( tracker, event, [ gPoint ] );
}

function onMouseDown( tracker, event ) {
    let gPoint = {
        id: InteractionManager.mousePointerId,
        type: 'mouse',
        isPrimary: true,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };

    if ( updatePointersDown( tracker, event, [ gPoint ], event.button ) ) {
        utils.stopEvent( event );
        capturePointer( tracker, 'mouse' );
    }

    if ( tracker.clickHandler || tracker.dblClickHandler || tracker.pressHandler || tracker.dragHandler || tracker.dragEndHandler ) {
        utils.cancelEvent( event );
    }
}

function onMouseUp( tracker, event ) {
    handleMouseUp( tracker, event );
}
/**
 * This handler is attached to the window object (on the capture phase) to emulate mouse capture.
 * onMouseUp is still attached to the tracked element, so stop propagation to avoid processing twice.
 */
function onMouseUpCaptured( tracker, event ) {
    handleMouseUp( tracker, event );
    utils.stopEvent( event );
}

function handleMouseUp( tracker, event ) {

    let gPoint = {
        id: InteractionManager.mousePointerId,
        type: 'mouse',
        isPrimary: true,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };

    if ( updatePointersUp( tracker, event, [ gPoint ], event.button ) ) {
        releasePointer( tracker, 'mouse' );
    }
}

function onMouseMove( tracker, event ) {
    handleMouseMove( tracker, event );
}


/**
 * This handler is attached to the window object (on the capture phase) to emulate mouse capture.
 * onMouseMove is still attached to the tracked element, so stop propagation to avoid processing twice.
 */
function onMouseMoveCaptured( tracker, event ) {
    handleMouseMove( tracker, event );
    utils.stopEvent( event );
}

function handleMouseMove( tracker, event ) {
    let gPoint = {
        id: InteractionManager.mousePointerId,
        type: 'mouse',
        isPrimary: true,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };
    updatePointersMove( tracker, event, [ gPoint ] );
}

function abortTouchContacts( tracker, event, pointsList ) {
    let i, gPointCount = pointsList.getLength(), abortGPoints = [];
    for ( i = 0; i < gPointCount; i++ ) {
        abortGPoints.push( pointsList.getByIndex( i ) );
    }
    if ( abortGPoints.length > 0 ) {
        // simulate touchend
        updatePointersUp( tracker, event, abortGPoints, 0 ); // 0 means primary button press/release or touch contact
        // release pointer capture
        pointsList.captureCount = 1;
        releasePointer( tracker, 'touch' );
        // simulate touchleave
        updatePointersExit( tracker, event, abortGPoints );
    }
}

function onTouchStart( tracker, event ) {
    let i, j,
        touchCount = event.changedTouches.length,
        gPoints = [],
        parentGPoints,
        pointsList = tracker.getActivePointersListByType( 'touch' );

    let time = Date.now();

    if ( pointsList.getLength() > event.touches.length - touchCount ) {
        this.warn('Tracked touch contact count doesn\'t match event.touches.length. Removing all tracked touch pointers.');
        abortTouchContacts( tracker, event, pointsList );
    }

    for ( i = 0; i < touchCount; i++ ) {
        gPoints.push( {
            id: event.changedTouches[ i ].identifier,
            type: 'touch',
            // isPrimary not set - let the updatePointers functions determine it
            currentPos: this.getMousePosition( event.changedTouches[ i ] ),
            currentTime: time
        } );
    }

    // simulate touchenter on our tracked element
    updatePointersEnter( tracker, event, gPoints );

    // simulate touchenter on our tracked element's tracked ancestor elements
    /** TODO Serve????
     for ( i = 0; i < MANAGERS.length; i++ ) {
        if ( MANAGERS[ i ] !== tracker && MANAGERS[ i ].isTracking() && isParentChild( MANAGERS[ i ].element, tracker.element ) ) {
            parentGPoints = [];
            for ( j = 0; j < touchCount; j++ ) {
                parentGPoints.push( {
                    id: event.changedTouches[ j ].identifier,
                    type: 'touch',
                    // isPrimary not set - let the updatePointers functions determine it
                    currentPos: getMouseAbsolute( event.changedTouches[ j ] ),
                    currentTime: time
                } );
            }
            updatePointersEnter( MANAGERS[ i ], event, parentGPoints );
        }
    }
     */

    if ( updatePointersDown( tracker, event, gPoints, 0 ) ) { // 0 means primary button press/release or touch contact
        utils.stopEvent( event );
        capturePointer( tracker, 'touch' );
    }

    utils.cancelEvent( event );
}

function onTouchEnd( tracker, event ) {
    handleTouchEnd( tracker, event );
}


/**
 * This handler is attached to the window object (on the capture phase) to emulate pointer capture.
 * onTouchEnd is still attached to the tracked element, so stop propagation to avoid processing twice.
 */
function onTouchEndCaptured( tracker, event ) {
    handleTouchEnd( tracker, event );
    utils.stopEvent( event );
}

function handleTouchEnd( tracker, event ) {
    let i, j,
        touchCount = event.changedTouches.length,
        gPoints = [],
        parentGPoints;

    let time = Date.now();

    for ( i = 0; i < touchCount; i++ ) {
        gPoints.push( {
            id: event.changedTouches[ i ].identifier,
            type: 'touch',
            // isPrimary not set - let the updatePointers functions determine it
            currentPos: utils.getMousePosition( event.changedTouches[ i ] ),
            currentTime: time
        } );
    }

    if ( updatePointersUp( tracker, event, gPoints, 0 ) ) {
        releasePointer( tracker, 'touch' );
    }

    // simulate touchleave on our tracked element
    updatePointersExit( tracker, event, gPoints );

    // simulate touchleave on our tracked element's tracked ancestor elements
    /** TODO serve????
     for ( i = 0; i < MANAGERS.length; i++ ) {
        if ( MANAGERS[ i ] !== tracker && MANAGERS[ i ].isTracking() && isParentChild( MANAGERS[ i ].element, tracker.element ) ) {
            parentGPoints = [];
            for ( j = 0; j < touchCount; j++ ) {
                parentGPoints.push( {
                    id: event.changedTouches[ j ].identifier,
                    type: 'touch',
                    // isPrimary not set - let the updatePointers functions determine it
                    currentPos: getMouseAbsolute( event.changedTouches[ j ] ),
                    currentTime: time
                } );
            }
            updatePointersExit( MANAGERS[ i ], event, parentGPoints );
        }
    }
     */

    utils.cancelEvent( event );
}

function onTouchMove( tracker, event ) {
    handleTouchMove( tracker, event );
}


/**
 * This handler is attached to the window object (on the capture phase) to emulate pointer capture.
 * onTouchMove is still attached to the tracked element, so stop propagation to avoid processing twice.
 */
function onTouchMoveCaptured( tracker, event ) {
    handleTouchMove( tracker, event );
    utils.stopEvent( event );
}

function handleTouchMove( tracker, event ) {
    let i, touchCount = event.changedTouches.length,
        gPoints = [];
    for ( i = 0; i < touchCount; i++ ) {
        gPoints.push( {
            id: event.changedTouches[ i ].identifier,
            type: 'touch',
            // isPrimary not set - let the updatePointers functions determine it
            currentPos: utils.getMousePosition( event.changedTouches[ i ] ),
            currentTime: Date.now()
        } );
    }
    updatePointersMove( tracker, event, gPoints );
    utils.cancelEvent( event );
}

function onTouchCancel( tracker, event ) {
    let pointsList = tracker.getActivePointersListByType( 'touch' );
    abortTouchContacts( tracker, event, pointsList );
}

function onGestureStart( tracker, event ) {
    event.stopPropagation();
    event.cancelable && event.preventDefault();
    return false;
}

function onGestureChange( tracker, event ) {
    event.stopPropagation();
    event.cancelable && event.preventDefault();
    return false;
}

function onPointerOver( tracker, event ) {
    if ( event.currentTarget === event.relatedTarget || isParentChild( event.currentTarget, event.relatedTarget ) ) {
        return;
    }
    const gPoint = {
        id: event.pointerId,
        type: event.pointerType,
        isPrimary: event.isPrimary,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };
    updatePointersEnter( tracker, event, [ gPoint ] );

}

function onPointerOut( tracker, event ) {
    if ( event.currentTarget === event.relatedTarget || isParentChild( event.currentTarget, event.relatedTarget ) ) {
        return;
    }
    const gPoint = {
        id: event.pointerId,
        type: event.pointerType,
        isPrimary: event.isPrimary,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };
    let pointsList = tracker.getActivePointersListByType( gPoint.type )
    let updateGPoint = pointsList.getById(event.pointerId)
    releasePointerUp(tracker, event, gPoint, updateGPoint, pointsList)
    releasePointer( tracker, gPoint.type );
    updatePointersExit( tracker, event, [ gPoint ] );
}

function onPointerDown( tracker, event ) {
    const gPoint = {
        id: event.pointerId,
        type: event.pointerType,
        isPrimary: event.isPrimary,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };

    if ( updatePointersDown( tracker, event, [ gPoint ], event.button ) ) {
        utils.stopEvent( event );
        capturePointer( tracker, gPoint.type );
    }

    if ( tracker.clickHandler || tracker.dblClickHandler || tracker.pressHandler || tracker.dragHandler || tracker.dragEndHandler || tracker.pinchHandler ) {
        utils.cancelEvent( event );
    }
}


function onPointerUp( tracker, event ) {
    handlePointerUp( tracker, event );
}


/**
 * This handler is attached to the window object (on the capture phase) to emulate mouse capture.
 * onPointerUp is still attached to the tracked element, so stop propagation to avoid processing twice.
 */
function onPointerUpCaptured( tracker, event ) {
    let pointsList = tracker.getActivePointersListByType( event.pointerType );
    if ( pointsList.getById( event.pointerId ) ) {
        handlePointerUp( tracker, event );
    }
    utils.stopEvent( event );
}

function handlePointerUp( tracker, event ) {
    const gPoint = {
        id: event.pointerId,
        type: event.pointerType,
        isPrimary: event.isPrimary,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };

    if ( updatePointersUp( tracker, event, [ gPoint ], event.button ) ) {
        releasePointer( tracker, gPoint.type );
    }
}


function onPointerMove( tracker, event ) {
    handlePointerMove( tracker, event );
}


/**
 * This handler is attached to the window object (on the capture phase) to emulate mouse capture.
 * onPointerMove is still attached to the tracked element, so stop propagation to avoid processing twice.
 */
function onPointerMoveCaptured( tracker, event ) {
    let pointsList = tracker.getActivePointersListByType( event.pointerType );
    if ( pointsList.getById( event.pointerId ) ) {
        handlePointerMove( tracker, event );
    }
    utils.stopEvent( event );
}

function handlePointerMove( tracker, event ) {

    // Pointer changed coordinates, button state, pressure, tilt, or contact geometry (e.g. width and height)
    const gPoint = {
        id: event.pointerId,
        type: event.pointerType,
        isPrimary: event.isPrimary,
        currentPos: utils.getMousePosition( event ),
        currentTime: Date.now()
    };
    updatePointersMove( tracker, event, [ gPoint ] );
}

function onPointerCancel( tracker, event ) {
    const gPoint = {
        id: event.pointerId,
        type: event.pointerType
    };

    updatePointersCancel( tracker, event, [ gPoint ] );
}

function getCaptureEventParams( tracker, pointerType ) {
    if ( pointerType === 'pointerevent' ) {
        return {
            upName: 'pointerup',
            upHandler: tracker.pointerupcaptured,
            moveName: 'pointermove',
            moveHandler: tracker.pointermovecaptured
        };
    } else if ( pointerType === 'mouse' ) {
        return {
            upName: 'mouseup',
            upHandler: tracker.mouseupcaptured,
            moveName: 'mousemove',
            moveHandler: tracker.mousemovecaptured
        };
    } else if ( pointerType === 'touch' ) {
        return {
            upName: 'touchend',
            upHandler: tracker.touchendcaptured,
            moveName: 'touchmove',
            moveHandler: tracker.touchmovecaptured
        };
    } else {
        throw new Error( "InteractionManager.getCaptureEventParams: Unknown pointer type." );
    }
}

function canAccessEvents (target) {
    try {
        return target.addEventListener && target.removeEventListener;
    } catch (e) {
        return false;
    }
}

/**
 * Begin capturing pointer events to the tracked element.
 */
function capturePointer( tracker, pointerType ) {
    let pointsList = tracker.getActivePointersListByType( pointerType ),
        eventParams;

    pointsList.captureCount++;

    if ( pointsList.captureCount === 1 ) {
        eventParams = getCaptureEventParams( tracker, InteractionManager.havePointerEvents ? 'pointerevent' : pointerType );
        // We emulate mouse capture by hanging listeners on the this.trackedElement object.
        //    (Note we listen on the capture phase so the captured handlers will get called first)
        if (utils.isInIframe && canAccessEvents(window.top)) {
            utils.bind(
                window.top,
                eventParams.upName,
                eventParams.upHandler,
                true
            );
        }
        utils.bind(
            tracker.trackedElement,
            eventParams.upName,
            eventParams.upHandler,
            true
        );
        utils.bind(
            tracker.trackedElement,
            eventParams.moveName,
            eventParams.moveHandler,
            true
        );
    }
}

/**
 * Stop capturing pointer events to the tracked element.
 */
function releasePointer( tracker, pointerType ) {
    let pointsList = tracker.getActivePointersListByType( pointerType ),
        eventParams;

    pointsList.captureCount--;

    if ( pointsList.captureCount === 0 ) {
        eventParams = getCaptureEventParams( tracker, InteractionManager.havePointerEvents ? 'pointerevent' : pointerType );
        // We emulate mouse capture by hanging listeners on the document object.
        //    (Note we listen on the capture phase so the captured handlers will get called first)
        if (utils.isInIframe && canAccessEvents(window.top)) {
            utils.unbind(
                window.top,
                eventParams.upName,
                eventParams.upHandler,
                true
            );
        }
        utils.unbind(
            tracker.trackedElement,
            eventParams.moveName,
            eventParams.moveHandler,
            true
        );
        utils.unbind(
            tracker.trackedElement,
            eventParams.upName,
            eventParams.upHandler,
            true
        );
    }
}

function updatePointersEnter( tracker, event, gPoints ) {
    let pointsList = tracker.getActivePointersListByType( gPoints[ 0 ].type ),
        i,
        gPointCount = gPoints.length,
        curGPoint,
        updateGPoint,
        propagate;

    for ( i = 0; i < gPointCount; i++ ) {
        curGPoint = gPoints[ i ];
        updateGPoint = pointsList.getById( curGPoint.id );

        if ( updateGPoint ) {
            // Already tracking the pointer...update it
            updateGPoint.insideElement = true;
            updateGPoint.lastPos = updateGPoint.currentPos;
            updateGPoint.lastTime = updateGPoint.currentTime;
            updateGPoint.currentPos = curGPoint.currentPos;
            updateGPoint.currentTime = curGPoint.currentTime;

            curGPoint = updateGPoint;
        } else {
            // Initialize for tracking and add to the tracking list
            curGPoint.captured = false;
            curGPoint.insideElementPressed = false;
            curGPoint.insideElement = true;
            startTrackingPointer( pointsList, curGPoint );
        }

        // Enter
        if ( tracker.enterHandler ) {
            propagate = tracker.enterHandler(
                {
                    eventSource:          tracker,
                    pointerType:          curGPoint.type,
                    position:             getPointRelativeToAbsolute( curGPoint.currentPos, tracker.element ),
                    buttons:              pointsList.buttons,
                    pointers:             tracker.getActivePointerCount(),
                    insideElementPressed: curGPoint.insideElementPressed,
                    buttonDownAny:        pointsList.buttons !== 0,
                    isTouchEvent:         curGPoint.type === 'touch',
                    originalEvent:        event,
                    preventDefaultAction: false,
                    userData:             tracker.userData
                }
            );
            if ( propagate === false ) {
                utils.cancelEvent( event );
            }
        }
    }
}

function updatePointersExit( tracker, event, gPoints ) {
    let pointsList = tracker.getActivePointersListByType( gPoints[ 0 ].type ),
        i,
        gPointCount = gPoints.length,
        curGPoint,
        updateGPoint,
        propagate;

    for ( i = 0; i < gPointCount; i++ ) {
        curGPoint = gPoints[ i ];
        updateGPoint = pointsList.getById( curGPoint.id );

        if ( updateGPoint ) {
            // Already tracking the pointer. If captured then update it, else stop tracking it
            if ( updateGPoint.captured ) {
                updateGPoint.insideElement = false;
                updateGPoint.lastPos = updateGPoint.currentPos;
                updateGPoint.lastTime = updateGPoint.currentTime;
                updateGPoint.currentPos = curGPoint.currentPos;
                updateGPoint.currentTime = curGPoint.currentTime;
            } else {
                stopTrackingPointer( pointsList, updateGPoint );
            }

            curGPoint = updateGPoint;
        }

        // Exit
        if ( tracker.exitHandler ) {
            let absPos = curGPoint.currentPos ? getPointRelativeToAbsolute( curGPoint.currentPos, tracker.element ) : curGPoint.currentPos;
            propagate = tracker.exitHandler(
                {
                    eventSource:          tracker,
                    pointerType:          curGPoint.type,
                    position:             absPos,
                    buttons:              pointsList.buttons,
                    pointers:             tracker.getActivePointerCount(),
                    insideElementPressed: updateGPoint ? updateGPoint.insideElementPressed : false,
                    buttonDownAny:        pointsList.buttons !== 0,
                    isTouchEvent:         curGPoint.type === 'touch',
                    originalEvent:        event,
                    preventDefaultAction: false,
                    userData:             tracker.userData
                }
            );

            if ( propagate === false ) {
                utils.cancelEvent( event );
            }
        }
    }
}

function getCenterPoint( point1, point2 ) {
    return new Point( ( point1.x + point2.x ) / 2, ( point1.y + point2.y ) / 2 );
}

function updatePointersDown( tracker, event, gPoints, buttonChanged ) {
    let propagate,
        pointsList = tracker.getActivePointersListByType( gPoints[ 0 ].type ),
        i,
        gPointCount = gPoints.length,
        curGPoint,
        updateGPoint;

    if ( typeof event.buttons !== 'undefined' ) {
        pointsList.buttons = event.buttons;
    } else {
        if ( buttonChanged === 0 ) {
            // Primary
            pointsList.buttons |= 1;
        } else if ( buttonChanged === 1 ) {
            // Aux
            pointsList.buttons |= 4;
        } else if ( buttonChanged === 2 ) {
            // Secondary
            pointsList.buttons |= 2;
        } else if ( buttonChanged === 3 ) {
            // X1 (Back)
            pointsList.buttons |= 8;
        } else if ( buttonChanged === 4 ) {
            // X2 (Forward)
            pointsList.buttons |= 16;
        } else if ( buttonChanged === 5 ) {
            // Pen Eraser
            pointsList.buttons |= 32;
        }
    }

    // Only capture and track primary button, pen, and touch contacts
    if ( buttonChanged !== 0 ) {
        // Aux Press
        if ( tracker.nonPrimaryPressHandler ) {
            propagate = tracker.nonPrimaryPressHandler(
                {
                    eventSource:          tracker,
                    pointerType:          gPoints[ 0 ].type,
                    position:             getPointRelativeToAbsolute( gPoints[ 0 ].currentPos, tracker.element ),
                    button:               buttonChanged,
                    buttons:              pointsList.buttons,
                    isTouchEvent:         gPoints[ 0 ].type === 'touch',
                    originalEvent:        event,
                    preventDefaultAction: false,
                    userData:             tracker.userData
                }
            );
            if ( propagate === false ) {
                utils.cancelEvent( event );
            }
        }

        return false;
    }

    for ( i = 0; i < gPointCount; i++ ) {
        curGPoint = gPoints[ i ];
        updateGPoint = pointsList.getById( curGPoint.id );

        if ( updateGPoint ) {
            // Already tracking the pointer...update it
            updateGPoint.captured = true;
            updateGPoint.insideElementPressed = true;
            updateGPoint.insideElement = true;
            updateGPoint.contactPos = curGPoint.currentPos;
            updateGPoint.contactTime = curGPoint.currentTime;
            updateGPoint.lastPos = updateGPoint.currentPos;
            updateGPoint.lastTime = updateGPoint.currentTime;
            updateGPoint.currentPos = curGPoint.currentPos;
            updateGPoint.currentTime = curGPoint.currentTime;

            curGPoint = updateGPoint;
        } else {
            // Initialize for tracking and add to the tracking list (no pointerover or pointermove event occurred before this)
            curGPoint.captured = true;
            curGPoint.insideElementPressed = true;
            curGPoint.insideElement = true;
            startTrackingPointer( pointsList, curGPoint );
        }

        pointsList.contacts++;
        //xlv.console.log('contacts++ ', pointsList.contacts);

        if ( tracker.dragHandler || tracker.dragEndHandler || tracker.pinchHandler ) {
            tracker.gesturePointVelocityTracker.addPoint( tracker, curGPoint );
        }

        if ( pointsList.contacts === 1 ) {
            // Press
            if ( tracker.pressHandler ) {
                propagate = tracker.pressHandler(
                    {
                        eventSource:          tracker,
                        pointerType:          curGPoint.type,
                        position:             getPointRelativeToAbsolute( curGPoint.contactPos, tracker.element ),
                        buttons:              pointsList.buttons,
                        isTouchEvent:         curGPoint.type === 'touch',
                        originalEvent:        event,
                        preventDefaultAction: false,
                        userData:             tracker.userData
                    }
                );
                if ( propagate === false ) {
                    utils.cancelEvent( event );
                }
            }
        } else if ( pointsList.contacts === 2 ) {
            handlePinchReset(tracker, pointsList, curGPoint);
        }
    }

    return true;
}

function handlePinchReset(tracker, pointsList, curGPoint) {
    if ( tracker.pinchHandler && curGPoint.type === 'touch' ) {
        // Initialize for pinch
        tracker.pinchGPoints = pointsList.asArray();
        tracker.lastPinchDist = tracker.currentPinchDist = tracker.pinchGPoints[ 0 ].currentPos.distanceTo( tracker.pinchGPoints[ 1 ].currentPos );
        tracker.lastPinchCenter = tracker.currentPinchCenter = getCenterPoint( tracker.pinchGPoints[ 0 ].currentPos, tracker.pinchGPoints[ 1 ].currentPos );
    }
}
function handleRelease(tracker, event, pointsList, updateGPoint, releasePoint) {
    let propagate = tracker.releaseHandler(
        {
            eventSource:           tracker,
            pointerType:           updateGPoint.type,
            position:              getPointRelativeToAbsolute( releasePoint, tracker.element ),
            buttons:               pointsList.buttons,
            insideElementPressed:  updateGPoint.insideElementPressed,
            insideElementReleased: updateGPoint.insideElement,
            isTouchEvent:          updateGPoint.type === 'touch',
            originalEvent:         event,
            preventDefaultAction:  false,
            userData:              tracker.userData
        }
    );
    if ( propagate === false ) {
        utils.cancelEvent( event );
    }
    return propagate;
}

function updatePointersUp( tracker, event, gPoints, buttonChanged ) {
    let pointsList = tracker.getActivePointersListByType( gPoints[ 0 ].type ),
        propagate,
        i,
        gPointCount = gPoints.length,
        curGPoint,
        updateGPoint,
        releaseCapture = false;

    if ( typeof event.buttons !== 'undefined' ) {
        pointsList.buttons = event.buttons;
    } else {
        if ( buttonChanged === 0 ) {
            // Primary
            pointsList.buttons ^= ~1;
        } else if ( buttonChanged === 1 ) {
            // Aux
            pointsList.buttons ^= ~4;
        } else if ( buttonChanged === 2 ) {
            // Secondary
            pointsList.buttons ^= ~2;
        } else if ( buttonChanged === 3 ) {
            // X1 (Back)
            pointsList.buttons ^= ~8;
        } else if ( buttonChanged === 4 ) {
            // X2 (Forward)
            pointsList.buttons ^= ~16;
        } else if ( buttonChanged === 5 ) {
            // Pen Eraser
            pointsList.buttons ^= ~32;
        }
    }

    // Only capture and track primary button, pen, and touch contacts
    if ( buttonChanged !== 0 ) {
        // Aux Release
        if ( tracker.nonPrimaryReleaseHandler ) {
            propagate = tracker.nonPrimaryReleaseHandler(
                {
                    eventSource:           tracker,
                    pointerType:           gPoints[ 0 ].type,
                    position:              getPointRelativeToAbsolute(  gPoints[ 0 ].currentPos, tracker.element ),
                    button:                buttonChanged,
                    buttons:               pointsList.buttons,
                    isTouchEvent:          gPoints[ 0 ].type === 'touch',
                    originalEvent:         event,
                    preventDefaultAction:  false,
                    userData:              tracker.userData
                }
            );
            if ( propagate === false ) {
                utils.cancelEvent( event );
            }
        }

        return false;
    }

    for ( i = 0; i < gPointCount; i++ ) {
        curGPoint = gPoints[ i ];
        updateGPoint = pointsList.getById( curGPoint.id );

        releaseCapture = releasePointerUp(tracker, event, curGPoint, updateGPoint, pointsList);
    }

    return releaseCapture;
}

function releasePointerUp(tracker, event, curGPoint, updateGPoint, pointsList) {
    let releasePoint,
        releaseTime,
        propagate,
        quick,
        releaseCapture = false,
        wasCaptured = false;

    // Update the pointer, stop tracking it if not still in this element
    if ( updateGPoint.captured ) {
        updateGPoint.captured = false;
        releaseCapture = true;
        wasCaptured = true;
    }
    updateGPoint.lastPos = updateGPoint.currentPos;
    updateGPoint.lastTime = updateGPoint.currentTime;
    updateGPoint.currentPos = curGPoint.currentPos;
    updateGPoint.currentTime = curGPoint.currentTime;
    if ( !updateGPoint.insideElement ) {
        stopTrackingPointer( pointsList, updateGPoint );
    }

    releasePoint = updateGPoint.currentPos;
    releaseTime = updateGPoint.currentTime;

    if ( wasCaptured ) {
        // Pointer was activated in our element but could have been removed in any element since events are captured to our element

        pointsList.contacts--;
        //xlv.console.log('contacts-- ', pointsList.contacts);

        if ( tracker.dragHandler || tracker.dragEndHandler || tracker.pinchHandler ) {
            tracker.gesturePointVelocityTracker.removePoint( tracker, updateGPoint );
        }

        if ( pointsList.contacts === 0 ) {

            // Release (pressed in our element)
            if ( tracker.releaseHandler ) {
                propagate = handleRelease(tracker, event, pointsList, updateGPoint, releasePoint)
            }

            // Drag End
            if ( tracker.dragEndHandler && !updateGPoint.currentPos.equals( updateGPoint.contactPos ) ) {
                propagate = tracker.dragEndHandler(
                    {
                        eventSource:          tracker,
                        pointerType:          updateGPoint.type,
                        position:             getPointRelativeToAbsolute( updateGPoint.currentPos, tracker.element ),
                        speed:                updateGPoint.speed,
                        direction:            updateGPoint.direction,
                        shift:                event.shiftKey,
                        isTouchEvent:         updateGPoint.type === 'touch',
                        originalEvent:        event,
                        preventDefaultAction: false,
                        userData:             tracker.userData
                    }
                );
                if ( propagate === false ) {
                    utils.cancelEvent( event );
                }
            }

            // Click / Double-Click
            if ( ( tracker.clickHandler || tracker.dblClickHandler ) && updateGPoint.insideElement ) {
                quick = releaseTime - updateGPoint.contactTime <= tracker.clickMaxDuration &&
                    updateGPoint.contactPos.distanceTo( releasePoint ) <= tracker.clickAreaRadius;

                // Click
                if ( tracker.clickHandler ) {
                    propagate = tracker.clickHandler(
                        {
                            eventSource:          tracker,
                            pointerType:          updateGPoint.type,
                            position:             getPointRelativeToAbsolute( updateGPoint.currentPos, tracker.element ),
                            quick:                quick,
                            shift:                event.shiftKey,
                            isTouchEvent:         updateGPoint.type === 'touch',
                            originalEvent:        event,
                            preventDefaultAction: false,
                            userData:             tracker.userData
                        }
                    );
                    if ( propagate === false ) {
                        utils.cancelEvent( event );
                    }
                }

                // Double-Click
                if ( tracker.dblClickHandler && quick ) {
                    pointsList.clicks++;
                    if ( pointsList.clicks === 1 ) {
                        tracker.lastClickPos = releasePoint;
                        /*jshint loopfunc:true*/
                        tracker.dblClickTimeOut = setTimeout( function() {
                            pointsList.clicks = 0;
                        }, tracker.doubleClickMaxDuration );
                        /*jshint loopfunc:false*/
                    } else if ( pointsList.clicks === 2 ) {
                        clearTimeout( tracker.dblClickTimeOut );
                        pointsList.clicks = 0;
                        if ( tracker.lastClickPos.distanceTo( releasePoint ) <= tracker.doubleClickAreaRadius ) {
                            propagate = tracker.dblClickHandler(
                                {
                                    eventSource:          tracker,
                                    pointerType:          updateGPoint.type,
                                    position:             getPointRelativeToAbsolute( updateGPoint.currentPos, tracker.element ),
                                    shift:                event.shiftKey,
                                    isTouchEvent:         updateGPoint.type === 'touch',
                                    originalEvent:        event,
                                    preventDefaultAction: false,
                                    userData:             tracker.userData
                                }
                            );
                            if ( propagate === false ) {
                                utils.cancelEvent( event );
                            }
                        }
                        tracker.lastClickPos = null;
                    }
                }
            }
        } else if ( pointsList.contacts === 2 ) {
            handlePinchReset(tracker, pointsList, curGPoint);
        }
    } else {
        // Pointer was activated in another element but removed in our element

        // Release (pressed in another element)
        if ( tracker.releaseHandler ) {
            propagate = handleRelease(tracker, event, pointsList, updateGPoint, releasePoint)
            if ( propagate === false ) {
                utils.cancelEvent( event );
            }
        }
    }

    return releaseCapture;
}


/**
 * Call when pointer(s) change coordinates, button state, pressure, tilt, or contact geometry (e.g. width and height)
 */
function updatePointersMove( tracker, event, gPoints ) {
    let pointsList = tracker.getActivePointersListByType( gPoints[ 0 ].type ),
        i,
        gPointCount = gPoints.length,
        curGPoint,
        updateGPoint,
        gPointArray,
        delta,
        propagate;


    if ( typeof event.buttons !== 'undefined' ) {
        pointsList.buttons = event.buttons;
    }

    for ( i = 0; i < gPointCount; i++ ) {
        curGPoint = gPoints[ i ];
        updateGPoint = pointsList.getById( curGPoint.id );

        if ( updateGPoint ) {
            //console.log('handlePointerMove', updateGPoint);
            // Already tracking the pointer...update it
            if ( curGPoint.hasOwnProperty( 'isPrimary' ) ) {
                updateGPoint.isPrimary = curGPoint.isPrimary;
            }
            updateGPoint.lastPos = updateGPoint.currentPos;
            updateGPoint.lastTime = updateGPoint.currentTime;
            updateGPoint.currentPos = curGPoint.currentPos;
            updateGPoint.currentTime = curGPoint.currentTime;
        } else {
            // Initialize for tracking and add to the tracking list (no pointerover or pointerdown event occurred before this)
            curGPoint.captured = false;
            curGPoint.insideElementPressed = false;
            curGPoint.insideElement = true;
            startTrackingPointer( pointsList, curGPoint );
        }
    }

    // Stop (mouse only)
    if ( tracker.stopHandler && gPoints[ 0 ].type === 'mouse' ) {
        clearTimeout( tracker.stopTimeOut );
        tracker.stopTimeOut = setTimeout( function() {
            handlePointerStop( tracker, event, gPoints[ 0 ].type );
        }, tracker.stopDelay );
    }


    if ( pointsList.contacts === 0 ) {
        // Move (no contacts: hovering mouse or other hover-capable device)
        if ( tracker.moveHandler ) {
            propagate = tracker.moveHandler(
                {
                    eventSource:          tracker,
                    pointerType:          gPoints[ 0 ].type,
                    position:             getPointRelativeToAbsolute( gPoints[ 0 ].currentPos, tracker.element ),
                    buttons:              pointsList.buttons,
                    isTouchEvent:         gPoints[ 0 ].type === 'touch',
                    originalEvent:        event,
                    preventDefaultAction: false,
                    userData:             tracker.userData
                }
            );
            if ( propagate === false ) {
                utils.cancelEvent( event );
            }
        }
    } else if ( pointsList.contacts === 1 ) {
        // Move (1 contact)
        if ( tracker.moveHandler ) {
            updateGPoint = pointsList.asArray()[ 0 ];
            propagate = tracker.moveHandler(
                {
                    eventSource:          tracker,
                    pointerType:          updateGPoint.type,
                    position:             getPointRelativeToAbsolute( updateGPoint.currentPos, tracker.element ),
                    buttons:              pointsList.buttons,
                    isTouchEvent:         updateGPoint.type === 'touch',
                    originalEvent:        event,
                    preventDefaultAction: false,
                    userData:             tracker.userData
                }
            );
            if ( propagate === false ) {
                utils.cancelEvent( event );
            }
        }

        // Drag
        if ( tracker.dragHandler ) {
            updateGPoint = pointsList.asArray()[ 0 ];
            delta = updateGPoint.currentPos.minus( updateGPoint.lastPos );
            propagate = tracker.dragHandler(
                {
                    eventSource:          tracker,
                    pointerType:          updateGPoint.type,
                    position:             getPointRelativeToAbsolute( updateGPoint.currentPos, tracker.element ),
                    buttons:              pointsList.buttons,
                    delta:                delta,
                    speed:                updateGPoint.speed,
                    direction:            updateGPoint.direction,
                    shift:                event.shiftKey,
                    isTouchEvent:         updateGPoint.type === 'touch',
                    originalEvent:        event,
                    preventDefaultAction: false,
                    userData:             tracker.userData
                }
            );
            if ( propagate === false ) {
                utils.cancelEvent( event );
            }
        }
    } else if ( pointsList.contacts === 2 ) {
        // Move (2 contacts, use center)
        if ( tracker.moveHandler ) {
            gPointArray = pointsList.asArray();
            propagate = tracker.moveHandler(
                {
                    eventSource:          tracker,
                    pointerType:          gPointArray[ 0 ].type,
                    position:             getPointRelativeToAbsolute( getCenterPoint( gPointArray[ 0 ].currentPos, gPointArray[ 1 ].currentPos ), tracker.element ),
                    buttons:              pointsList.buttons,
                    isTouchEvent:         gPointArray[ 0 ].type === 'touch',
                    originalEvent:        event,
                    preventDefaultAction: false,
                    userData:             tracker.userData
                }
            );
            if ( propagate === false ) {
                utils.cancelEvent( event );
            }
        }

        // Pinch
        if ( tracker.pinchHandler && gPoints[ 0 ].type === 'touch' ) {
            delta = tracker.pinchGPoints[ 0 ].currentPos.distanceTo( tracker.pinchGPoints[ 1 ].currentPos );
            if ( delta !== tracker.currentPinchDist ) {
                tracker.lastPinchDist = tracker.currentPinchDist;
                tracker.currentPinchDist = delta;
                tracker.lastPinchCenter = tracker.currentPinchCenter;
                tracker.currentPinchCenter = getCenterPoint( tracker.pinchGPoints[ 0 ].currentPos, tracker.pinchGPoints[ 1 ].currentPos );
                propagate = tracker.pinchHandler(
                    {
                        eventSource:          tracker,
                        pointerType:          'touch',
                        gesturePoints:        tracker.pinchGPoints,
                        lastCenter:           getPointRelativeToAbsolute( tracker.lastPinchCenter, tracker.element ),
                        center:               getPointRelativeToAbsolute( tracker.currentPinchCenter, tracker.element ),
                        lastDistance:         tracker.lastPinchDist,
                        distance:             tracker.currentPinchDist,
                        shift:                event.shiftKey,
                        originalEvent:        event,
                        preventDefaultAction: false,
                        userData:             tracker.userData
                    }
                );
                if ( propagate === false ) {
                    utils.cancelEvent( event );
                }
            }
        }
    }
}

function handlePointerStop( tracker, originalMoveEvent, pointerType ) {
    if ( tracker.stopHandler ) {
        tracker.stopHandler( {
            eventSource:          tracker,
            pointerType:          pointerType,
            position:             getMouseRelative( originalMoveEvent, tracker.element ),
            buttons:              tracker.getActivePointersListByType( pointerType ).buttons,
            isTouchEvent:         pointerType === 'touch',
            originalEvent:        originalMoveEvent,
            preventDefaultAction: false,
            userData:             tracker.userData
        } );
    }
}

function updatePointersCancel( tracker, event, gPoints ) {
    updatePointersUp( tracker, event, gPoints, 0 );
    updatePointersExit( tracker, event, gPoints );
}

const genGesturePointVelocityTracker = () => {
    let trackerPoints = [],
        intervalId = 0,
        lastTime = 0;

    // Generates a unique identifier for a tracked gesture point
    let _generateGuid = ( tracker, gPoint ) => {
        return tracker.hash.toString() + gPoint.type + gPoint.id.toString();
    };

    // Interval timer callback. Computes velocity for all tracked gesture points.
    let _doTracking = function () {
        let i,
            len = trackerPoints.length,
            trackPoint,
            gPoint,
            now = Date.now(),
            elapsedTime,
            distance,
            speed;

        elapsedTime = now - lastTime;
        lastTime = now;

        for ( i = 0; i < len; i++ ) {
            trackPoint = trackerPoints[ i ];
            gPoint = trackPoint.gPoint;
            // Math.atan2 gives us just what we need for a velocity vector, as we can simply
            // use cos()/sin() to extract the x/y velocity components.
            gPoint.direction = Math.atan2( gPoint.currentPos.y - trackPoint.lastPos.y, gPoint.currentPos.x - trackPoint.lastPos.x );
            // speed = distance / elapsed time
            distance = trackPoint.lastPos.distanceTo( gPoint.currentPos );
            trackPoint.lastPos = gPoint.currentPos;
            speed = 1000 * distance / ( elapsedTime + 1 );
            // Simple biased average, favors the most recent speed computation. Smooths out erratic gestures a bit.
            gPoint.speed = 0.75 * speed + 0.25 * gPoint.speed;
        }
    };

    // Public. Add a gesture point to be tracked
    const addPoint = ( tracker, gPoint ) => {
        const guid = _generateGuid( tracker, gPoint );

        trackerPoints.push(
            {
                guid: guid,
                gPoint: gPoint,
                lastPos: gPoint.currentPos
            } );

        // Only fire up the interval timer when there's gesture pointers to track
        if ( trackerPoints.length === 1 ) {
            lastTime = Date.now();
            intervalId = window.setInterval( _doTracking, 50 );
        }
    };

    // Public. Stop tracking a gesture point
    const removePoint = function ( tracker, gPoint ) {
        const guid = _generateGuid( tracker, gPoint );
        let i, len = trackerPoints.length;
        for ( i = 0; i < len; i++ ) {
            if ( trackerPoints[ i ].guid === guid ) {
                trackerPoints.splice( i, 1 );
                // Only run the interval timer if theres gesture pointers to track
                len--;
                if ( len === 0 ) {
                    window.clearInterval( intervalId );
                }
                break;
            }
        }
    };

    return {
        addPoint: addPoint,
        removePoint: removePoint
    };
}

function startTrackingPointer( pointsList, gPoint ) {

    // If isPrimary is not known for the pointer then set it according to our rules:
    //    true if the first pointer in the gesture, otherwise false
    if ( !gPoint.hasOwnProperty( 'isPrimary' ) ) {
        gPoint.isPrimary = pointsList.getLength() === 0;
    }
    gPoint.speed = 0;
    gPoint.direction = 0;
    gPoint.contactPos = gPoint.currentPos;
    gPoint.contactTime = gPoint.currentTime;
    gPoint.lastPos = gPoint.currentPos;
    gPoint.lastTime = gPoint.currentTime;

    return pointsList.add( gPoint );
}

function stopTrackingPointer( pointsList, gPoint ) {
    let listLength, primaryPoint;

    if ( pointsList.getById( gPoint.id ) ) {
        listLength = pointsList.removeById( gPoint.id );

        // If isPrimary is not known for the pointer and we just removed the primary pointer from the list then we need to set another pointer as primary
        if ( !gPoint.hasOwnProperty( 'isPrimary' ) ) {
            primaryPoint = pointsList.getPrimary();
            if ( !primaryPoint ) {
                primaryPoint = pointsList.getByIndex( 0 );
                if ( primaryPoint ) {
                    primaryPoint.isPrimary = true;
                }
            }
        }
    } else {
        listLength = pointsList.getLength();
    }

    return listLength;
}


class InteractionManager {
    static lastHash = Math.round(10000 * Math.random() );
    static commonsEvents = [ "click", "dblclick", "keydown", "keyup", "keypress", "focus", "blur", "wheel"];
    static pointerEvents = window.PointerEvent ? [ "pointerout", "pointerdown", "pointerup", "pointermove", "pointerover", "pointercancel" ] :
        ["mouseover", "mouseout", "mousedown", "mouseup", "mousemove"];
    static haveMouseEnter = !window.PointerEvent;
    static havePointerEvents = !InteractionManager.haveMouseEnter;
    static touchEvents = ('ontouchstart' in window) ? ["touchstart", "touchend", "touchmove", "touchcancel"] : [];
    static gestureEvents = ('ongesturestart' in window) ? ["gesturestart", "gesturechange"] : [];
    static subscribeEvents = InteractionManager.commonsEvents.concat(InteractionManager.pointerEvents, InteractionManager.touchEvents, InteractionManager.gestureEvents);

    static mousePointerId = "legacy-mouse";
    static maxTouchPoints = window.PointerEvent ? (navigator.maxTouchPoints ? navigator.maxTouchPoints : 0) : 10;

    hash;
    /**
     * The element for which pointer events are being monitored.
     * @type {HTMLElement|string}
     */
    element;
    trackedElement;
    /**
     * The number of milliseconds within which a pointer down-up event combination
     * will be treated as a click gesture.
     */
    clickMaxDuration;
    /**
     * The maximum distance allowed between a pointer down event and a pointer up event
     * to be treated as a click gesture.
     */
    clickAreaRadius;
    /**
     * The number of milliseconds within which two pointer down-up event combinations
     * will be treated as a double-click gesture.
     */
    doubleClickMaxDuration;
    /**
     * The maximum distance allowed between two pointer click events
     * to be treated as a click gesture.
     */
    doubleClickAreaRadius;
    userData;
    stopDelay;
    enterHandler;
    exitHandler;
    pressHandler;
    nonPrimaryPressHandler;
    releaseHandler;
    nonPrimaryReleaseHandler;
    moveHandler;
    scrollHandler;
    clickHandler;
    dblClickHandler;
    dragHandler;
    dragEndHandler;
    pinchHandler;
    stopHandler;
    keyDownHandler;
    keyUpHandler;
    keyHandler;
    blurHandler;

    eventHandlers;

    gesturePointVelocityTracker;

    tracking = false;
    activePointersLists = [];
    lastClickPos = null;
    dblClickTimeOut = null;
    pinchGPoints = [];
    lastPinchDist = 0;
    currentPinchDist = 0;
    lastPinchCenter = null;
    currentPinchCenter = null;

    constructor( options ) {
        InteractionManager.lastHash++;
        this.hash = InteractionManager.lastHash;

        this.element = utils.getElement( options.element );
        this.trackedElement = this.element;
        this.clickMaxDuration = options.clickMaxDuration || defaults.clickMaxDuration;
        this.clickAreaRadius = options.clickAreaRadius || defaults.clickAreaRadius;
        this.doubleClickMaxDuration = options.doubleClickMaxDuration || defaults.doubleClickMaxDuration;
        this.doubleClickAreaRadius = options.doubleClickAreaRadius || defaults.doubleClickAreaRadius;
        this.userData = options.userData || null;
        this.stopDelay = options.stopDelay || 50;
        this.enterHandler = options.enterHandler || null;
        this.exitHandler = options.exitHandler || null;
        this.pressHandler = options.pressHandler || null;
        this.nonPrimaryPressHandler = options.nonPrimaryPressHandler || null;
        this.releaseHandler = options.releaseHandler || null;
        this.nonPrimaryReleaseHandler = options.nonPrimaryReleaseHandler || null;
        this.moveHandler = options.moveHandler || null;
        this.scrollHandler = options.scrollHandler || null;
        this.clickHandler = options.clickHandler || null;
        this.dblClickHandler = options.dblClickHandler || null;
        this.dragHandler = options.dragHandler || null;
        this.dragEndHandler = options.dragEndHandler || null;
        this.pinchHandler = options.pinchHandler || null;
        this.stopHandler = options.stopHandler || null;
        this.keyDownHandler = options.keyDownHandler || null;
        this.keyUpHandler = options.keyUpHandler || null;
        this.keyHandler = options.keyHandler || null;
        this.focusHandler = options.focusHandler || null;
        this.blurHandler = options.blurHandler || null;

        this.eventHandlers = {
            click: ( event ) => { onClick( this, event ); },
            dblclick: (event) => { onDblClick( this, event ); },
            keydown: (event) => { onKeyDown( this, event ); },
            keyup: (event) => { onKeyUp( this, event ); },
            keypress: (event) => { onKeyPress( this, event ); },
            focus: (event) => { onFocus( this, event ); },
            blur: (event) => { onBlur( this, event ); },
            wheel: (event) => { onWheel( this, event ); },
            mouseover: (event) => { onMouseOver( this, event ); },
            mouseout: (event) => { onMouseOut( this, event ); },
            mousedown: (event) => { onMouseDown( this, event ); },
            mouseup: (event) => { onMouseUp( this, event ); },
            mouseupcaptured: (event) => { onMouseUpCaptured( this, event ); },
            mousemove: (event) => { onMouseMove( this, event ); },
            mousemovecaptured: (event) => { onMouseMoveCaptured( this, event ); },
            touchstart: (event) => { onTouchStart( this, event ); },
            touchend: (event) => { onTouchEnd( this, event ); },
            touchendcaptured: (event) => { onTouchEndCaptured( this, event ); },
            touchmove: (event) => { onTouchMove( this, event ); },
            touchmovecaptured: (event) => { onTouchMoveCaptured( this, event ); },
            touchcancel: (event) => { onTouchCancel( this, event ); },
            gesturestart: (event) => { onGestureStart( this, event ); },
            gesturechange: (event) => { onGestureChange( this, event ); },
            pointerover: (event) => { onPointerOver( this, event ); },
            pointerout: (event) => { onPointerOut( this, event ); },
            pointerdown: (event) => { onPointerDown( this, event ); },
            pointerup: (event) => { onPointerUp( this, event ); },
            pointermove: (event) => { onPointerMove( this, event ); },
            pointercancel: (event) => { onPointerCancel( this, event ); },
            pointerupcaptured: (event) => { onPointerUpCaptured( this, event ); },
            pointermovecaptured: (event) => { onPointerMoveCaptured( this, event ); },
        }


        if ( !options.startDisabled ) {
            this.setTracking( true );
        }

        this.gesturePointVelocityTracker = genGesturePointVelocityTracker();
    }


    /**
     * Clean up any events or objects created by the manager.
     */
    destroy() {
        let i;
        this.stopTracking();
        this.element = null;
    }


    /**
     * Are we currently tracking events on this element.
     */
    isTracking() {
        return this.tracking;
    }

    /**
     * Enable or disable whether or not we are tracking events on this element.
     */
    setTracking( track ) {
        if ( track ) {
            this.startTracking();
        } else {
            this.stopTracking();
        }
        //chain
        return this;
    }
    /**
     * Returns the gesture point list for the given pointer device type,
     * creating and caching a new gesture point list if one doesn't already exist for the type.
     */
    getActivePointersListByType( type ) {
        let i,
            len = this.activePointersLists.length,
            list;

        for ( i = 0; i < len; i++ ) {
            if ( this.activePointersLists[ i ].type === type ) {
                return this.activePointersLists[ i ];
            }
        }
        list = new GesturePointList( type );
        this.activePointersLists.push( list );
        return list;
    }

    /**
     * Returns the total number of pointers currently active on the tracked element.
     */
    getActivePointerCount() {
        let i, len = this.activePointersLists.length, count = 0;
        for ( i = 0; i < len; i++ ) {
            count += this.activePointersLists[ i ].getLength();
        }
        return count;
    }

    /**
     * Removes all tracked pointers.
     */
    clearTrackedPointers() {
        let i, pointerListCount = this.activePointersLists.length;
        for ( i = 0; i < pointerListCount; i++ ) {
            if ( this.activePointersLists[ i ].captureCount > 0 ) {
                utils.unbind(
                    this.trackedElement,
                    'mousemove',
                    this.eventHandlers.mousemovecaptured,
                    true
                );
                utils.unbind(
                    this.trackedElement,
                    'mouseup',
                    this.eventHandlers.mouseupcaptured,
                    true
                );
                utils.unbind(
                    this.trackedElement,
                    'pointermove',
                    this.eventHandlers.pointermovecaptured,
                    true
                );
                utils.unbind(
                    this.trackedElement,
                    'pointerup',
                    this.eventHandlers.pointerupcaptured,
                    true
                );
                utils.unbind(
                    this.trackedElement,
                    'touchmove',
                    this.eventHandlers.touchmovecaptured,
                    true
                );
                utils.unbind(
                    this.trackedElement,
                    'touchend',
                    this.eventHandlers.touchendcaptured,
                    true
                );

                this.activePointersLists[ i ].captureCount = 0;
            }
        }
        for ( i = 0; i < pointerListCount; i++ ) {
            this.activePointersLists.pop();
        }
    }


    /**
     * Starts tracking pointer events on the tracked element.
     */
    startTracking() {
        let event, i;
        if ( !this.tracking ) {
            for ( i = 0; i < InteractionManager.subscribeEvents.length; i++ ) {
                event = InteractionManager.subscribeEvents[ i ];
                utils.bind(
                    this.element,
                    event,
                    this.eventHandlers[ event ],
                    false
                );
            }
            this.clearTrackedPointers();
            this.tracking = true;
        }
    }

    /**
     * Stops tracking pointer events on the tracked element.
     */
    stopTracking() {
        let event, i;
        if ( this.tracking ) {

            for ( i = 0; i < InteractionManager.subscribeEvents.length; i++ ) {
                event = InteractionManager.subscribeEvents[ i ];
                utils.unbind(
                    this.element,
                    event,
                    this.eventHandlers[ event ],
                    false
                );
            }
            this.clearTrackedPointers();
            this.tracking = false;
        }
    }
}

export default InteractionManager