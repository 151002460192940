import defaults from "../defaults";
import {Tween, Point, Rect, Logger} from "../utils";
import utils from "../utils";
class Viewport {
    log;
    margins;
    containerSize = null;
    contentSize = null;
    zoomPoint = null;
    viewer = null;
    tweenSmoothness = defaults.tweenSmoothness;
    tweenDuration = defaults.tweenDuration;
    underZoomRatio = defaults.underZoomRatio;
    overZoomRatio = defaults.overZoomRatio;
    visibilityRatio = defaults.visibilityRatio;
    wrapHorizontal = defaults.wrapHorizontal;
    wrapVertical = defaults.wrapVertical;
    defaultZoomLevel = defaults.defaultZoomLevel;
    minZoomLevel = defaults.minZoomLevel;
    maxZoomLevel = defaults.maxZoomLevel;
    rotation = defaults.rotation;
    homeFillsViewer = defaults.homeFillsViewer;

    centerSpringX;
    centerSpringY;
    zoomSpring;
    oldCenterX;
    oldCenterY;
    oldZoom;
    containerInnerSize;

    contentBoundsNoRotate;
    contentSizeNoRotate;

    /**
     * Handles coordinate-related functionality (zoom, pan, rotation, etc.)
     */
    constructor(options) {
        this.log = new Logger({ logLevel : options.logLevel})
        this.margins = Object.assign({
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        }, options.margins || {});
        delete options.margins;

        Object.assign(this, options)

        this.updateContainerInnerSize();


        this.centerSpringX = new Tween({
            initial: 0,
            tweenSmoothness: this.tweenSmoothness,
            tweenDuration:   this.tweenDuration
        });
        this.centerSpringY = new Tween({
            initial: 0,
            tweenSmoothness: this.tweenSmoothness,
            tweenDuration:   this.tweenDuration
        });
        this.zoomSpring    = new Tween({
            exponential: true,
            initial: 1,
            tweenSmoothness: this.tweenSmoothness,
            tweenDuration:   this.tweenDuration
        });

        this.oldCenterX = this.centerSpringX.current.value;
        this.oldCenterY = this.centerSpringY.current.value;
        this.oldZoom    = this.zoomSpring.current.value;

        this.setContentBounds(new Rect(0, 0, 1, 1), 1);

        this.doZoomReset(true);
        this.update();
    }



    /**
     * Updates the viewport's home bounds and constraints for the given content size.
     * @function
     * @param {Point} contentSize - size of the content in content units
     * @return {Viewport} Chainable.
     * @fires event:reset-size
     */
    resetContentSize (contentSize) {
        this.log.d(contentSize, "[Viewport.resetContentSize] contentSize is required");
        this.log.d(contentSize instanceof Point, "[Viewport.resetContentSize] contentSize must be an Point");
        this.log.d(contentSize.x > 0, "[Viewport.resetContentSize] contentSize.x must be greater than 0");
        this.log.d(contentSize.y > 0, "[Viewport.resetContentSize] contentSize.y must be greater than 0");

        this.setContentBounds(new Rect(0, 0, 1, contentSize.y / contentSize.x), contentSize.x);
        return this;
    }

    // Set the viewport's content bounds
    // @param {Rect} bounds - the new bounds in viewport coordinates
    // without rotation
    // @param {Number} contentFactor - how many content units per viewport unit
    // @fires event:reset-size
    // @private
    setContentBounds (bounds, contentFactor) {
        this.log.d(bounds, "[Viewport.setContentBounds] bounds is required");
        this.log.d(bounds instanceof Rect, "[Viewport.setContentBounds] bounds must be an Rect");
        this.log.d(bounds.width > 0, "[Viewport.setContentBounds] bounds.width must be greater than 0");
        this.log.d(bounds.height > 0, "[Viewport.setContentBounds] bounds.height must be greater than 0");

        this.contentBoundsNoRotate = bounds.clone();
        this.contentSizeNoRotate = this.contentBoundsNoRotate.getSize().times(
            contentFactor);

        this._contentBounds = bounds.rotate(this.rotation).getBoundingBox();
        this._contentSize = this._contentBounds.getSize().times(contentFactor);
        this._contentAspectRatio = this._contentSize.x / this._contentSize.y;

        if (this.viewer) {
            /**
             * Raised when the viewer's content size or home bounds are reset
             * (see {@link Viewport#resetContentSize}).
             *
             * @event reset-size
             * @memberof XLViewer
             * @type {object}
             * @property {XLViewer} eventSource - A reference to the XLViewer which raised this event.
             * @property {Point} contentSize
             * @property {Rect} contentBounds - Content bounds.
             * @property {Rect} homeBounds - Content bounds.
             * Deprecated use contentBounds instead.
             * @property {Number} contentFactor
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.viewer.dispatchEvent('resetSize', {
                contentSize: this.contentSizeNoRotate.clone(),
                contentFactor: contentFactor,
                homeBounds: this.contentBoundsNoRotate.clone(),
                contentBounds: this._contentBounds.clone()
            });
        }
    }

    /**
     * Returns the home zoom in "viewport zoom" value.
     * @function
     * @returns {Number} The home zoom in "viewport zoom".
     */
    getHomeZoom () {
        if (this.defaultZoomLevel) {
            return this.defaultZoomLevel;
        }

        let aspectFactor = this._contentAspectRatio / this.getAspectRatio();
        let output;
        if (this.homeFillsViewer) { // fill the viewer and clip the image
            output = aspectFactor >= 1 ? aspectFactor : 1;
        } else {
            output = aspectFactor >= 1 ? 1 : aspectFactor;
        }

        return output / this._contentBounds.width;
    }

    /**
     * Returns the home bounds in viewport coordinates.
     * @function
     * @returns {Rect} The home bounds in vewport coordinates.
     */
    getHomeBounds () {
        let center = this._contentBounds.getCenter();
        let width  = 1.0 / this.getHomeZoom();
        let height = width / this.getAspectRatio();

        return new Rect(
            center.x - (width / 2.0),
            center.y - (height / 2.0),
            width,
            height
        );
    }

    /**
     * @function
     * @param {Boolean} immediately
     * @fires event:home
     */
    doZoomReset ( immediately ) {
        if( this.viewer ){
            /**
             * Raised when the "home" operation occurs (see {@link Viewport#doZoomReset}).
             *
             * @event home
             * @memberof XLViewer
             * @type {object}
             * @property {XLViewer} eventSource - A reference to the XLViewer which raised this event.
             * @property {Boolean} immediately
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.viewer.dispatchEvent( 'home', {
                immediately: immediately
            });
        }
        return this.fitBounds( this.getHomeBounds(), immediately );
    }

    /**
     * @function
     */
    getMinZoom () {
        let homeZoom = this.getHomeZoom();
        return this.minZoomLevel ?
            this.minZoomLevel :
            this.underZoomRatio * homeZoom;
    }

    /**
     * @function
     */
    getMaxZoom () {
        let zoom = this.maxZoomLevel;
        if (!zoom) {
            zoom = this._contentSize.x * this.overZoomRatio / this.containerInnerSize.x;
            zoom /= this._contentBounds.width;
        }

        return Math.max( zoom, this.getHomeZoom() );
    }

    /**
     * @function
     */
    getAspectRatio () {
        return this.containerInnerSize.x / this.containerInnerSize.y;
    }

    /**
     * @function
     * @returns {Point} The size of the container, in screen coordinates.
     */
    getContainerSize () {
        return new Point(
            this.containerSize.x,
            this.containerSize.y
        );
    }

    /**
     * The margins push the "home" region in from the sides by the specified amounts.
     * @function
     * @returns {Object} Properties (Numbers, in screen coordinates): left, top, right, bottom.
     */
    getMargins () {
        return Object.assign({}, this.margins); // Make a copy so we are not returning our original
    }

    /**
     * The margins push the "home" region in from the sides by the specified amounts.
     * @function
     * @param {Object} margins - Properties (Numbers, in screen coordinates): left, top, right, bottom.
     */
    setMargins (margins) {
        this.margins = Object.assign({
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        }, margins);

        this.updateContainerInnerSize();
        this.viewer.forceRedraw();
    }

    /**
     * Returns the bounds of the visible area in viewport coordinates.
     * @function
     * @param {Boolean?} current - Pass true for the current location; defaults to false (target location).
     * @returns {Rect} The location you are zoomed/panned to, in viewport coordinates.
     */
    getBounds ( current ) {
        let center = this.getCenter( current ),
            width  = 1.0 / this.getZoom( current ),
            height = width / this.getAspectRatio();

        return new Rect(
            center.x - ( width / 2.0 ),
            center.y - ( height / 2.0 ),
            width,
            height
        );
    }

    /**
     * Returns the bounds of the visible area in normalized viewport coordinates (dimensions from 0 to 1).
     * @function
     * @param {Boolean} current - Pass true for the current location; defaults to false (target location).
     * @returns {Rect} The location you are zoomed/panned to, in viewport coordinates.
     */
    getNormalizedBounds ( current ) {
        let bounds = this.getBounds( current );
        bounds.x /= this._contentBounds.width;
        bounds.width /= this._contentBounds.width;
        bounds.y /= this._contentBounds.height;
        bounds.height /= this._contentBounds.height;
        return bounds;
    }

    /**
     * @function
     * @param {Boolean} current - Pass true for the current location; defaults to false (target location).
     * @returns {Rect} The location you are zoomed/panned to,
     * including the space taken by margins, in viewport coordinates.
     */
    getBoundsWithMargins ( current ) {
        let bounds = this.getBounds(current);
        let factor = this.containerInnerSize.x * this.getZoom(current);
        bounds.x -= this.margins.left / factor;
        bounds.y -= this.margins.top / factor;
        bounds.width += (this.margins.left + this.margins.right) / factor;
        bounds.height += (this.margins.top + this.margins.bottom) / factor;
        return bounds;
    }

    /**
     * @function
     * @param {Boolean} current - Pass true for the current location; defaults to false (target location).
     */
    getCenter ( current ) {
        let centerCurrent = new Point(
                this.centerSpringX.current.value,
                this.centerSpringY.current.value
            ),
            centerTarget = new Point(
                this.centerSpringX.target.value,
                this.centerSpringY.target.value
            ),
            oldZoomPixel,
            zoom,
            width,
            height,
            bounds,
            newZoomPixel,
            deltaZoomPixels,
            deltaZoomPoints;

        if ( current ) {
            return centerCurrent;
        } else if ( !this.zoomPoint ) {
            return centerTarget;
        }

        oldZoomPixel = this.pixelFromPoint(this.zoomPoint, true);

        zoom    = this.getZoom();
        width   = 1.0 / zoom;
        height  = width / this.getAspectRatio();
        bounds  = new Rect(
            centerCurrent.x - width / 2.0,
            centerCurrent.y - height / 2.0,
            width,
            height
        );

        newZoomPixel = this._pixelFromPoint(this.zoomPoint, bounds);
        deltaZoomPixels = newZoomPixel.minus( oldZoomPixel );
        deltaZoomPoints = deltaZoomPixels.divide( this.containerInnerSize.x * zoom );

        return centerTarget.plus( deltaZoomPoints );
    }

    /**
     * @function
     * @param {Boolean?} current - Pass true for the current location; defaults to false (target location).
     */
    getZoom ( current ) {
        if ( current ) {
            return this.zoomSpring.current.value;
        } else {
            return this.zoomSpring.target.value;
        }
    }

    /**
     * @function
     * @private
     * @param {Rect} bounds
     * @param {Boolean} immediately
     * @return {Rect} constrained bounds.
     */
    applyBoundaryConstraints (bounds, immediately) {
        let newBounds = new Rect(
            bounds.x,
            bounds.y,
            bounds.width,
            bounds.height);

        let horizontalThreshold = this.visibilityRatio * newBounds.width;
        let verticalThreshold   = this.visibilityRatio * newBounds.height;

        if (this.wrapHorizontal) {
            //do nothing
        } else {
            let dx = 0;
            let thresholdLeft = newBounds.x + (newBounds.width - horizontalThreshold);
            if (this.contentBoundsNoRotate.x > thresholdLeft) {
                dx = this.contentBoundsNoRotate.x - thresholdLeft;
            }

            let contentRight = this.contentBoundsNoRotate.x + this.contentBoundsNoRotate.width;
            let thresholdRight = newBounds.x + horizontalThreshold;
            if (contentRight < thresholdRight) {
                let newDx = contentRight - thresholdRight;
                if (dx) {
                    dx = (dx + newDx) / 2;
                } else {
                    dx = newDx;
                }
            }
            newBounds.x += dx;
        }

        if (this.wrapVertical) {
            //do nothing
        } else {
            let dy = 0;
            let thresholdTop = newBounds.y + (newBounds.height - verticalThreshold);
            if (this.contentBoundsNoRotate.y > thresholdTop) {
                dy = this.contentBoundsNoRotate.y - thresholdTop;
            }

            let contentBottom = this.contentBoundsNoRotate.y + this.contentBoundsNoRotate.height;
            let thresholdBottom = newBounds.y + verticalThreshold;
            if (contentBottom < thresholdBottom) {
                let newDy = contentBottom - thresholdBottom;
                if (dy) {
                    dy = (dy + newDy) / 2;
                } else {
                    dy = newDy;
                }
            }
            newBounds.y += dy;
        }

        if (this.viewer) {
            /**
             * Raised when the viewport constraints are applied (see {@link Viewport#applyConstraints}).
             *
             * @event constrain
             * @memberof XLViewer
             * @type {object}
             * @property {XLViewer} eventSource - A reference to the XLViewer which raised this event.
             * @property {Boolean} immediately
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.viewer.dispatchEvent( 'constrain', {
                immediately: immediately
            });
        }

        return newBounds;
    }

    /**
     * @function
     * @return {Viewport} Chainable.
     * @fires event:constrain
     */
    applyConstraints ( immediately ) {
        let actualZoom = this.getZoom(),
            constrainedZoom = Math.max(
                Math.min( actualZoom, this.getMaxZoom() ),
                this.getMinZoom()
            ),
            bounds,
            constrainedBounds;

        if ( actualZoom !== constrainedZoom ) {
            this.zoomTo( constrainedZoom, this.zoomPoint, immediately );
        }

        bounds = this.getBounds();

        constrainedBounds = this.applyBoundaryConstraints( bounds, immediately );

        if ( bounds.x !== constrainedBounds.x || bounds.y !== constrainedBounds.y || immediately ){
            this.fitBounds( constrainedBounds, immediately );
        }

        return this;
    }

    /**
     * @function
     * @param {Boolean} immediately
     */
    ensureVisible ( immediately ) {
        return this.applyConstraints( immediately );
    }

    /**
     * @function
     * @private
     * @param {Rect} bounds
     * @param {Object} options (immediately=false, constraints=false)
     * @return {Viewport} Chainable.
     */
    _fitBounds ( bounds, options ) {
        options = options || {};
        let immediately = options.immediately || false;
        let constraints = options.constraints || false;

        let aspect = this.getAspectRatio();
        let center = bounds.getCenter();
        let newBounds = new Rect(
            bounds.x,
            bounds.y,
            bounds.width,
            bounds.height
        );

        if ( newBounds.getAspectRatio() >= aspect ) {
            newBounds.height = bounds.width / aspect;
            newBounds.y      = center.y - newBounds.height / 2;
        } else {
            newBounds.width = bounds.height * aspect;
            newBounds.x     = center.x - newBounds.width / 2;
        }

        this.panTo( this.getCenter( true ), true );
        this.zoomTo( this.getZoom( true ), null, true );

        let oldBounds = this.getBounds();
        let oldZoom   = this.getZoom();
        let newZoom   = 1.0 / newBounds.width;

        if (constraints) {
            let newBoundsAspectRatio = newBounds.getAspectRatio();
            let newConstrainedZoom = Math.max(
                Math.min(newZoom, this.getMaxZoom() ),
                this.getMinZoom()
            );

            if (newZoom !== newConstrainedZoom) {
                newZoom = newConstrainedZoom;
                newBounds.width = 1.0 / newZoom;
                newBounds.x = center.x - newBounds.width / 2;
                newBounds.height = newBounds.width / newBoundsAspectRatio;
                newBounds.y = center.y - newBounds.height / 2;
            }

            newBounds = this.applyBoundaryConstraints( newBounds, immediately );
            center = newBounds.getCenter();
        }

        if (immediately) {
            this.panTo( center, true );
            return this.zoomTo(newZoom, null, true);
        }

        if (Math.abs(newZoom - oldZoom) < 0.00000001 ||
            Math.abs(newBounds.width - oldBounds.width) < 0.00000001) {
            return this.panTo( center, immediately );
        }

        let referencePoint = oldBounds.getTopLeft().times(
            this.containerInnerSize.x / oldBounds.width
        ).minus(
            newBounds.getTopLeft().times(
                this.containerInnerSize.x / newBounds.width
            )
        ).divide(
            this.containerInnerSize.x / oldBounds.width -
            this.containerInnerSize.x / newBounds.width
        );

        return this.zoomTo( newZoom, referencePoint, immediately );
    }

    /**
     * @function
     * @param {Rect} bounds
     * @param {Boolean} immediately
     * @return {Viewport} Chainable.
     */
    fitBounds ( bounds, immediately ) {
        return this._fitBounds( bounds, {
            immediately: immediately,
            constraints: false
        } );
    }

    /**
     * Effettua uno zoom verso un certo rettangolo {Rect}
     * le dimensioni del rettangolo vanno da 0 a 1.
     * @param bounds
     * @param immediately
     * @param applyContraints
     */
    fitNormalizedBounds ( bounds, immediately, applyContraints ) {
        bounds.x *= this._contentBounds.width;
        bounds.width *= this._contentBounds.width;
        bounds.y *= this._contentBounds.height;
        bounds.height *= this._contentBounds.height;
        if(applyContraints) {
            this.fitBoundsWithConstraints(bounds, immediately);
        } else {
            this.fitBounds(bounds, immediately);
        }

    }

    /**
     * @function
     * @param {Rect} bounds
     * @param {Boolean} immediately
     * @return {Viewport} Chainable.
     */
    fitBoundsWithConstraints ( bounds, immediately ) {
        return this._fitBounds( bounds, {
            immediately: immediately,
            constraints: true
        } );
    }

    /**
     * Zooms so the image just fills the viewer vertically.
     * @param {Boolean} immediately
     * @return {Viewport} Chainable.
     */
    fitVertically (immediately) {
        let box = new Rect(
            this._contentBounds.x + (this._contentBounds.width / 2),
            this._contentBounds.y,
            0,
            this._contentBounds.height);
        return this.fitBounds(box, immediately);
    }

    /**
     * Zooms so the image just fills the viewer horizontally.
     * @param {Boolean} immediately
     * @return {Viewport} Chainable.
     */
    fitHorizontally (immediately) {
        let box = new Rect(
            this._contentBounds.x,
            this._contentBounds.y + (this._contentBounds.height / 2),
            this._contentBounds.width,
            0);
        return this.fitBounds(box, immediately);
    }


    /**
     * @function
     * @param {Point} delta
     * @param {Boolean} immediately
     * @return {Viewport} Chainable.
     * @fires event:pan
     */
    panBy ( delta, immediately ) {
        let center = new Point(
            this.centerSpringX.target.value,
            this.centerSpringY.target.value
        );
        return this.panTo( center.plus( delta ), immediately );
    }

    /**
     * @function
     * @param {Point} center
     * @param {Boolean} immediately
     * @return {Viewport} Chainable.
     * @fires event:pan
     */
    panTo ( center, immediately ) {
        if ( immediately ) {
            this.centerSpringX.resetTo( center.x );
            this.centerSpringY.resetTo( center.y );
        } else {
            this.centerSpringX.springTo( center.x );
            this.centerSpringY.springTo( center.y );
        }

        if( this.viewer ){
            /**
             * Raised when the viewport is panned (see {@link Viewport#panBy} and {@link Viewport#panTo}).
             *
             * @event pan
             * @memberof XLViewer
             * @type {object}
             * @property {XLViewer} eventSource - A reference to the XLViewer which raised this event.
             * @property {Point} center
             * @property {Boolean} immediately
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.viewer.dispatchEvent( 'pan', {
                center: center,
                immediately: immediately
            });
        }

        return this;
    }

    /**
     * @function
     * @return {Viewport} Chainable.
     * @fires event:zoom
     */
    zoomBy (factor, refPoint, immediately) {
        return this.zoomTo(
            this.zoomSpring.target.value * factor, refPoint, immediately);
    }

    /**
     * @function
     * @return {Viewport} Chainable.
     * @fires event:zoom
     */
    zoomTo ( zoom, refPoint, immediately ) {

        this.zoomPoint = refPoint instanceof Point &&
        !isNaN(refPoint.x) &&
        !isNaN(refPoint.y) ?
            refPoint :
            null;

        if ( immediately ) {
            this.zoomSpring.resetTo( zoom );
        } else {
            this.zoomSpring.springTo( zoom );
        }

        if( this.viewer ){
            /**
             * Raised when the viewport zoom level changes (see {@link Viewport#zoomBy} and {@link Viewport#zoomTo}).
             *
             * @event zoom
             * @memberof XLViewer
             * @type {object}
             * @property {XLViewer} eventSource - A reference to the XLViewer which raised this event.
             * @property {Number} zoom
             * @property {Point} refPoint
             * @property {Boolean} immediately
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.viewer.dispatchEvent( 'zoom', {
                zoom: zoom,
                refPoint: refPoint,
                immediately: immediately
            });
        }

        return this;
    }

    /**
     * Rotates this viewport to the angle specified.
     * @function
     * @return {Viewport} Chainable.
     */
    setRotation (rotation) {
        if (!this.viewer || !this.viewer.drawer.canRotate()) {
            return this;
        }

        rotation = rotation % 360;
        if (rotation < 0) {
            rotation += 360;
        }
        this.rotation = rotation;
        this.setContentBounds(
            this.viewer.manager.getHomeBounds(),
            this.viewer.manager.getContentFactor());
        this.viewer.forceRedraw();

        /**
         * Raised when rotation has been changed.
         *
         * @event rotate
         * @memberof XLViewer
         * @type {object}
         * @property {XLViewer} eventSource - A reference to the XLViewer which raised the event.
         * @property {Number} rotation - The number of rotation the rotation was set to.
         * @property {?Object} userData - Arbitrary subscriber-defined object.
         */
        this.viewer.dispatchEvent('rotate', {"rotation": rotation});
        return this;
    }

    /**
     * Gets the current rotation in rotation.
     * @function
     * @return {Number} The current rotation in rotation.
     */
    getRotation () {
        return this.rotation;
    }

    /**
     * @function
     * @return {Viewport} Chainable.
     * @fires event:resize
     */
    resize ( newContainerSize, maintain ) {
        let oldBounds = this.getBounds(),
            newBounds = oldBounds,
            widthDeltaFactor;

        this.containerSize.x = newContainerSize.x;
        this.containerSize.y = newContainerSize.y;

        this.updateContainerInnerSize();

        if ( maintain ) {
            // TODO: widthDeltaFactor will always be 1; probably not what's intended
            widthDeltaFactor = newContainerSize.x / this.containerSize.x;
            newBounds.width  = oldBounds.width * widthDeltaFactor;
            newBounds.height = newBounds.width / this.getAspectRatio();
        }

        if( this.viewer ){
            /**
             * Raised when the viewer is resized (see {@link Viewport#resize}).
             *
             * @event resize
             * @memberof XLViewer
             * @type {object}
             * @property {XLViewer} eventSource - A reference to the XLViewer which raised this event.
             * @property {Point} newContainerSize
             * @property {Boolean} maintain
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.viewer.dispatchEvent( 'resize', {
                newContainerSize: newContainerSize,
                maintain: maintain
            });
        }

        return this.fitBounds( newBounds, true );
    }

    // private
    updateContainerInnerSize () {
        this.containerInnerSize = new Point(
            Math.max(1, this.containerSize.x - (this.margins.left + this.margins.right)),
            Math.max(1, this.containerSize.y - (this.margins.top + this.margins.bottom))
        );
    }

    /**
     * @function
     */
    update () {
        let oldZoomPixel,
            newZoomPixel,
            deltaZoomPixels,
            deltaZoomPoints;

        if (this.zoomPoint) {
            oldZoomPixel = this.pixelFromPoint( this.zoomPoint, true );
        }

        this.zoomSpring.update();

        if (this.zoomPoint && this.zoomSpring.current.value !== this.oldZoom) {
            newZoomPixel    = this.pixelFromPoint( this.zoomPoint, true );
            deltaZoomPixels = newZoomPixel.minus( oldZoomPixel );
            deltaZoomPoints = this.deltaPointsFromPixels( deltaZoomPixels, true );

            this.centerSpringX.shiftBy( deltaZoomPoints.x );
            this.centerSpringY.shiftBy( deltaZoomPoints.y );
        } else {
            this.zoomPoint = null;
        }

        this.centerSpringX.update();
        this.centerSpringY.update();

        let changed = this.centerSpringX.current.value !== this.oldCenterX ||
            this.centerSpringY.current.value !== this._oldCenterY ||
            this.zoomSpring.current.value !== this.oldZoom;

        this.oldCenterX = this.centerSpringX.current.value;
        this._oldCenterY = this.centerSpringY.current.value;
        this.oldZoom    = this.zoomSpring.current.value;

        return changed;
    }

    /**
     * Convert a delta (translation vector) from viewport coordinates to pixels
     * coordinates. This method does not take rotation into account.
     * Consider using deltaPixelsFromPoints if you need to account for rotation.
     * @param {Point} deltaPoints - The translation vector to convert.
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    deltaPixelsFromPointsNoRotate (deltaPoints, current) {
        return deltaPoints.times(
            this.containerInnerSize.x * this.getZoom(current)
        );
    }

    /**
     * Convert a delta (translation vector) from viewport coordinates to pixels
     * coordinates.
     * @param {Point} deltaPoints - The translation vector to convert.
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    deltaPixelsFromPoints (deltaPoints, current) {
        return this.deltaPixelsFromPointsNoRotate(
            deltaPoints.rotate(this.getRotation()),
            current);
    }

    /**
     * Convert a delta (translation vector) from pixels coordinates to viewport
     * coordinates. This method does not take rotation into account.
     * Consider using deltaPointsFromPixels if you need to account for rotation.
     * @param {Point} deltaPixels - The translation vector to convert.
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    deltaPointsFromPixelsNoRotate (deltaPixels, current) {
        return deltaPixels.divide(
            this.containerInnerSize.x * this.getZoom(current)
        );
    }

    /**
     * Convert a delta (translation vector) from pixels coordinates to viewport
     * coordinates.
     * @param {Point} deltaPixels - The translation vector to convert.
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    deltaPointsFromPixels (deltaPixels, current) {
        return this.deltaPointsFromPixelsNoRotate(deltaPixels, current)
            .rotate(-this.getRotation());
    }

    /**
     * Convert viewport coordinates to pixels coordinates.
     * This method does not take rotation into account.
     * Consider using pixelFromPoint if you need to account for rotation.
     * @param {Point} point the viewport coordinates
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    pixelFromPointNoRotate (point, current) {
        return this._pixelFromPointNoRotate(point, this.getBounds(current));
    }

    /**
     * Convert viewport coordinates to pixel coordinates.
     * @param {Point} point the viewport coordinates
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    pixelFromPoint (point, current) {
        return this._pixelFromPoint(point, this.getBounds(current));
    }

    // private
    _pixelFromPointNoRotate (point, bounds) {
        return point.minus(
            bounds.getTopLeft()
        ).times(
            this.containerInnerSize.x / bounds.width
        ).plus(
            new Point(this.margins.left, this.margins.top)
        );
    }

    // private
    _pixelFromPoint (point, bounds) {
        return this._pixelFromPointNoRotate(
            point.rotate(this.getRotation(), this.getCenter(true)),
            bounds);
    }

    /**
     * Convert pixel coordinates to viewport coordinates.
     * This method does not take rotation into account.
     * Consider using pointFromPixel if you need to account for rotation.
     * @param {Point} pixel Pixel coordinates
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    pointFromPixelNoRotate (pixel, current) {
        let bounds = this.getBounds( current );
        return pixel.minus(
            new Point(this.margins.left, this.margins.top)
        ).divide(
            this.containerInnerSize.x / bounds.width
        ).plus(
            bounds.getTopLeft()
        );
    }

    /**
     * Convert pixel coordinates to viewport coordinates.
     * @param {Point} pixel Pixel coordinates
     * @param {Boolean} [current=false] - Pass true for the current location;
     * defaults to false (target location).
     * @returns {Point}
     */
    pointFromPixel (pixel, current) {
        return this.pointFromPixelNoRotate(pixel, current).rotate(
            -this.getRotation(),
            this.getCenter(true)
        );
    }

    // private
    viewportToImageDelta ( viewerX, viewerY ) {
        let scale = this.contentBoundsNoRotate.width;
        return new Point(
            viewerX * this.contentSizeNoRotate.x / scale,
            viewerY * this.contentSizeNoRotate.x / scale);
    }

    /**
     * Translates from XLviewer viewer coordinate system to image coordinate system.
     * This method can be called either by passing X,Y coordinates or an
     * Point
     * Note: not accurate with multi-image; use TiledImage.viewportToImageCoordinates instead.
     * @function
     * @param {(Point|Number)} viewerX either a point or the X
     * coordinate in viewport coordinate system.
     * @param {Number} [viewerY] Y coordinate in viewport coordinate system.
     * @return {Point} a point representing the coordinates in the image.
     */
    viewportToImageCoordinates (viewerX, viewerY) {
        if (viewerX instanceof Point) {
            //they passed a point instead of individual components
            return this.viewportToImageCoordinates(viewerX.x, viewerX.y);
        }

        if (this.viewer && this.viewer.manager.getItemCount() > 1) {
            this.log.e('[Viewport.viewportToImageCoordinates] is not accurate with multi-image; use TiledImage.viewportToImageCoordinates instead.');
        }

        return this.viewportToImageDelta(
            viewerX - this.contentBoundsNoRotate.x,
            viewerY - this.contentBoundsNoRotate.y);
    }

    // private
    _imageToViewportDelta ( imageX, imageY ) {
        let scale = this.contentBoundsNoRotate.width;
        return new Point(
            imageX / this.contentSizeNoRotate.x * scale,
            imageY / this.contentSizeNoRotate.x * scale);
    }

    /**
     * Translates from image coordinate system to XLviewer viewer coordinate system
     * This method can be called either by passing X,Y coordinates or an
     * Point
     * Note: not accurate with multi-image; use TiledImage.imageToViewportCoordinates instead.
     * @function
     * @param {(Point | Number)} imageX the point or the
     * X coordinate in image coordinate system.
     * @param {Number} [imageY] Y coordinate in image coordinate system.
     * @return {Point} a point representing the coordinates in the viewport.
     */
    imageToViewportCoordinates (imageX, imageY) {
        if (imageX instanceof Point) {
            //they passed a point instead of individual components
            return this.imageToViewportCoordinates(imageX.x, imageX.y);
        }

        if (this.viewer && this.viewer.manager.getItemCount() > 1) {
            this.log.e('[Viewport.imageToViewportCoordinates] is not accurate with multi-image; use TiledImage.imageToViewportCoordinates instead.');
        }

        let point = this._imageToViewportDelta(imageX, imageY);
        point.x += this.contentBoundsNoRotate.x;
        point.y += this.contentBoundsNoRotate.y;
        return point;
    }

    /**
     * Translates from a rectangle which describes a portion of the image in
     * pixel coordinates to XLviewer viewport rectangle coordinates.
     * This method can be called either by passing X,Y,width,height or an
     * Rect
     * Note: not accurate with multi-image; use TiledImage.imageToViewportRectangle instead.
     * @function
     * @param {(Rect | Number)} imageX the rectangle or the X
     * coordinate of the top left corner of the rectangle in image coordinate system.
     * @param {Number} [imageY] the Y coordinate of the top left corner of the rectangle
     * in image coordinate system.
     * @param {Number} [pixelWidth] the width in pixel of the rectangle.
     * @param {Number} [pixelHeight] the height in pixel of the rectangle.
     */
    imageToViewportRectangle (imageX, imageY, pixelWidth, pixelHeight) {
        let rect = imageX;
        if (!(rect instanceof Rect)) {
            //they passed individual components instead of a rectangle
            rect = new Rect(imageX, imageY, pixelWidth, pixelHeight);
        }

        let coordA = this.imageToViewportCoordinates(rect.x, rect.y);
        let coordB = this._imageToViewportDelta(rect.width, rect.height);
        return new Rect(
            coordA.x,
            coordA.y,
            coordB.x,
            coordB.y,
            rect.rotation
        );
    }

    /**
     * Translates from a rectangle which describes a portion of
     * the viewport in point coordinates to image rectangle coordinates.
     * This method can be called either by passing X,Y,width,height or an
     * Rect
     * Note: not accurate with multi-image; use TiledImage.viewportToImageRectangle instead.
     * @function
     * @param {(Rect | Number)} viewerX either a rectangle or
     * the X coordinate of the top left corner of the rectangle in viewport
     * coordinate system.
     * @param {Number} [viewerY] the Y coordinate of the top left corner of the rectangle
     * in viewport coordinate system.
     * @param {Number} [pointWidth] the width of the rectangle in viewport coordinate system.
     * @param {Number} [pointHeight] the height of the rectangle in viewport coordinate system.
     */
    viewportToImageRectangle (viewerX, viewerY, pointWidth, pointHeight) {
        let rect = viewerX;
        if (!(rect instanceof Rect)) {
            //they passed individual components instead of a rectangle
            rect = new Rect(viewerX, viewerY, pointWidth, pointHeight);
        }

        let coordA = this.viewportToImageCoordinates(rect.x, rect.y);
        let coordB = this.viewportToImageDelta(rect.width, rect.height);
        return new Rect(
            coordA.x,
            coordA.y,
            coordB.x,
            coordB.y,
            rect.rotation
        );
    }

    /**
     * Convert pixel coordinates relative to the viewer element to image
     * coordinates.
     * Note: not accurate with multi-image.
     * @param {Point} pixel
     * @returns {Point}
     */
    viewerElementToImageCoordinates ( pixel ) {
        let point = this.pointFromPixel( pixel, true );
        return this.viewportToImageCoordinates( point );
    }

    /**
     * Convert pixel coordinates relative to the image to
     * viewer element coordinates.
     * Note: not accurate with multi-image.
     * @param {Point} pixel
     * @returns {Point}
     */
    imageToViewerElementCoordinates ( pixel ) {
        let point = this.imageToViewportCoordinates( pixel );
        return this.pixelFromPoint( point, true );
    }

    /**
     * Convert pixel coordinates relative to the window to image coordinates.
     * Note: not accurate with multi-image.
     * @param {Point} pixel
     * @returns {Point}
     */
    windowToImageCoordinates ( pixel ) {
        let viewerCoordinates = pixel.minus(
            utils.getElementPosition( this.viewer.element ));
        return this.viewerElementToImageCoordinates( viewerCoordinates );
    }

    /**
     * Convert image coordinates to pixel coordinates relative to the window.
     * Note: not accurate with multi-image.
     * @param {Point} pixel
     * @returns {Point}
     */
    imageToWindowCoordinates ( pixel ) {
        let viewerCoordinates = this.imageToViewerElementCoordinates( pixel );
        return viewerCoordinates.plus(
            utils.getElementPosition( this.viewer.element ));
    }

    /**
     * Convert pixel coordinates relative to the viewer element to viewport
     * coordinates.
     * @param {Point} pixel
     * @returns {Point}
     */
    viewerElementToViewportCoordinates ( pixel ) {
        return this.pointFromPixel( pixel, true );
    }

    /**
     * Convert viewport coordinates to pixel coordinates relative to the
     * viewer element.
     * @param {Point} point
     * @returns {Point}
     */
    viewportToViewerElementCoordinates ( point ) {
        return this.pixelFromPoint( point, true );
    }

    /**
     * Convert a rectangle in pixel coordinates relative to the viewer element
     * to viewport coordinates.
     * @param {Rect} rectangle the rectangle to convert
     * @returns {Rect} the converted rectangle
     */
    viewerElementToViewportRectangle (rectangle) {
        return Rect.fromSummits(
            this.pointFromPixel(rectangle.getTopLeft(), true),
            this.pointFromPixel(rectangle.getTopRight(), true),
            this.pointFromPixel(rectangle.getBottomLeft(), true)
        );
    }

    /**
     * Convert a rectangle in viewport coordinates to pixel coordinates relative
     * to the viewer element.
     * @param {Rect} rectangle the rectangle to convert
     * @returns {Rect} the converted rectangle
     */
    viewportToViewerElementRectangle (rectangle) {
        return Rect.fromSummits(
            this.pixelFromPoint(rectangle.getTopLeft(), true),
            this.pixelFromPoint(rectangle.getTopRight(), true),
            this.pixelFromPoint(rectangle.getBottomLeft(), true)
        );
    }

    /**
     * Convert pixel coordinates relative to the window to viewport coordinates.
     * @param {Point} pixel
     * @returns {Point}
     */
    windowToViewportCoordinates ( pixel ) {
        let viewerCoordinates = pixel.minus(
            utils.getElementPosition( this.viewer.element ));
        return this.viewerElementToViewportCoordinates( viewerCoordinates );
    }

    /**
     * Convert viewport coordinates to pixel coordinates relative to the window.
     * @param {Point} point
     * @returns {Point}
     */
    viewportToWindowCoordinates ( point ) {
        let viewerCoordinates = this.viewportToViewerElementCoordinates( point );
        return viewerCoordinates.plus(
            utils.getElementPosition( this.viewer.element ));
    }

    /**
     * Convert a viewport zoom to an image zoom.
     * Image zoom: ratio of the original image size to displayed image size.
     * 1 means original image size, 0.5 half size...
     * Viewport zoom: ratio of the displayed image's width to viewport's width.
     * 1 means identical width, 2 means image's width is twice the viewport's width...
     * Note: not accurate with multi-image.
     * @function
     * @param {Number} viewportZoom The viewport zoom
     * target zoom.
     * @returns {Number} imageZoom The image zoom
     */
    viewportToImageZoom ( viewportZoom ) {
        if (this.viewer && this.viewer.manager.getItemCount() > 1) {
            this.log.e('[Viewport.viewportToImageZoom] is not accurate with multi-image.');
        }

        let imageWidth = this.contentSizeNoRotate.x;
        let containerWidth = this.containerInnerSize.x;
        let scale = this.contentBoundsNoRotate.width;
        let viewportToImageZoomRatio = (containerWidth / imageWidth) * scale;
        return viewportZoom * viewportToImageZoomRatio;
    }

    /**
     * Convert an image zoom to a viewport zoom.
     * Image zoom: ratio of the original image size to displayed image size.
     * 1 means original image size, 0.5 half size...
     * Viewport zoom: ratio of the displayed image's width to viewport's width.
     * 1 means identical width, 2 means image's width is twice the viewport's width...
     * Note: not accurate with multi-image.
     * @function
     * @param {Number} imageZoom The image zoom
     * target zoom.
     * @returns {Number} viewportZoom The viewport zoom
     */
    imageToViewportZoom ( imageZoom ) {
        if (this.viewer && this.viewer.manager.getItemCount() > 1) {
            this.log.e('[Viewport.imageToViewportZoom] is not accurate with multi-image.');
        }

        let imageWidth = this.contentSizeNoRotate.x;
        let containerWidth = this.containerInnerSize.x;
        let scale = this.contentBoundsNoRotate.width;
        let viewportToImageZoomRatio = (imageWidth / containerWidth) / scale;
        return imageZoom * viewportToImageZoomRatio;
    }
}

export default Viewport;