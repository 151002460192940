class ImageRecord {
    image = null;
    renderedContext = null;
    tiles = null;
    constructor(options) {
        this.image = options.image;
        this.tiles = [];
    }

    destroy() {
        this.image = null;
        this.renderedContext = null;
        this.tiles = null;
    }

    getImage() {
        return this.image;
    }

    getRenderedContext() {
        if (!this.renderedContext) {
            let canvas = document.createElement( 'canvas' );
            canvas.width = this.image.width;
            canvas.height = this.image.height;
            this.renderedContext = canvas.getContext('2d');
            this.renderedContext.drawImage( this.image, 0, 0 );
            //since we are caching the prerendered image on a canvas
            //allow the image to not be held in memory
            this.image = null;
        }
        return this.renderedContext;
    }


    addTile(tile) {
        this.tiles.push(tile);
    }

    removeTile(tile) {
        for (let i = 0; i < this.tiles.length; i++) {
            if (this.tiles[i] === tile) {
                this.tiles.splice(i, 1);
                return;
            }
        }

       throw new Error('[ImageRecord.removeTile] trying to remove unknown tile ' + tile.toString());
    }

    getTileCount() {
        return this.tiles.length;
    }
}

export default ImageRecord;