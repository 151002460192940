import Point from "./Point";
import Rect from "./Rect";
import Tween from "./Tween";
import Logger from './Logger'
import EventDispatcher from './EventDispatcher'
import xmlToJSON from './xmlToJSON'
const Aggregation = (baseClass, ...mixins) => {
    class base extends baseClass {
        constructor (...args) {
            super(...args);
            mixins.forEach((mixin) => {
                copyProps(this,(new mixin));
            });
        }
    }
    let copyProps = (target, source) => {  // this function copies all properties and symbols, filtering out some special ones
        Object.getOwnPropertyNames(source)
            .concat(Object.getOwnPropertySymbols(source))
            .forEach((prop) => {
                if (!prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/))
                    Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop));
            })
    }
    mixins.forEach((mixin) => { // outside contructor() to allow aggregation(A,B,C).staticFunction() to be called etc.
        copyProps(base.prototype, mixin.prototype);
        copyProps(base, mixin);
    });
    return base;
}
/**
 * apply styles to a dom element
 * @param element {HTMLElement} - DOM element to be styled
 * @param styleMap {Object} - the properties of this object are applied to the ìgiven DOM element
 */
const applyStyle = (element, styleMap) => {
    let k;
    for(k in styleMap) {
        element.style[k] = styleMap[k];
    }
}

const clamp = (value, min, max) => {
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value
}

const delegate = ( object, method ) => {
    return () => {
        let args = arguments;
        if ( args === undefined ){
            args = [];
        }
        return method.apply( object, args );
    };
}


const getElement = ( element ) => {
    if ( typeof ( element ) == "string" ) {
        element = document.getElementById( element );
    }
    return element;
}

/**
 * Determines the position of the upper-left corner of the element adjusted for current page and/or element scroll..
 */
const getElementOffset = ( element ) => {
    element = getElement( element );
    let doc = element && element.ownerDocument, docElement, win, boundingRect = { top: 0, left: 0 };
    if ( !doc ) {
        return new Point();
    }
    docElement = doc.documentElement;
    if ( typeof element.getBoundingClientRect !== typeof undefined ) {
        boundingRect = element.getBoundingClientRect();
    }
    win = ( doc === doc.window ) ? doc : ( doc.nodeType === 9 ) ? doc.defaultView || doc.parentWindow : false;
    return new Point(
        boundingRect.left + ( win.pageXOffset || docElement.scrollLeft ) - ( docElement.clientLeft || 0 ),
        boundingRect.top + ( win.pageYOffset || docElement.scrollTop ) - ( docElement.clientTop || 0 )
    );
};
/**
 * Determines the height and width of the given element.
 */
const getElementSize = ( element ) => {
    element = getElement( element );
    return new Point(
        element.clientWidth,
        element.clientHeight
    );
};

const getOffsetParent = (element) => {
    let positionStyle = element.style.position;
    let skipStatic = positionStyle === "fixed" || positionStyle === "absolute";
    for (let parent = element.parentNode;parent && parent !== element;parent = parent.parentNode) {
        positionStyle = parent.style.position;
        skipStatic = skipStatic && positionStyle === "static" && parent !== element;
        if (!skipStatic && (parent.scrollWidth > parent.clientWidth || parent.scrollHeight > parent.clientHeight || positionStyle === "fixed" || positionStyle === "absolute" || positionStyle == "relative")) {
            return parent;
        }
    }
    return null;
}

/**
 * Determines the position of the upper-left corner of the element.
 */
const getElementPosition = ( element ) => {
    let result = new Point(),
        isFixed,
        offsetParent;

    element      = getElement( element );
    offsetParent = getOffsetParent( element );

    while ( offsetParent ) {

        result.x += element.offsetLeft;
        result.y += element.offsetTop;

        if ( isFixed ) {
            result = result.plus( getPageScroll() );
        }

        element = offsetParent;
        offsetParent = getOffsetParent( element );
    }

    return result;
}

/**
 * Determines the page's current scroll position.
 */
const getPageScroll = () => {
    return new Point(
        window.pageXOffset,
        window.pageYOffset
    )
}
/**
 * Gets the position of the mouse on the screen for a given event.

 */
const getMousePosition = ( event ) => {
    const result = new Point();
    result.x = event.pageX;
    result.y = event.pageY;
    return result;
}

/**
 * @param tagName
 * @returns {HTMLElement}
 */
const makeNeutralElement = ( tagName ) => {
    let element = document.createElement( tagName ),
        style   = element.style;
    style.background = "transparent none";
    style.border     = "none";
    style.margin     = "0px";
    style.padding    = "0px";
    style.position   = "static";
    return element;
}

/**
 * Parses an XML string into a DOM Document.
 * @throws {Error} if browser doesn't support XML DOM
 */
const parseXml = ( string ) => {
    if ( window.DOMParser ) {
        let parser = new DOMParser();
        return parser.parseFromString( string, "text/xml" );
    }
    throw new Error( "Browser doesn't support XML DOM." );
}

const setElementOpacity = ( element, opacity ) => {
    element = getElement( element );
    element.style.opacity = opacity < 1 ? opacity : "";
}
const setElementTouchActionNone = ( element ) => {
    element = getElement( element );
    element.style.touchAction = 'none';
}

/**
 * _getSafeElemSize is like getElementSize(), but refuses to return 0 for x or y,
 * which was causing some calling operations to return NaN.
 */
function getSafeElemSize (oElement) {
    oElement = getElement( oElement );
    return new Point(
        (oElement.clientWidth === 0 ? 1 : oElement.clientWidth),
        (oElement.clientHeight === 0 ? 1 : oElement.clientHeight)
    );
}


/**
 * Adds an event listener for the given element, eventName and handler.
 * @function
 * @param {HTMLElement|Document|Window} element
 * @param {String} eventName
 * @param {Function} handler
 * @param {Boolean} [useCapture]
 */
const bind = ( element, eventName, handler, useCapture ) => {
    let opts = useCapture;
    if(!["click","pointerdown",'dblclick','wheel'].includes(eventName)) {
        try {
            window.addEventListener("test", null,
                Object.defineProperty({}, "passive", {
                    get: function() { opts = { passive: true, capture : useCapture }; }
                }));
        } catch(err) {}
    }
    element = getElement( element );
    element.addEventListener( eventName, handler, opts );
}


/**
 * Remove a given event listener for the given element, event type and
 * handler.
 * @function
 * @param {HTMLElement|Document|Window} element
 * @param {String} eventName
 * @param {Function} handler
 * @param {Boolean} [useCapture]
 */
const unbind = ( element, eventName, handler, useCapture ) => {
    element = getElement( element );
    element.removeEventListener( eventName, handler, useCapture );
}


/**
 * Cancels the default browser behavior had the event propagated all
 * the way up the DOM to the window object.
 * @function
 * @param {Event} [event]
 */
const cancelEvent =  ( event ) => {
    event.cancelable && event.preventDefault();
}

// True if inside an iframe, otherwise false.
const isInIframe = (function() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
})();

/**
 * Stops the propagation of the event (cross browser).
 * @param {Event} [event]
 */
const stopEvent = ( event ) => {
    event.stopPropagation();
}


const pixelDensityRatio = (function () {
    const context = document.createElement('canvas').getContext('2d');
    const devicePixelRatio = window.devicePixelRatio || 1;
    const backingStoreRatio = context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio || 1;
    return devicePixelRatio / backingStoreRatio;
}())

/**
 * Wraps the given element in a nest of divs so that the element can
 * be easily centered using CSS tables
 * @function
 * @param {Element|String} element
 * @returns {Element} outermost wrapper element
 */
const makeCenteredNode = ( element ) => {
    // Convert a possible ID to an actual HTMLElement
    element = getElement( element );
    let wrap = makeNeutralElement( 'div' )
    applyStyle(wrap, {
        "display" : "flex",
        "justify-content" : "center",
        "align-items": "center",
        "width" : "100%"
    })
    wrap.appendChild(element);
    return wrap;
}

const utils = {
    applyStyle,
    clamp,
    delegate,
    getElement,
    getElementOffset,
    getElementPosition,
    getElementSize,
    getMousePosition,
    getOffsetParent,
    getPageScroll,
    getSafeElemSize,
    makeNeutralElement,
    parseXml,
    setElementOpacity,
    setElementTouchActionNone,
    bind,
    unbind,
    cancelEvent,
    stopEvent,
    isInIframe,
    pixelDensityRatio,
    xmlToJSON,
    makeCenteredNode
}

export {
    Aggregation,
    EventDispatcher,
    Logger,
    Point,
    Rect,
    Tween,
};

export default utils;