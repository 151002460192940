import defaults from "../defaults";
import PouchDB from "pouchdb";

const db = PouchDB('xlimage')

class ImageJob {
    timeout = defaults.timeout;
    jobId = null;
    objectUrl = null;
    /**
     * Image object which will contain downloaded image.
     * @member {HTMLImageElement} image
     * @memberof XLviewer.ImageJob#
     */
    image = null;
    errorMsg = null;
    enableCORS = false;
    src;
    callback;
    constructor(options) {
        Object.assign(this, options);
    }

    start(){
        this.image = new Image();
        if ( this.enableCORS !== false ) {
            this.image.crossOrigin = this.enableCORS;
        }

        this.image.onload = () => {
            this.finish( true );
        };

        this.image.onabort = this.image.onerror = () => {
            this.errorMsg = "Image load aborted";
            this.finish( false );
        };

        this.jobId = window.setTimeout( () => {
            this.errorMsg = "Image load exceeded timeout";
            this.finish( false );
        }, this.timeout);

        let setSrc = (blob) => {
            this.objectUrl = URL.createObjectURL(blob)
            this.image.src = this.objectUrl
        };

        db.getAttachment(this.src, 'tile', (err, blob) => {
            if (err) {
                fetch(this.src).then(res => {
                    return res.blob();
                }).then(blob => {
                    db.putAttachment(this.src, 'tile', blob, blob.type, (err, res) => {
                        if (err) { return console.log("fail to save blob:", err); }
                    })
                    setSrc(blob);
                })
                return;
            }
            console.log("load from cache")
            setSrc(blob);
            // handle result
        });


    }

    finish( successful ) {
        this.image.onload = this.image.onerror = this.image.onabort = null;
        if (!successful) {
            this.image = null;
            if(this.objectUrl) {
                URL.revokeObjectURL(this.objectUrl);
                this.objectUrl = null;
            }
        }

        if ( this.jobId ) {
            window.clearTimeout( this.jobId );
        }

        this.callback( this );
    }
}

export default ImageJob