import {EventDispatcher, Rect, Point, Tween, Logger} from '../utils'
import Tile from './Tile'
import utils from '../utils'
import defaults from "../defaults";

const Placement = {
    CENTER:       0,
    TOP_LEFT:     1,
    TOP:          2,
    TOP_RIGHT:    3,
    RIGHT:        4,
    BOTTOM_RIGHT: 5,
    BOTTOM:       6,
    BOTTOM_LEFT:  7,
    LEFT:         8,
    properties: {
        0: {
            isLeft: false,
            isHorizontallyCentered: true,
            isRight: false,
            isTop: false,
            isVerticallyCentered: true,
            isBottom: false
        },
        1: {
            isLeft: true,
            isHorizontallyCentered: false,
            isRight: false,
            isTop: true,
            isVerticallyCentered: false,
            isBottom: false
        },
        2: {
            isLeft: false,
            isHorizontallyCentered: true,
            isRight: false,
            isTop: true,
            isVerticallyCentered: false,
            isBottom: false
        },
        3: {
            isLeft: false,
            isHorizontallyCentered: false,
            isRight: true,
            isTop: true,
            isVerticallyCentered: false,
            isBottom: false
        },
        4: {
            isLeft: false,
            isHorizontallyCentered: false,
            isRight: true,
            isTop: false,
            isVerticallyCentered: true,
            isBottom: false
        },
        5: {
            isLeft: false,
            isHorizontallyCentered: false,
            isRight: true,
            isTop: false,
            isVerticallyCentered: false,
            isBottom: true
        },
        6: {
            isLeft: false,
            isHorizontallyCentered: true,
            isRight: false,
            isTop: false,
            isVerticallyCentered: false,
            isBottom: true
        },
        7: {
            isLeft: true,
            isHorizontallyCentered: false,
            isRight: false,
            isTop: false,
            isVerticallyCentered: false,
            isBottom: true
        },
        8: {
            isLeft: true,
            isHorizontallyCentered: false,
            isRight: false,
            isTop: false,
            isVerticallyCentered: true,
            isBottom: false
        }
    }
}


class TiledImage extends EventDispatcher {
    log;
    quality;
    normHeight;
    contentAspectX;
    #tileCache;
    #drawer;
    #imageLoader;
    //#clip;
    #xSpring;
    #ySpring;
    #scaleSpring;
    #worldWidthCurrent;
    #worldHeightCurrent;
    #worldWidthTarget;
    #worldHeightTarget;

    viewer;
    tilesMatrix = {}
    coverage = {}; // A '3d' dictionary [level][x][y] --> Boolean.
    lastDrawn = []; // An unordered list of Tiles drawn last frame.
    lastResetTime = 0; // Last time for which the tiledImage was reset.
    #midDraw = false; // Is the tiledImage currently updating the viewport?
    #needsDraw = true; // Does the tiledImage need to update the viewport again?
    #hasOpaqueTile = false; // Do we have even one fully opaque tile?
    //configurable settings
    tweenSmoothness = defaults.tweenSmoothness;
    tweenDuration = defaults.tweenDuration;
    underZoomRatio = defaults.underZoomRatio;
    wrapHorizontal = defaults.wrapHorizontal;
    wrapVertical = defaults.wrapVertical;
    noWaitForRendering = defaults.noWaitForRendering;
    blendDuration = defaults.blendDuration;
    alwaysBlend = defaults.alwaysBlend;
    minPixelRatio = defaults.minPixelRatio;
    overZoomRatioForSmoothingTile = defaults.overZoomRatioForSmoothingTile;
    debug = defaults.debug;
    enableCORS = defaults.enableCORS;
    placeholderFillStyle = defaults.placeholderFillStyle;
    opacity = defaults.opacity;
    blendingType = defaults.blendingType

    /**
     * Handles rendering of tiles. A new instance is created for each TileSource opened.
     */
    constructor(options) {
        super();
        this.log = new Logger({ logLevel : options.logLevel })
        TiledImage.#validateOptions(options);

        this.quality = options.quality || defaults.quality;
        this.#tileCache = options.tileCache;
        delete options.tileCache;

        this.#drawer = options.drawer;
        delete options.drawer;

        this.#imageLoader = options.imageLoader;
        delete options.imageLoader;
        /*
        this.#clip = options.clip.clone();
        delete options.clip;
         */
        const x = options.x || 0;
        delete options.x;
        const y = options.y || 0;
        delete options.y;

        // Ratio of zoomable image height to width.
        this.normHeight = options.source.dimensions.y / options.source.dimensions.x;
        this.contentAspectX = options.source.dimensions.x / options.source.dimensions.y;

        let scale = 1;
        if ( options.width ) {
            scale = options.width;
            delete options.width;

            if ( options.height ) {
                console.error( "specifying both width and height to a tiledImage is not supported" );
                delete options.height;
            }
        } else if ( options.height ) {
            scale = options.height / this.normHeight;
            delete options.height;
        }

        let fitBounds = options.fitBounds;
        delete options.fitBounds;
        let fitBoundsPlacement = options.fitBoundsPlacement || Placement.CENTER;
        delete options.fitBoundsPlacement;

        Object.assign(this, options);


        this.#xSpring = new Tween({
            initial: x,
            tweenSmoothness: this.tweenSmoothness,
            tweenDuration: this.tweenDuration
        });

        this.#ySpring = new Tween({
            initial: y,
            tweenSmoothness: this.tweenSmoothness,
            tweenDuration: this.tweenDuration
        });

        this.#scaleSpring = new Tween({
            initial: scale,
            tweenSmoothness: this.tweenSmoothness,
            tweenDuration: this.tweenDuration
        });

        this.#updateForScale();

        if (fitBounds) {
            this.fitBounds(fitBounds, fitBoundsPlacement, true);
        }
    }


    static #validateOptions( options ) {
        if(!options.tileCache) {
            throw new Error('[TiledImage] options.tileCache is required');
        }
        if(!options.drawer) {
            throw new Error('[TiledImage] options.drawer is required');
        }
        if(!options.viewer) {
            throw new Error('[TiledImage] options.viewer is required');
        }
        if(!options.imageLoader) {
            throw new Error('[TiledImage] options.imageLoader is required');
        }
        if(!options.source) {
            throw new Error('[TiledImage] options.source is required');
        }
        /*
        if(!options.clip || !(options.clip instanceof Rect)) {
            throw new Error('[TiledImage] options.source is required');
        }
         */
    }
    
    _drawingHandler() {
        return (args) => {
            this.viewer.dispatchEvent('tileDrawing', Object.assign({
                tiledImage: this
            }, args));
        }
        /**
         * This event is fired just before the tile is drawn giving the application a chance to alter the image.
         *
         * NOTE: This event is only fired when the drawer is using a &lt;canvas&gt;.
         *
         * @event tile-drawing
         * @memberof Viewer
         * @type {object}
         * @property {Viewer} eventSource - A reference to the Viewer which dispatchd the event.
         * @property {Tile} tile - The Tile being drawn.
         * @property {TiledImage} tiledImage - Which TiledImage is being drawn.
         * @property {Tile} context - The HTML canvas context being drawn into.
         * @property {Tile} rendered - The HTML canvas context containing the tile imagery.
         * @property {?Object} userData - Arbitrary subscriber-defined object.
         */
    }

    needsDraw() {
        return this.#needsDraw;
    }

    /**
     * Clears all tiles and triggers an update on the next call to
     * {@link TiledImage#update}.
     */
    reset() {
        this.#tileCache.clearTilesFor(this);
        this.lastResetTime = Date.now();
        this.#needsDraw = true;
    }

    /**
     * Updates the TiledImage's bounds, animating if needed.
     * @returns {Boolean} Whether the TiledImage animated.
     */
    update() {
        let oldX = this.#xSpring.current.value;
        let oldY = this.#ySpring.current.value;
        let oldScale = this.#scaleSpring.current.value;

        this.#xSpring.update();
        this.#ySpring.update();
        this.#scaleSpring.update();

        if (this.#xSpring.current.value !== oldX || this.#ySpring.current.value !== oldY ||
            this.#scaleSpring.current.value !== oldScale) {
            this.#updateForScale();
            this.#needsDraw = true;
            return true;
        }

        return false;
    }

    /**
     * Draws the TiledImage to its Drawer.
     */
    draw() {
        if (this.opacity !== 0) {
            this.#midDraw = true;
            this.updateViewport();
            this.#midDraw = false;
        }
    }

    /**
     * Destroy the TiledImage (unload current loaded tiles).
     */
    destroy() {
        this.reset();
    }

    /**
     * @returns {Rect} This TiledImage's bounds in viewport coordinates.
     * @param {Boolean} [current=false] - Pass true for the current location; false for target location.
     */
    getBounds(current) {
        if (current) {
            return new Rect( this.#xSpring.current.value, this.#ySpring.current.value,
                this.#worldWidthCurrent, this.#worldHeightCurrent );
        }

        return new Rect( this.#xSpring.target.value, this.#ySpring.target.value,
            this.#worldWidthTarget, this.#worldHeightTarget );
    }

    /**
     * @returns {Point} This TiledImage's content size, in original pixels.
     */
    getContentSize() {
        return new Point(this.source.dimensions.x, this.source.dimensions.y);
    }

    // private
    #viewportToImageDelta( viewerX, viewerY, current ) {
        let scale = (current ? this.#scaleSpring.current.value : this.#scaleSpring.target.value);
        return new Point(viewerX * (this.source.dimensions.x / scale),
            viewerY * ((this.source.dimensions.y * this.contentAspectX) / scale));
    }

    /**
     * Translates from XLviewer viewer coordinate system to image coordinate system.
     * This method can be called either by passing X,Y coordinates or an {@link Point}.
     * @param {Number|Point} viewerX - The X coordinate or point in viewport coordinate system.
     * @param {Number|Boolean} [viewerY] - The Y coordinate in viewport coordinate system.
     * @param {Boolean} [current=false] - Pass true to use the current location; false for target location.
     * @return {Point} A point representing the coordinates in the image.
     */
    viewportToImageCoordinates( viewerX, viewerY, current ) {
        if (viewerX instanceof Point) {
            //they passed a point instead of individual components
            current = viewerY;
            viewerY = viewerX.y;
            viewerX = viewerX.x;
        }

        if (current) {
            return this.#viewportToImageDelta(viewerX - this.#xSpring.current.value,
                viewerY - this.#ySpring.current.value);
        }

        return this.#viewportToImageDelta(viewerX - this.#xSpring.target.value,
            viewerY - this.#ySpring.target.value);
    }

    // private
    #imageToViewportDelta( imageX, imageY, current ) {
        let scale = (current ? this.#scaleSpring.current.value : this.#scaleSpring.target.value);
        return new Point((imageX / this.source.dimensions.x) * scale,
            (imageY / this.source.dimensions.y / this.contentAspectX) * scale);
    }

    /**
     * Translates from image coordinate system to XLviewer viewer coordinate system
     * This method can be called either by passing X,Y coordinates or an {@link Point}.
     * @param {Number|Point} imageX - The X coordinate or point in image coordinate system.
     * @param {Number|Boolean} [imageY] - The Y coordinate in image coordinate system.
     * @param {Boolean} [current=false] - Pass true to use the current location; false for target location.
     * @return {Point} A point representing the coordinates in the viewport.
     */
    imageToViewportCoordinates( imageX, imageY, current ) {
        if (imageX instanceof Point) {
            //they passed a point instead of individual components
            current = imageY;
            imageY = imageX.y;
            imageX = imageX.x;
        }
        let point = this.#imageToViewportDelta(imageX, imageY);
        if (current) {
            point.x += this.#xSpring.current.value;
            point.y += this.#ySpring.current.value;
        } else {
            point.x += this.#xSpring.target.value;
            point.y += this.#ySpring.target.value;
        }
        return point;
    }

    /**
     * Translates from a rectangle which describes a portion of the image in
     * pixel coordinates to XLviewer viewport rectangle coordinates.
     * This method can be called either by passing X,Y,width,height or an {@link Rect}.
     * @param {Number|Rect} imageX - The left coordinate or rectangle in image coordinate system.
     * @param {Number|Boolean} [imageY] - The top coordinate in image coordinate system.
     * @param {Number} [pixelWidth] - The width in pixel of the rectangle.
     * @param {Number} [pixelHeight] - The height in pixel of the rectangle.
     * @param {Boolean} [current=false] - Pass true to use the current location; false for target location.
     * @return {Rect} A rect representing the coordinates in the viewport.
     */
    imageToViewportRectangle( imageX, imageY, pixelWidth, pixelHeight, current ) {
        let rect = imageX;
        if (rect instanceof Rect) {
            //they passed a rect instead of individual components
            current = imageY;
        } else {
            rect = new Rect(imageX, imageY, pixelWidth, pixelHeight);
        }
        const coordsA = this.imageToViewportCoordinates(rect.getTopLeft(), current);
        const coordsB = this.#imageToViewportDelta(rect.width, rect.height, current);
        return new Rect(
            coordsA.x,
            coordsA.y,
            coordsB.x,
            coordsB.y,
            rect.rotation
        );
    }

    /**
     * Translates from a rectangle which describes a portion of
     * the viewport in point coordinates to image rectangle coordinates.
     * This method can be called either by passing X,Y,width,height or an {@link Rect}.
     * @param {Number|Rect} viewerX - The left coordinate or rectangle in viewport coordinate system.
     * @param {Number|Boolean} [viewerY] - The top coordinate in viewport coordinate system.
     * @param {Number} [pointWidth] - The width in viewport coordinate system.
     * @param {Number} [pointHeight] - The height in viewport coordinate system.
     * @param {Boolean} [current=false] - Pass true to use the current location; false for target location.
     * @return {Rect} A rect representing the coordinates in the image.
     */
    viewportToImageRectangle( viewerX, viewerY, pointWidth, pointHeight, current ) {
        let rect = viewerX;
        if (viewerX instanceof Rect) {
            //they passed a rect instead of individual components
            current = viewerY;
        } else {
            rect = new Rect(viewerX, viewerY, pointWidth, pointHeight);
        }

        let coordsA = this.viewportToImageCoordinates(rect.getTopLeft(), current);
        let coordsB = this.#viewportToImageDelta(rect.width, rect.height, current);

        return new Rect(
            coordsA.x,
            coordsA.y,
            coordsB.x,
            coordsB.y,
            rect.rotation
        );
    }

    /**
     * Convert pixel coordinates relative to the viewer element to image
     * coordinates.
     * @param {Point} pixel
     * @returns {Point}
     */
    viewerElementToImageCoordinates( pixel ) {
        let point = this.viewport.pointFromPixel( pixel, true );
        return this.viewportToImageCoordinates( point );
    }

    /**
     * Convert pixel coordinates relative to the image to
     * viewer element coordinates.
     * @param {Point} pixel
     * @returns {Point}
     */
    imageToViewerElementCoordinates( pixel ) {
        let point = this.imageToViewportCoordinates( pixel );
        return this.viewport.pixelFromPoint( point, true );
    }

    /**
     * Convert pixel coordinates relative to the window to image coordinates.
     * @param {Point} pixel
     * @returns {Point}
     */
    windowToImageCoordinates( pixel ) {
        let viewerCoordinates = pixel.minus(
            utils.getElementPosition( this.viewer.element ));
        return this.viewerElementToImageCoordinates( viewerCoordinates );
    }

    /**
     * Convert image coordinates to pixel coordinates relative to the window.
     * @param {Point} pixel
     * @returns {Point}
     */
    imageToWindowCoordinates( pixel ) {
        let viewerCoordinates = this.imageToViewerElementCoordinates( pixel );
        return viewerCoordinates.plus(
            utils.getElementPosition( this.viewer.element ));
    }

    /**
     * Convert a viewport zoom to an image zoom.
     * Image zoom: ratio of the original image size to displayed image size.
     * 1 means original image size, 0.5 half size...
     * Viewport zoom: ratio of the displayed image's width to viewport's width.
     * 1 means identical width, 2 means image's width is twice the viewport's width...
     * @function
     * @param {Number} viewportZoom The viewport zoom
     * @returns {Number} imageZoom The image zoom
     */
    viewportToImageZoom( viewportZoom ) {
        let ratio = this.#scaleSpring.current.value *
            this.viewport.containerInnerSize.x / this.source.dimensions.x;
        return ratio * viewportZoom ;
    }

    /**
     * Convert an image zoom to a viewport zoom.
     * Image zoom: ratio of the original image size to displayed image size.
     * 1 means original image size, 0.5 half size...
     * Viewport zoom: ratio of the displayed image's width to viewport's width.
     * 1 means identical width, 2 means image's width is twice the viewport's width...
     * Note: not accurate with multi-image.
     * @function
     * @param {Number} imageZoom The image zoom
     * @returns {Number} viewportZoom The viewport zoom
     */
    imageToViewportZoom( imageZoom ) {
        let ratio = this.#scaleSpring.current.value *
            this.viewport.containerInnerSize.x / this.source.dimensions.x;
        return imageZoom / ratio;
    }

    /**
     * Sets the TiledImage's position in the manager.
     * @param {Point} position - The new position, in viewport coordinates.
     * @param {Boolean} [immediately=false] - Whether to animate to the new position or snap immediately.
     * @fires TiledImage.event:bounds-change
     */
    setPosition(position, immediately) {
        let sameTarget = (this.#xSpring.target.value === position.x &&
            this.#ySpring.target.value === position.y);

        if (immediately) {
            if (sameTarget && this.#xSpring.current.value === position.x &&
                this.#ySpring.current.value === position.y) {
                return;
            }

            this.#xSpring.resetTo(position.x);
            this.#ySpring.resetTo(position.y);
            this.#needsDraw = true;
        } else {
            if (sameTarget) {
                return;
            }

            this.#xSpring.springTo(position.x);
            this.#ySpring.springTo(position.y);
            this.#needsDraw = true;
        }

        if (!sameTarget) {
            this.#raiseBoundsChange();
        }
    }

    /**
     * Sets the TiledImage's width in the manager, adjusting the height to match based on aspect ratio.
     * @param {Number} width - The new width, in viewport coordinates.
     * @param {Boolean} [immediately=false] - Whether to animate to the new size or snap immediately.
     * @fires TiledImage.event:bounds-change
     */
    setWidth(width, immediately) {
        this.#setScale(width, immediately);
    }

    /**
     * Sets the TiledImage's height in the manager, adjusting the width to match based on aspect ratio.
     * @param {Number} height - The new height, in viewport coordinates.
     * @param {Boolean} [immediately=false] - Whether to animate to the new size or snap immediately.
     * @fires TiledImage.event:bounds-change
     */
    setHeight(height, immediately) {
        this.#setScale(height / this.normHeight, immediately);
    }

    /**
     * Positions and scales the TiledImage to fit in the specified bounds.
     * Note: this method fires TiledImage.event:bounds-change
     * twice
     * @param {Rect} bounds The bounds to fit the image into.
     * @param {Placement} [anchor=Placement.CENTER]
     * How to anchor the image in the bounds.
     * @param {Boolean} [immediately=false] Whether to animate to the new size
     * or snap immediately.
     * @fires TiledImage.event:bounds-change
     */
    fitBounds(bounds, anchor, immediately) {
        anchor = anchor || Placement.CENTER;
        let anchorProperties = Placement.properties[anchor];
        let aspectRatio = this.contentAspectX;
        let xOffset = 0;
        let yOffset = 0;
        let displayedWidthRatio = 1;
        let displayedHeightRatio = 1;
        /*
        if (this.#clip) {
            aspectRatio = this.#clip.getAspectRatio();
            displayedWidthRatio = this.#clip.width / this.source.dimensions.x;
            displayedHeightRatio = this.#clip.height / this.source.dimensions.y;
            if (bounds.getAspectRatio() > aspectRatio) {
                xOffset = this.#clip.x / this.#clip.height * bounds.height;
                yOffset = this.#clip.y / this.#clip.height * bounds.height;
            } else {
                xOffset = this.#clip.x / this.#clip.width * bounds.width;
                yOffset = this.#clip.y / this.#clip.width * bounds.width;
            }
        }
*/
        if (bounds.getAspectRatio() > aspectRatio) {
            // We will have margins on the X axis
            let height = bounds.height / displayedHeightRatio;
            let marginLeft = 0;
            if (anchorProperties.isHorizontallyCentered) {
                marginLeft = (bounds.width - bounds.height * aspectRatio) / 2;
            } else if (anchorProperties.isRight) {
                marginLeft = bounds.width - bounds.height * aspectRatio;
            }
            this.setPosition(
                new Point(bounds.x - xOffset + marginLeft, bounds.y - yOffset),
                immediately);
            this.setHeight(height, immediately);
        } else {
            // We will have margins on the Y axis
            let width = bounds.width / displayedWidthRatio;
            let marginTop = 0;
            if (anchorProperties.isVerticallyCentered) {
                marginTop = (bounds.height - bounds.width / aspectRatio) / 2;
            } else if (anchorProperties.isBottom) {
                marginTop = bounds.height - bounds.width / aspectRatio;
            }
            this.setPosition(
                new Point(bounds.x - xOffset, bounds.y - yOffset + marginTop),
                immediately);
            this.setWidth(width, immediately);
        }
    }

    /**
     * @returns {Rect|null} The TiledImage's current clip rectangle,
     * in image pixels, or null if none.

    getClip() {
        if (this.#clip) {
            return this.#clip.clone();
        }

        return null;
    }*/

    /**
     * @param {Rect|null} newClip - An area, in image pixels, to clip to
     * (portions of the image outside of this area will not be visible). Only works on
     * browsers that support the HTML5 canvas.

    setClip(newClip) {
        if(newClip !== null && newClip instanceof Rect) {
            throw new Error("[TiledImage.setClip] newClip must be an Rect or null")
        }

        if (newClip instanceof Rect) {
            this.#clip = newClip.clone();
        } else {
            this.#clip = null;
        }

        this.#needsDraw = true;
    }
     */


    /**
     * @returns {Number} The TiledImage's current opacity.
     */
    getOpacity() {
        return this.opacity;
    }

    /**
     * @param {Number} opacity Opacity the tiled image should be drawn at.
     */
    setOpacity(opacity) {
        this.opacity = opacity;
        this.#needsDraw = true;
    }

    /**
     * @returns {String} The TiledImage's current blendingType.
     */
    getCompositeOperation() {
        return this.blendingType;
    }

    /**
     * @param {String} blendingType the tiled image should be drawn with this globalCompositeOperation.
     */
    setCompositeOperation(blendingType) {
        this.blendingType = blendingType;
        this.#needsDraw = true;
    }

    /**
     * @private
     * @param {Number} scale
     * @param {Boolean} immediately
     */
    #setScale(scale, immediately) {
        let sameTarget = (this.#scaleSpring.target.value === scale);
        if (immediately) {
            if (sameTarget && this.#scaleSpring.current.value === scale) {
                return;
            }

            this.#scaleSpring.resetTo(scale);
            this.#updateForScale();
            this.#needsDraw = true;
        } else {
            if (sameTarget) {
                return;
            }

            this.#scaleSpring.springTo(scale);
            this.#updateForScale();
            this.#needsDraw = true;
        }

        if (!sameTarget) {
            this.#raiseBoundsChange();
        }
    }

    /**
     * @private
     */
    #updateForScale() {
        this.#worldWidthTarget = this.#scaleSpring.target.value;
        this.#worldHeightTarget = this.normHeight * this.#scaleSpring.target.value;
        this.#worldWidthCurrent = this.#scaleSpring.current.value;
        this.#worldHeightCurrent = this.normHeight * this.#scaleSpring.current.value;
    }

    /**
     * @private
     */
    #raiseBoundsChange() {
        /**
         * Raised when the TiledImage's bounds are changed.
         * Note that this event is triggered only when the animation target is changed;
         * not for every frame of animation.
         * @event bounds-change
         * @memberOf TiledImage
         * @type {object}
         * @property {World} eventSource - A reference to the TiledImage which raised the event.
         * @property {?Object} userData - Arbitrary subscriber-defined object.
         */
        this.dispatchEvent('boundsChange');
    }

    /**
     * @private
     */
    #isBottomItem() {
        return this.viewer.manager.getItemAt(0) === this;
    }
    
    
    updateViewport() {
        this.#needsDraw = false;
        let tile,
            level,
            best            = null,
            haveDrawn       = false,
            currentTime     = Date.now(),
            viewportBounds  = this.viewport.getBoundsWithMargins( true ),
            zeroRatioC      = this.viewport.deltaPixelsFromPointsNoRotate(
                this.source.getPixelRatio( 0 ),
                true
            ).x * this.#scaleSpring.current.value,
            lowestLevel     = Math.max(
                this.source.minLevel,
                Math.floor(
                    Math.log( this.underZoomRatio ) /
                    Math.log( 2 )
                )
            ),
            highestLevel    = Math.min(
                Math.abs(this.source.maxLevel),
                Math.abs(Math.floor(
                    Math.log( zeroRatioC / this.minPixelRatio ) /
                    Math.log( 2 )
                ))
            ),
            rotation         = this.viewport.rotation,
            renderPixelRatioC,
            renderPixelRatioT,
            zeroRatioT,
            optimalRatio,
            levelOpacity,
            levelVisibility;

        viewportBounds.x -= this.#xSpring.current.value;
        viewportBounds.y -= this.#ySpring.current.value;

        // Reset tile's internal drawn state
        while ( this.lastDrawn.length > 0 ) {
            tile = this.lastDrawn.pop();
            tile.beingDrawn = false;
        }

        //Change bounds for rotation
        if (rotation === 90 || rotation === 270) {
            viewportBounds = viewportBounds.rotate( rotation );
        } else if (rotation !== 0 && rotation !== 180) {
            // This is just an approximation.
            let orthBounds = viewportBounds.rotate(90);
            viewportBounds.x -= orthBounds.width / 2;
            viewportBounds.y -= orthBounds.height / 2;
            viewportBounds.width += orthBounds.width;
            viewportBounds.height += orthBounds.height;
        }

        let viewportTL = viewportBounds.getTopLeft();
        let viewportBR = viewportBounds.getBottomRight();

        //Don't draw if completely outside of the viewport
        if  ( !this.wrapHorizontal && (viewportBR.x < 0 || viewportTL.x > this.#worldWidthCurrent ) ) {
            return;
        }

        if ( !this.wrapVertical && ( viewportBR.y < 0 || viewportTL.y > this.#worldHeightCurrent ) ) {
            return;
        }

        // Calculate viewport rect / bounds
        if ( !this.wrapHorizontal ) {
            viewportTL.x = Math.max( viewportTL.x, 0 );
            viewportBR.x = Math.min( viewportBR.x, this.#worldWidthCurrent );
        }

        if ( !this.wrapVertical ) {
            viewportTL.y = Math.max( viewportTL.y, 0 );
            viewportBR.y = Math.min( viewportBR.y, this.#worldHeightCurrent );
        }

        // Calculations for the interval of levels to draw
        // (above in initial let statement)
        // can return invalid intervals; fix that here if necessary
        lowestLevel = Math.min( lowestLevel, highestLevel );

        // Update any level that will be drawn
        let drawLevel; // FIXME: drawLevel should have a more explanatory name
        for ( level = highestLevel; level >= lowestLevel; level-- ) {
            drawLevel = false;

            //Avoid calculations for draw if we have already drawn this
            renderPixelRatioC = this.viewport.deltaPixelsFromPointsNoRotate(
                this.source.getPixelRatio( level ),
                true
            ).x * this.#scaleSpring.current.value;

            if ( ( !haveDrawn && renderPixelRatioC >= this.minPixelRatio ) ||
                ( level === lowestLevel ) ) {
                drawLevel = true;
                haveDrawn = true;
            } else if ( !haveDrawn ) {
                continue;
            }

            //Perform calculations for draw if we haven't drawn this
            renderPixelRatioT = this.viewport.deltaPixelsFromPointsNoRotate(
                this.source.getPixelRatio( level ),
                false
            ).x * this.#scaleSpring.current.value;

            zeroRatioT      = this.viewport.deltaPixelsFromPointsNoRotate(
                this.source.getPixelRatio(
                    Math.max(
                        this.source.getClosestLevel( this.viewport.containerSize ) - 1,
                        0
                    )
                ),
                false
            ).x * this.#scaleSpring.current.value;

            optimalRatio    = this.noWaitForRendering ?
                1 :
                zeroRatioT;

            levelOpacity    = Math.min( 1, ( renderPixelRatioC - 0.5 ) / 0.5 );

            levelVisibility = optimalRatio / Math.abs(
                optimalRatio - renderPixelRatioT
            );

            // Update the level and keep track of 'best' tile to load
            best = this.updateLevel(
                haveDrawn,
                drawLevel,
                level,
                levelOpacity,
                levelVisibility,
                viewportTL,
                viewportBR,
                currentTime,
                best
            );

            // Stop the loop if lower-res tiles would all be covered by
            // already drawn tiles
            if ( this.providesCoverage( this.coverage, level ) ) {
                break;
            }
        }

        // Perform the actual drawing
        this.drawTiles( this.lastDrawn );

        // Load the new 'best' tile
        if (best && !best.context2D) {
            this.loadTile(  best, currentTime );
        }

    }


    updateLevel( haveDrawn, drawLevel, level, levelOpacity, levelVisibility, viewportTL, viewportBR, currentTime, best ){
        let x, y,
            tileTL,
            tileBR,
            numberOfTiles,
            viewportCenter  = this.viewport.pixelFromPoint( this.viewport.getCenter() );


        if( this.viewer ){
            /**
             * @event update-level
             * @type {object}
             * @property {Viewer} eventSource - A reference to the Viewer which raised the event.
             * @property {TiledImage} this - Which TiledImage is being drawn.
             * @property {Object} havedrawn
             * @property {Object} level
             * @property {Object} opacity
             * @property {Object} visibility
             * @property {Object} topleft
             * @property {Object} bottomright
             * @property {Object} currenttime
             * @property {Object} best
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.viewer.dispatchEvent( 'updateLevel', {
                this: this,
                havedrawn: haveDrawn,
                level: level,
                opacity: levelOpacity,
                visibility: levelVisibility,
                topleft: viewportTL,
                bottomright: viewportBR,
                currenttime: currentTime,
                best: best
            });
        }

        //OK, a new drawing so do your calculations
        tileTL    = this.source.getTileAtPoint( level, viewportTL.divide( this.#scaleSpring.current.value ));
        tileBR    = this.source.getTileAtPoint( level, viewportBR.divide( this.#scaleSpring.current.value ));
        numberOfTiles  = this.source.getNumTiles( level );

        this.resetCoverage( this.coverage, level );

        if ( !this.wrapHorizontal ) {
            tileBR.x = Math.min( tileBR.x, numberOfTiles.x - 1 );
        }
        if ( !this.wrapVertical ) {
            tileBR.y = Math.min( tileBR.y, numberOfTiles.y - 1 );
        }
        for ( x = tileTL.x; x <= tileBR.x; x++ ) {
            for ( y = tileTL.y; y <= tileBR.y; y++ ) {

                best = this.updateTile(
                    drawLevel,
                    haveDrawn,
                    x, y, this.quality,
                    level,
                    levelOpacity,
                    levelVisibility,
                    viewportCenter,
                    numberOfTiles,
                    currentTime,
                    best
                );

            }
        }

        return best;
    }

    updateTile( drawLevel, haveDrawn, x, y, quality, level, levelOpacity, levelVisibility, viewportCenter, numberOfTiles, currentTime, best){

        let tile = this.getTile(
                x, y,
                level,
                this.source,
                this.tilesMatrix,
                currentTime,
                numberOfTiles,
                this.#worldWidthCurrent,
                this.#worldHeightCurrent,
                quality
            ),
            drawTile = drawLevel;

        if( this.viewer ){
            /**
             * <em>- Needs documentation -</em>
             *
             * @event update-tile
             * @memberof Viewer
             * @type {object}
             * @property {Viewer} eventSource - A reference to the Viewer which raised the event.
             * @property {TiledImage} tiledImage - Which TiledImage is being drawn.
             * @property {Tile} tile
             * @property {?Object} userData - Arbitrary subscriber-defined object.
             */
            this.viewer.dispatchEvent( 'updateTile', {
                tiledImage: this,
                tile: tile
            });
        }

        this.setCoverage( this.coverage, level, x, y, false );

        if ( !tile.exists ) {
            return best;
        }

        if ( haveDrawn && !drawTile ) {
            if ( this.isCovered( this.coverage, level, x, y ) ) {
                this.setCoverage( this.coverage, level, x, y, true );
            } else {
                drawTile = true;
            }
        }

        if ( !drawTile ) {
            return best;
        }

        this.positionTile(
            tile,
            this.source.tileOverlap,
            this.viewport,
            viewportCenter,
            levelVisibility
        );

        if (!tile.loaded) {
            if (tile.context2D) {
                this.setTileLoaded(tile);
            } else {
                let imageRecord = this.#tileCache.getImageRecord(tile.url);
                if (imageRecord) {
                    let image = imageRecord.getImage();
                    this.setTileLoaded(tile, image);
                }
            }
        }

        if ( tile.loaded ) {
            let needsDraw = this.blendTile(
                tile,
                x, y,
                level,
                levelOpacity,
                currentTime
            );

            if ( needsDraw ) {
                this.#needsDraw = true;
            }
        } else if ( tile.loading ) {
            // the tile is already in the download queue
            // thanks josh1093 for finally translating this typo
        } else {
            best = this.compareTiles( best, tile );
        }
        return best;
    }
    
    /**
     * @private
     * @inner
     * Returns true if the given tile provides coverage to lower-level tiles of
     * lower resolution representing the same content. If neither x nor y is
     * given, returns true if the entire visible level provides coverage.
     *
     * Note that out-of-bounds tiles provide coverage in this sense, since
     * there's no content that they would need to cover. Tiles at non-existent
     * levels that are within the image bounds, however, do not.
     */
    providesCoverage( coverage, level, x, y ) {
        let rows, cols, i, j;
        if ( !coverage[ level ] ) {
            return false;
        }
        if ( x === undefined || y === undefined ) {
            rows = coverage[ level ];
            for ( i in rows ) {
                if ( rows.hasOwnProperty( i ) ) {
                    cols = rows[ i ];
                    for ( j in cols ) {
                        if ( cols.hasOwnProperty( j ) && !cols[ j ] ) {
                            return false;
                        }
                    }
                }
            }
            return true;
        }
        return (
            coverage[ level ][ x] === undefined ||
            coverage[ level ][ x ][ y ] === undefined ||
            coverage[ level ][ x ][ y ] === true
        );
    }

    /**
     * @private
     * @inner
     * Returns true if the given tile is completely covered by higher-level
     * tiles of higher resolution representing the same content. If neither x
     * nor y is given, returns true if the entire visible level is covered.
     */
    isCovered( coverage, level, x, y ) {
        if ( x === undefined || y === undefined ) {
            return this.providesCoverage( coverage, level + 1 );
        } else {
            return (
                this.providesCoverage( coverage, level + 1, 2 * x, 2 * y ) &&
                this.providesCoverage( coverage, level + 1, 2 * x, 2 * y + 1 ) &&
                this.providesCoverage( coverage, level + 1, 2 * x + 1, 2 * y ) &&
                this.providesCoverage( coverage, level + 1, 2 * x + 1, 2 * y + 1 )
            );
        }
    }

    /**
     * @private
     * @inner
     * Sets whether the given tile provides coverage or not.
     */
    setCoverage( coverage, level, x, y, covers ) {
        if ( !coverage[ level ] ) {
            this.log.warn("Setting coverage for a tile before its level's coverage has been reset: " + level);
            return;
        }
        if ( !coverage[ level ][ x ] ) {
            coverage[ level ][ x ] = {};
        }
        coverage[ level ][ x ][ y ] = covers;
    }

    /**
     * @private
     * @inner
     * Resets coverage information for the given level. This should be called
     * after every draw routine. Note that at the beginning of the next draw
     * routine, coverage for every visible tile should be explicitly set.
     */
    resetCoverage( coverage, level ) {
        coverage[ level ] = {};
    }

    /**
     * @private
     * @inner
     * Determines whether the 'last best' tile for the area is better than the
     * tile in question.
     */
    compareTiles( previousBest, tile ) {
        if ( !previousBest ) {
            return tile;
        }

        if ( tile.visibility > previousBest.visibility ) {
            return tile;
        } else if ( tile.visibility === previousBest.visibility ) {
            if ( tile.distance < previousBest.distance ) {
                return tile;
            }
        }

        return previousBest;
    }

    drawTiles( lastDrawn ) {
        if (lastDrawn.length === 0) {
            return;
        }
        let tile = lastDrawn[0];
        let useSketch = this.opacity < 1 ||
            (this.blendingType &&
                this.blendingType !== 'source-over') ||
            (!this.#isBottomItem() && tile.hasTransparencyChannel());

        let sketchScale;
        let sketchTranslate;

        let zoom = this.viewport.getZoom(true);
        let imageZoom = this.viewportToImageZoom(zoom);
        if (imageZoom > this.overZoomRatioForSmoothingTile) {
            // When zoomed in a lot (>100%) the tile edges are visible.
            // So we have to composite them at ~100% and scale them up together.
            useSketch = true;
            sketchScale = tile.getScaleForEdgeSmoothing();
            sketchTranslate = tile.getTranslationForEdgeSmoothing(sketchScale,
                this.#drawer.getCanvasSize(false),
                this.#drawer.getCanvasSize(true));
        }

        if ( useSketch ) {
            this.#drawer.clear( true );
        }

        // When scaling, we must rotate only when blending the sketch canvas to avoid
        // interpolation
        if (this.viewport.rotation !== 0 && !sketchScale) {
            this.#drawer.offsetForRotation(this.viewport.rotation, useSketch);
        }

        let usedClip = false;
        /*
        if ( this.#clip ) {
            this.#drawer.saveContext(useSketch);

            let box = this.imageToViewportRectangle(this.#clip, true);
            let clipRect = this.#drawer.viewportToDrawerRectangle(box);
            if (sketchScale) {
                clipRect = clipRect.times(sketchScale);
            }
            if (sketchTranslate) {
                clipRect = clipRect.translate(sketchTranslate);
            }
            this.#drawer.setClip(clipRect, useSketch);

            usedClip = true;
        }
*/
        if ( this.placeholderFillStyle && this.#hasOpaqueTile === false ) {
            let placeholderRect = this.#drawer.viewportToDrawerRectangle(this.getBounds(true));
            if (sketchScale) {
                placeholderRect = placeholderRect.times(sketchScale);
            }
            if (sketchTranslate) {
                placeholderRect = placeholderRect.translate(sketchTranslate);
            }

            let fillStyle = null;
            if ( typeof this.placeholderFillStyle === "function" ) {
                fillStyle = this.placeholderFillStyle(this, this.#drawer.context);
            }
            else {
                fillStyle = this.placeholderFillStyle;
            }

            this.#drawer.drawRectangle(placeholderRect, fillStyle, useSketch);
        }

        for (let i = lastDrawn.length - 1; i >= 0; i--) {
            tile = lastDrawn[ i ];
            this.#drawer.drawTile( tile, this._drawingHandler(), useSketch, sketchScale, sketchTranslate );
            tile.beingDrawn = true;

            if( this.viewer ){
                /**
                 * <em>- Needs documentation -</em>
                 *
                 * @event tile-drawn
                 * @memberof Viewer
                 * @type {object}
                 * @property {Viewer} eventSource - A reference to the Viewer which raised the event.
                 * @property {TiledImage} tiledImage - Which TiledImage is being drawn.
                 * @property {Tile} tile
                 * @property {?Object} userData - Arbitrary subscriber-defined object.
                 */
                this.viewer.dispatchEvent( 'tileDrawn', {
                    tiledImage: this,
                    tile: tile
                });
            }
        }

        if ( usedClip ) {
            this.#drawer.restoreContext( useSketch );
        }

        if (this.viewport.rotation !== 0 && !sketchScale) {
            this.#drawer.restoreRotationChanges(useSketch);
        }

        if (useSketch) {
            let offsetForRotation = this.viewport.rotation !== 0 && sketchScale;
            if (offsetForRotation) {
                this.#drawer.offsetForRotation(this.viewport.rotation, false);
            }
            this.#drawer.blendSketch(this.opacity, sketchScale, sketchTranslate, this.blendingType);
            if (offsetForRotation) {
                this.#drawer.restoreRotationChanges(false);
            }
        }
        this.drawDebugInfo( lastDrawn );
    }

    getTile( x, y, level, tileSource, tilesMatrix, time, numTiles, worldWidth, worldHeight, quality ) {
        let xMod, yMod, bounds,
            exists, url, context2D, tile;

        if ( !tilesMatrix[ level ] ) {
            tilesMatrix[ level ] = {};
        }
        if ( !tilesMatrix[ level ][ x ] ) {
            tilesMatrix[ level ][ x ] = {};
        }

        if ( !tilesMatrix[ level ][ x ][ y ] ) {
            xMod    = ( numTiles.x + ( x % numTiles.x ) ) % numTiles.x;
            yMod    = ( numTiles.y + ( y % numTiles.y ) ) % numTiles.y;
            bounds  = tileSource.getTileBounds( level, xMod, yMod );
            exists  = tileSource.tileExists( level, xMod, yMod );
            url     = tileSource.getTileUrl( level, xMod, yMod, quality );
            context2D = tileSource.getContext2D ?
                tileSource.getContext2D(level, xMod, yMod) : undefined;

            bounds.x += ( x - xMod ) / numTiles.x;
            bounds.y += (worldHeight / worldWidth) * (( y - yMod ) / numTiles.y);

            tilesMatrix[ level ][ x ][ y ] = new Tile(
                level,
                x,
                y,
                bounds,
                exists,
                url,
                context2D
            );
        }

        tile = tilesMatrix[ level ][ x ][ y ];
        tile.lastTouchTime = time;

        return tile;
    }

    loadTile( tile, time ) {
        tile.loading = true;
        this.#imageLoader.addJob({
            src: tile.url,
            enableCORS: this.enableCORS,
            callback: ( image, errorMsg ) => {
                this.onTileLoad( tile, time, image, errorMsg );
            },
            abort: function() {
                tile.loading = false;
            }
        });
    }

    onTileLoad( tile, time, image, errorMsg ) {
        if ( !image ) {
            this.log.l( "Tile %s failed to load: %s - error: %s", tile, tile.url, errorMsg );
            /**
             * Triggered when a tile fails to load.
             *
             * @event tile-load-failed
             * @memberof Viewer
             * @type {object}
             * @property {Tile} tile - The tile that failed to load.
             * @property {TiledImage} tiledImage - The tiled image the tile belongs to.
             * @property {number} time - The time in milliseconds when the tile load began.
             * @property {string} message - The error message.
             */
            this.viewer.dispatchEvent("tileLoadFailed", {tile: tile, tiledImage: this, time: time, message: errorMsg});
            tile.loading = false;
            tile.exists = false;
            return;
        }

        if ( time < this.lastResetTime ) {
            this.log.l( `Ignoring tile ${tile.toString()} loaded before reset: ${tile.url}` );
            tile.loading = false;
            return;
        }

        let finish = () => {
            let cutoff = Math.ceil( Math.log(
                this.source.getTileWidth(tile.level) ) / Math.log( 2 ) );
            this.setTileLoaded(tile, image, cutoff);
        };

        // Check if we're mid-update; this can happen on IE8 because image load events for
        // cached images happen immediately there
        if ( !this.#midDraw ) {
            finish();
        } else {
            // Wait until after the update, in case caching unloads any tiles
            window.setTimeout( finish, 1);
        }
    }

    setTileLoaded(tile, image, cutoff) {
        let increment = 0;

        let getCompletionCallback = () => {
            increment++;
            return completionCallback;
        }

        let completionCallback = () => {
            increment--;
            if (increment === 0) {
                tile.loading = false;
                tile.loaded = true;
                if (!tile.context2D) {
                    this.#tileCache.cacheTile({
                        image: image,
                        tile: tile,
                        cutoff: cutoff,
                        tiledImage: this
                    });
                }
                this.#needsDraw = true;
            }
        }

        /**
         * Triggered when a tile has just been loaded in memory. That means that the
         * image has been downloaded and can be modified before being drawn to the canvas.
         *
         * @event tile-loaded
         * @memberof Viewer
         * @type {object}
         * @property {Image} image - The image of the tile.
         * @property {TiledImage} tiledImage - The tiled image of the loaded tile.
         * @property {Tile} tile - The tile which has been loaded.
         * @property {function} getCompletionCallback - A function giving a callback to call
         * when the asynchronous processing of the image is done. The image will be
         * marked as entirely loaded when the callback has been called once for each
         * call to getCompletionCallback.
         */
        this.viewer.dispatchEvent("tileLoaded", {
            tile: tile,
            tiledImage: this,
            image: image,
            getCompletionCallback: getCompletionCallback
        });
        // In case the completion callback is never called, we at least force it once.
        getCompletionCallback()();
    }

    positionTile( tile, overlap, viewport, viewportCenter, levelVisibility ){
        let boundsTL     = tile.bounds.getTopLeft();

        boundsTL.x *= this.#scaleSpring.current.value;
        boundsTL.y *= this.#scaleSpring.current.value;
        boundsTL.x += this.#xSpring.current.value;
        boundsTL.y += this.#ySpring.current.value;

        let boundsSize   = tile.bounds.getSize();

        boundsSize.x *= this.#scaleSpring.current.value;
        boundsSize.y *= this.#scaleSpring.current.value;

        let positionC    = viewport.pixelFromPointNoRotate(boundsTL, true),
            positionT    = viewport.pixelFromPointNoRotate(boundsTL, false),
            sizeC        = viewport.deltaPixelsFromPointsNoRotate(boundsSize, true),
            sizeT        = viewport.deltaPixelsFromPointsNoRotate(boundsSize, false),
            tileCenter   = positionT.plus( sizeT.divide( 2 ) ),
            tileDistance = viewportCenter.distanceTo( tileCenter );

        if ( !overlap ) {
            sizeC = sizeC.plus( new Point( 1, 1 ) );
        }

        tile.position   = positionC;
        tile.size       = sizeC;
        tile.distance   = tileDistance;
        tile.visibility = levelVisibility;
    }


    blendTile( tile, x, y, level, levelOpacity, currentTime ){
        let blendTimeMillis = 1000 * this.blendDuration,
            deltaTime,
            opacity;

        if ( !tile.blendStart ) {
            tile.blendStart = currentTime;
        }

        deltaTime   = currentTime - tile.blendStart;
        opacity     = blendTimeMillis ? Math.min( 1, deltaTime / ( blendTimeMillis ) ) : 1;

        if ( this.alwaysBlend ) {
            opacity *= levelOpacity;
        }

        tile.opacity = opacity;

        this.lastDrawn.push( tile );

        if ( opacity === 1 ) {
            this.setCoverage( this.coverage, level, x, y, true );
            this.#hasOpaqueTile = true;
        } else if ( deltaTime < blendTimeMillis ) {
            return true;
        }

        return false;
    }

    drawDebugInfo(  lastDrawn ) {
        if( this.debug ) {
            for ( let i = lastDrawn.length - 1; i >= 0; i-- ) {
                let tile = lastDrawn[ i ];
                try {
                    this.#drawer.drawDebugInfo( tile, lastDrawn.length, i );
                } catch(e) {
                    console.error(e)
                }
            }
        }
    }

}

export default TiledImage;