import {reactive, computed, ref} from "vue";
import Ws, {WsEvents} from "@/ws";
import axios from "axios";
import config from "@/config";

export default {
    install: (app) => {

        const heatmap = ref([]);

        app.provide('heatmap', {
            heatmap,
            async load() {
                try {
                    let res = await axios.get(`${config.server}/heatmap`, { headers : {'x-token':sessionStorage.token}})
                    if(res.data.data) {
                        heatmap.value = res.data.data;
                    }
                } catch (e) {
                    console.log("error loading heatmap data", e)
                }
            },
            async sendEvent(x, y, r) {
                try {
                    await axios.post(`${config.server}/heatmap`, {x, y, r},{ headers : {'x-token':sessionStorage.token}})
                } catch (e) {
                    console.log("error loading heatmap data", e)
                }
            }
        })
    },
}