<template>
  <div class="full-width fill-height">
    <v-img src="../assets/bg.png" class="full-width fill-height" cover ></v-img>
    <div class="d-flex align-center flex-column full-width" style="position: absolute; bottom: 64px">
      <h1 class="text-subtitle-2 text-lg-h5 text-white playfair text-center text-pre" style="line-height: 1;">{{L('brera_home_title')}}</h1>
      <p class="text-caption text-lg-body-1 text-white text-center mt-6 text-pre mb-16 pb-16">{{L('brera_home_subtitle')}}</p>
      <v-btn rounded="pill" color="primary" size="x-large" class="font-weight-bold text-body-2 spaced pl-12 pr-12 mt-6" @click="$router.push('/artwork')">{{L('ENTER')}}</v-btn>
    </div>

    <div class="d-flex align-center" style="position: absolute; bottom: 180px; right: 220px">
      <v-btn @click="$router.push('/artwork'); helpDialog = true"
          color="primary"
          icon="mdi-play"
          size="x-large"
      ></v-btn>
      <div class="ml-6">
        <h2 class="text-white playfair text-pre" style="line-height: 1">{{L('HOW IT WORKS')}}</h2>
        <p class="text-white mt-2">{{L('Find out now')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, onMounted} from "vue";
import router from "@/router";

export default {
  name: "Home",
  setup(props) {

    const auth = inject('auth')
    const helps = inject('helps')
    const localizations = inject('localizations')

    onMounted(()=>{
      if(auth.invalidAccess.value) {
        router.replace('/invalid-access')
      }
    })

    return {
      helpDialog : helps.show,
      L : localizations.L,
    }
  }
}
</script>

<style scoped>

</style>